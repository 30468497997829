import {Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {SxProps} from '@mui/system';

export const styles: Record<string, SxProps<Theme>> = {
  paper: {
    width: '100%',
    marginBottom: theme => theme.spacing(2),
  },
  noContent: {
    position: 'relative',
    textAlign: 'center',
    paddingBottom: '22px',
  },
  categoryToolbar: {
    justifyContent: 'center',
    backgroundColor: '#1111',
  },
  categoryTypography: {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: 1.6,
  },
  toolbar: {
    paddingLeft: theme => theme.spacing(2),
    paddingRight: theme => theme.spacing(2),
  },
  title: {
    flex: '1 1 100%',
    fontSize: '1rem',
    fontWeight: 500,
  },
  table: {
    minWidth: 750,
  },
  tableTitleHeader: {
    width: '45%',
    minWidth: 290,
  },
  tablVimeoLinkHeader: {
    width: '28%',
  },
  tableCategoryHeader: {
    width: '20%',
  },
  tableDescriptionHeader: {
    width: '18%',
  },
  tableViewableByHeader: {
    minWidth: 165,
  },
  tableSizeHeader: {
    minWidth: 80,
  },
  tableDateHeader: {
    minWidth: 170,
  },
  tilesContainer: {
    paddingLeft: theme => theme.spacing(2),
    paddingRight: theme => theme.spacing(2),
  },
  resourceFont: {
    fontSize: '1em',
    maxWidth: '340px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
  },
  resourceLinkFont: {
    fontSize: '1em',
    whiteSpace: 'nowrap',
    display: 'block',
  },
  cardMedia: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  card: {
    maxWidth: 325,
    minHeight: 316,
  },
  videoCard: {
    minHeight: 280,
  },
  cardToolbar: {
    paddingLeft: theme => theme.spacing(2) + '!important',
    paddingRight: '0px!important',
  },
  cardViewableByIcon: {
    opacity: '55%',
  },
  cardViewableBy: {
    display: 'flex',
    gap: theme => theme.spacing(1),
    marginTop: theme => -theme.spacing(3),
    marginBottom: theme => -theme.spacing(1),
    paddingLeft: theme => theme.spacing(2),
    paddingRight: theme => theme.spacing(1),
    justifyContent: 'flex-start',
  },
  cardTitle: {
    paddingTop: 0,
  },
  divider: {
    margin: theme => theme.spacing(1, 2),
  },
};

//styles for the InlineXXX components (like InlineTypography) that work now only with classnames
export const useStyles = makeStyles(() =>
  createStyles({
    categoryTypography: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.6,
    },
    resourceFont: {
      fontSize: '1em',
      maxWidth: '140px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'block',
    },
    titleFont: {
      fontSize: '1em',
      maxWidth: '340px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'block',
    },
  })
);

export enum EditableField {
  title = 'title',
  vimeoLink = 'vimeoLink',
  description = 'description',
  category = 'category',
  viewableBy = 'viewableBy',
}

export const EditableFieldValues: {[_ in EditableField]: string} = {
  [EditableField.title]: 'Title',
  [EditableField.vimeoLink]: 'Vimeo Link',
  [EditableField.description]: 'Description',
  [EditableField.category]: 'Category',
  [EditableField.viewableBy]: 'Viewable By',
};
