import {Dispositions, useCallable, useDisputeHistory, useNotification} from '@ozark/common';
import {useState} from 'react';
import {getAgentByUid} from './useAgents';
import {getMerchantAsync} from './useMerchant';

export const useDocumentsUploadLink = () => {
  const {getDocumentsUploadToken} = useCallable();
  const {getDisputeReasons} = useDisputeHistory();
  const showNotification = useNotification();
  const [loading, setLoading] = useState(false);

  const documentsUploadLink = async (appId: string, email: string, documents: string[]) => {
    setLoading(true);
    const result = await getDocumentsUploadToken({appId: appId, email, documents});
    if (result.status !== 'ok') {
      console.error(
        `Failed to get documents upload token for appId ${appId} with an error`,
        result
      );
      showNotification('error', 'Failed to get documents upload token');
      setLoading(false);
      return;
    }

    setLoading(false);

    return result;
  };

  const copyUploadLink = async (
    appId: string,
    disposition: Dispositions,
    user: 'agent' | 'merchant',
    userId?: string
  ) => {
    let email = '';
    if (user === 'agent' && userId) {
      const agent = await getAgentByUid(userId);
      if (agent) {
        email = agent.email;
      }
    }
    if (user === 'merchant' && userId) {
      const merchant = await getMerchantAsync(userId);
      if (merchant) {
        email = merchant.email;
      }
    }

    const reasons = (await getDisputeReasons(appId, disposition)).map(r => r.key);
    const linkData = await documentsUploadLink(appId, email ?? user, reasons);
    if (!linkData?.portalUrl) {
      showNotification('error', `Error during copying Pending Items Upload URL`);
      return;
    }
    navigator.clipboard.writeText(linkData.portalUrl);
    showNotification('success', `Pending Items Upload URL successfully copied.`);
  };

  return {
    documentsUploadLink,
    copyUploadLink,
    loading,
  };
};
