import {Resource, ResourceInput, ResourceView, ResourceViewableBy} from '@ozark/common';
import {ResourcesApplicationType} from './types';

export const getPreviewUrl = (resource?: ResourceView) => {
  if (!resource || !resource.cloudPreviewImagePath) {
    return undefined;
  }

  return resource.cloudPreviewImagePath.length > 0 && resource.downloadPreviewUrl
    ? resource.downloadPreviewUrl
    : undefined;
};

export const setGroupsAndViewableBy = (
  appType: ResourcesApplicationType,
  data: Partial<ResourceInput>,
  existingData: Resource,
  groupId?: string
) => {
  if (data.viewableBy === existingData.viewableBy) {
    return;
  }
  const value = data.viewableBy;
  let updatedGroups: string[];
  if (appType === ResourcesApplicationType.erp) {
    if (value === ResourceViewableBy.hidden || value === ResourceViewableBy.erp) {
      updatedGroups = []; // ERP: reset all groups to hidden
    } else {
      updatedGroups = existingData.groups; // ERP: left groups as is
    }
  } else {
    if (value === ResourceViewableBy.hidden) {
      updatedGroups = [...existingData.groups.filter((r: string) => r !== groupId)]; // Portal: remove hidden group
    } else {
      if (groupId) {
        updatedGroups = [...existingData.groups.filter((r: string) => r !== groupId), groupId]; // Portal: add visible group
      } else {
        updatedGroups = existingData.groups; // no changes, because group is empty
      }
    }
  }
  data.groups = updatedGroups;

  let updatedViewableBy: ResourceViewableBy | undefined;
  if (appType === ResourcesApplicationType.erp) {
    updatedViewableBy = value; // ERP: update viewableBy
  } else {
    if (existingData!.shared) {
      updatedViewableBy = existingData!.viewableBy; // Portal: don't update for ERP resource
    } else {
      updatedViewableBy = value; // Portal: update viewableBy
    }
  }

  data.viewableBy = updatedViewableBy;
};
