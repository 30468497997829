import {yupResolver} from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ActivePlatforms, Platforms} from '@ozark/functions/src/constants';
import {getEnumKeyByValue} from '@ozark/functions/src/helpers';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router';
import * as yup from 'yup';
import {useCallable, useNotification} from '../../../hooks';
import {Select} from '../../Select';
import {TextField} from '../../TextField';

type Props = {
  route: string;
  applicationId: string;
  onClose: () => void;
  referrer?: string;
  defaultPlatform?: string;
  hidePlatform?: boolean;
};

const getCloneApplicationFormObject = (platformIsRequired: boolean) => {
  const obj = {
    dba: yup.string().required('DBA Name is required'),
    platform: yup.string().optional(),
  };
  if (platformIsRequired) {
    obj.platform = yup.string().required('Platform is required');
  }
  return yup.object(obj);
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('md')]: {
        width: 480,
      },
    },
  })
);

export const CloneDialog = ({
  route,
  applicationId,
  onClose,
  referrer,
  defaultPlatform,
  hidePlatform,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {cloneApplication} = useCallable();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const showNotification = useNotification();

  const hookForm = useForm({
    resolver: yupResolver(getCloneApplicationFormObject(!hidePlatform)),
    shouldUnregister: true,
    defaultValues: {
      platform: defaultPlatform ?? '',
      dba: '',
    },
  });

  const {
    handleSubmit,
    formState: {errors},
    control,
  } = hookForm;

  const onSuccess = async (data: any) => {
    try {
      setLoading(true);

      const id = await cloneApplication({
        platform: data.platform ?? defaultPlatform,
        dba: data.dba,
        originalApplicationId: applicationId,
      });

      if (!id) {
        showNotification('error', 'Failed to clone application.');
        return;
      }

      showNotification('success', `Application successfully cloned`);

      history.push(`${route}/${id}`, {
        referrer: referrer ?? 'Applications',
      });

      setLoading(false);
      onClose();
    } catch (_err) {
      showNotification('error', 'Failed to clone application.');
      console.error(`error cloning application`, _err);
      setLoading(false);
    }
  };

  const onError = (data: any) => {
    console.error(`error cloning application ${JSON.stringify(data)}`);
    setLoading(false);
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="create-dialog-title"
      fullScreen={fullScreen}
      maxWidth={'lg'}
    >
      <DialogTitle id="create-dialog-title">Clone Application</DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <Grid container spacing={1}>
            {!hidePlatform && (
              <Grid item xs={12}>
                <Select
                  name="platform"
                  label="Platform"
                  required
                  errors={errors}
                  control={control}
                  multiple
                >
                  {ActivePlatforms.map(value => (
                    <MenuItem key={`${String(getEnumKeyByValue(Platforms, value))}`} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                name="dba"
                label="DBA Name"
                required
                errors={errors}
                control={control}
                type="text"
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit(onSuccess, onError)} color="primary" disabled={loading}>
          Clone
        </Button>
      </DialogActions>
    </Dialog>
  );
};
