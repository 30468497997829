export enum MerchantDocument {
  mpa = 'Merchant Processing Agreement',
  voidedCheck = 'Voided Check',
  bankLetter = 'Bank Letter',
  achAuthorization = 'ACH Authorization Form',
  bankStatement = 'Bank Statement',
  businessLicense = 'Business License',
  processingStatement = 'Processing Statement',
  irsSs4 = 'IRS SS4 (EIN)',
  financials = 'Financials',
  governementId = 'Government ID',
  letterOfAttestation = 'Letter of Attestation',
  professionalLicense = 'Professional License',
  utilityBill = 'Utility Bill',
  erpAgreement = 'ERP Agreement',
  fulfillmentAgreement = 'Fulfillment Agreement',
  customerServiceAgreement = 'Customer Service Agreement',
  customerInvoice = 'Customer Invoice',
  inventoryPhoto = 'Inventory Photo',
}

export const merchantDocumentsHiddenFromUi = [MerchantDocument.erpAgreement];
