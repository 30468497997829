/* eslint-disable react-hooks/exhaustive-deps */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SortIcon from '@mui/icons-material/Sort';
import {Button, Divider, Link, MenuItem, TextField} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationStatusGroups,
  ApplicationView,
  Collections,
  Dispositions,
  Firebase,
  getColor,
  getDispositions,
  InfiniteSnapshotOptions,
  Lead,
  selectApplicationView,
  selectLeadView,
  UniversalSnapshot,
  useInfiniteSnapshots,
  UserRoles,
  Workflows,
} from '@ozark/common';
import {Card, InfiniteDocuments, Loading, Title} from '@ozark/common/components';
import firebase from 'firebase/compat/app';
import {useCallback, useState} from 'react';
import {useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {ApplicationsExportButton} from '../ApplicationsExportButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    grow: {
      flex: 1,
    },
    divider: {
      margin: theme.spacing(2),
    },
    placeholder: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    selectInput: {
      backgroundColor: 'transparent !important',
    },
  })
);

const viewableDispositions = Workflows[UserRoles.agentSupport].viewable;

const getDispositionFilterValues = (
  disposition: Dispositions | null,
  group: ApplicationStatusGroups
) => {
  if (disposition) {
    return [disposition];
  }

  return getDispositions(group, viewableDispositions);
};

const AgentSupport = () => {
  const classes = useStyles();
  const history = useHistory();
  const {authProfile} = useStore();
  const {isUserAdmin, profiles, groupsMap} = useStore();

  const [showAll, setShowAll] = useState(true);
  const [group, setGroup] = useState(ApplicationStatusGroups.open);
  const [disposition, setDisposition] = useState<Dispositions | null>(null);

  const [options, setOptions] = useState<Partial<InfiniteSnapshotOptions>>({
    order: 'desc',
    limit: 50,
    filter: query => query.where('disposition', 'in', getDispositions(group, viewableDispositions)),
    orderBy: 'dispositionMovedToAsAt',
    firstOrderBy: [{orderBy: 'unassigned', order: 'desc'}],
  });

  const {documents: applications, next} = useInfiniteSnapshots<ApplicationView>(
    Collections.applications,
    selectApplicationView,
    options
  );

  const getFilter = useCallback(
    (showAll: any, groupProp: ApplicationStatusGroups, dispositionProp: Dispositions | null) =>
      (
        query:
          | firebase.firestore.Query<firebase.firestore.DocumentData>
          | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
      ): firebase.firestore.Query<firebase.firestore.DocumentData> => {
        let _query = query;

        if (!showAll) {
          _query = _query.where('uid', '==', authProfile.data!.uid);
        }

        _query = _query.where(
          'disposition',
          'in',
          getDispositionFilterValues(dispositionProp, groupProp)
        );

        return _query;
      },
    []
  );

  const fetchAll = async () => {
    let query = Firebase.firestore
      .collection(Collections.applications)
      .where(
        'disposition',
        'in',
        getDispositions(ApplicationStatusGroups.all, viewableDispositions)
      )
      .orderBy('createdAt', 'desc');

    const snapshot = await query.get();

    if (snapshot.empty || snapshot.docs.length === 0) {
      return [];
    }

    return snapshot.docs.map(doc => selectLeadView(doc as UniversalSnapshot<Lead>));
  };

  const setFilter = (
    showAll: boolean,
    groupProp: ApplicationStatusGroups,
    dispositionProp: Dispositions | null
  ) => setOptions({...options, filter: getFilter(showAll, groupProp, dispositionProp)});

  const toggleOrder = () =>
    setOptions({...options, order: options.order === 'asc' ? 'desc' : 'asc'});

  const toggleShowAll = () => {
    setShowAll(!showAll);
    setFilter(!showAll, group, disposition);
  };

  const handleGroupChange = (event: any) => {
    setGroup(event.target.value);
    setDisposition(null);
    setFilter(showAll, event.target.value, null);
  };

  const handleDispositionChange = (event: any) => {
    const nextDisposition = event.target.value === 'null' ? null : event.target.value;
    setDisposition(nextDisposition);
    setFilter(showAll, group, nextDisposition);
  };

  if (applications.promised) return <Loading />;

  return (
    <div className={classes.root}>
      <Title
        breadcrumbs={[
          <Link
            component="button"
            variant="body1"
            onClick={() => history.push(ROUTES.AGENT_SUPPORT)}
          >
            Agent Support
          </Link>,
        ]}
      >
        <div className={classes.grow} />
        <TextField
          value={group}
          onChange={handleGroupChange}
          variant="standard"
          InputProps={{
            classes: {
              input: classes.selectInput,
            },
            disableUnderline: true,
          }}
          select
        >
          {Object.values(ApplicationStatusGroups)
            .filter(e => getDispositions(e, viewableDispositions).length > 0)
            .sortAndMap(e => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
        </TextField>
        <Divider orientation="vertical" className={classes.divider} flexItem />
        <TextField
          value={String(disposition)}
          onChange={handleDispositionChange}
          variant="standard"
          InputProps={{
            classes: {
              input: classes.selectInput,
            },
            disableUnderline: true,
          }}
          select
        >
          <MenuItem value={String(null)}>
            {group === ApplicationStatusGroups.all
              ? 'All Dispositions'
              : `All ${group} Dispositions`}
          </MenuItem>

          {getDispositions(group, viewableDispositions).sortAndMap(e => (
            <MenuItem key={e} value={e}>
              <span style={{color: getColor(e)}}>&bull;&bull;&bull;&nbsp;&nbsp;</span>
              {e}
            </MenuItem>
          ))}
        </TextField>
        <Divider orientation="vertical" className={classes.divider} flexItem />
        <Button
          size="small"
          onClick={toggleShowAll}
          startIcon={!showAll ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />}
        >
          Assigned to me
        </Button>
        <Divider orientation="vertical" className={classes.divider} flexItem />
        <Button
          size="small"
          onClick={toggleOrder}
          endIcon={
            options.order === 'desc' ? (
              <SortIcon style={{transform: 'rotateX(180deg)'}} />
            ) : (
              <SortIcon />
            )
          }
        >
          {options.order === 'desc' ? 'Newest at Top' : 'Oldest at Top'}
        </Button>

        {isUserAdmin() && (
          <ApplicationsExportButton
            rows={Object.values(applications.data ?? {})}
            getRows={fetchAll}
            filename="agent-support-applications"
            sx={{ml: 2}}
          />
        )}
      </Title>
      <InfiniteDocuments
        documents={applications}
        next={next}
        itemSize={210}
        onDocumentRender={document => (
          <Card
            application={document as ApplicationView}
            profiles={profiles}
            onClick={() =>
              history.push(`agent-support/${document.id}`, {
                referrer: 'Agent Support',
              })
            }
            group={groupsMap[(document as ApplicationView).group.id]}
          />
        )}
      />
    </div>
  );
};

export default AgentSupport;
