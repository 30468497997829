import {DocumentBase, DocumentCreatedBy} from '.';
import {Overwrite} from '../helpers';
import {UniversalTimestamp} from './compat';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type Attachment = DocumentBase &
  DocumentCreatedBy & {
    cloudPath: string;
    cloudPreviewImagePath?: string;
    createdAt: UniversalTimestamp;
    name: string;
    tag?: string; // this field is needed only for UW Pending documents attachments
    label: string;
    deleted?: boolean;
    noteId?: string;
    folderName?: string | null;
  };

export type AttachmentInput = Overwrite<Attachment, {createdAt: Date}>;

export type AttachmentView = ViewBase<Attachment>;

export const selectAttachmentView: ViewSelector<AttachmentView, Attachment> = snapshot => {
  const attachment: AttachmentView = {
    ...defaultViewSelector(snapshot),
  };
  return attachment;
};

export const getSanitizedAttachmentFields = (
  attachment: Attachment | AttachmentInput
): Pick<
  AttachmentInput,
  'name' | 'label' | 'cloudPath' | 'createdAt' | 'cloudPreviewImagePath' | 'uid' | 'deleted'
> => {
  const {name, label, cloudPath, createdAt, cloudPreviewImagePath, uid, deleted} = attachment;
  const createdAtDate = ((createdAt as UniversalTimestamp).toDate?.() ?? createdAt) as Date;

  return {name, label, cloudPath, createdAt: createdAtDate, cloudPreviewImagePath, uid, deleted};
};
