/* eslint-disable react-hooks/exhaustive-deps */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SortIcon from '@mui/icons-material/Sort';
import {Button, Divider, Link, MenuItem, TextField} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationLifeStatusFilter,
  ApplicationView,
  Collections,
  Dispositions,
  Firebase,
  InfiniteSnapshotOptions,
  Lead,
  selectApplicationView,
  selectLeadView,
  TransferStatus,
  UniversalSnapshot,
  useInfiniteSnapshots,
} from '@ozark/common';
import {Card, InfiniteDocuments, Loading, Title} from '@ozark/common/components';
import firebase from 'firebase/compat/app';
import {useCallback, useState} from 'react';
import {generatePath, useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {ApplicationsExportButton} from '../ApplicationsExportButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    grow: {
      flex: 1,
    },
    divider: {
      margin: theme.spacing(2),
    },
    placeholder: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    selectInput: {
      backgroundColor: 'transparent !important',
      fontFamily: 'Rubik, sans-serif',
      fontWeight: 500,
      lineHeight: 1.75,
      fontSize: '0.8125em',
      borderRadius: '4px',
      textTransform: 'uppercase',
    },
  })
);

const ALL_INCOMPLETE_STATUS = 'All Incomplete';
type TransferStatusType = TransferStatus | typeof ALL_INCOMPLETE_STATUS;
const TransferStatuses = Object.values([ALL_INCOMPLETE_STATUS, ...Object.values(TransferStatus)]);

// TODO: This component should be refactored to extract common logic with ozark-portal/src/components/IncompleteApplications/index  .tsx
const IncompleteApplications = () => {
  const classes = useStyles();
  const history = useHistory();
  const {authProfile, profiles, isUserAdmin, groupsMap} = useStore();

  const [showAll, setShowAll] = useState(true);
  const [applicationStatus, setApplicationStatus] = useState(
    ApplicationLifeStatusFilter.activeOnly
  );
  const [incompleteStatus, setIncompleteStatus] =
    useState<TransferStatusType>(ALL_INCOMPLETE_STATUS);

  const isAdmin = isUserAdmin();

  const getFilter = useCallback(
    (showAll: any, applicationStatus: any, incompleteStatus: any) =>
      (
        query:
          | firebase.firestore.Query<firebase.firestore.DocumentData>
          | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
      ): firebase.firestore.Query<firebase.firestore.DocumentData> => {
        let _query = query.where('disposition', '==', Dispositions.incomplete);

        if (!showAll) {
          _query = _query.where('uid', '==', authProfile.data!.uid);
        }

        if (applicationStatus === ApplicationLifeStatusFilter.deletedOnly) {
          _query = _query.where('deleted', '>', false);
        }

        if (incompleteStatus !== 'All Incomplete') {
          _query = _query.where('transferStatus.transferStatus', '==', incompleteStatus);
        }

        return _query;
      },
    []
  );

  const fetchAll = async () => {
    let query = Firebase.firestore
      .collection(Collections.applications)
      .where('disposition', '==', Dispositions.incomplete)
      .orderBy('createdAt', 'desc');

    const snapshot = await query.get();

    if (snapshot.empty || snapshot.docs.length === 0) {
      return [];
    }

    return snapshot.docs.map(doc => selectLeadView(doc as UniversalSnapshot<Lead>));
  };

  const [options, setOptions] = useState<Partial<InfiniteSnapshotOptions>>({
    order: 'desc',
    limit: 50,
    filter: getFilter(showAll, applicationStatus, incompleteStatus),
  });

  const {documents: applications, next} = useInfiniteSnapshots<ApplicationView>(
    Collections.applications,
    selectApplicationView,
    options
  );

  const setFilter = (
    showAll: boolean | null,
    applicationStatus: ApplicationLifeStatusFilter | null,
    incompleteStatus: TransferStatusType
  ) =>
    setOptions({
      ...options,
      orderBy: getOrderBy(incompleteStatus),
      filter: getFilter(showAll, applicationStatus, incompleteStatus),
      firstOrderBy:
        applicationStatus === ApplicationLifeStatusFilter.deletedOnly
          ? [{orderBy: new firebase.firestore.FieldPath('deleted'), order: 'asc'}]
          : undefined,
      displayDeleted: applicationStatus !== ApplicationLifeStatusFilter.activeOnly,
    });

  const toggleOrder = () =>
    setOptions({...options, order: options.order === 'asc' ? 'desc' : 'asc'});

  const toggleShowAll = () => {
    setShowAll(!showAll);
    setFilter(!showAll, applicationStatus, incompleteStatus);
  };

  const handleApplicationStatusChange = (event: any) => {
    setApplicationStatus(event.target.value);
    setFilter(showAll, event.target.value, incompleteStatus);
  };

  const handleIncompleteStatusChange = (event: any) => {
    setIncompleteStatus(event.target.value);
    setFilter(showAll, applicationStatus, event.target.value);
  };

  const getOrderBy = (incompleteStatusValue: string): firebase.firestore.FieldPath => {
    return incompleteStatusValue !== ALL_INCOMPLETE_STATUS
      ? new firebase.firestore.FieldPath('transferStatus', 'transferDate')
      : new firebase.firestore.FieldPath('createdAt');
  };

  const divider = <Divider orientation="vertical" className={classes.divider} flexItem />;

  const showDeletedButton = (
    <TextField
      value={applicationStatus}
      onChange={handleApplicationStatusChange}
      variant="standard"
      InputProps={{
        classes: {
          input: classes.selectInput,
        },
        disableUnderline: true,
      }}
      select
    >
      {Object.values(ApplicationLifeStatusFilter).sortAndMap(e => (
        <MenuItem key={e} value={e}>
          {e}
        </MenuItem>
      ))}
    </TextField>
  );

  if (applications.promised || authProfile.promised) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <Title
        breadcrumbs={[
          <Link component="button" variant="body1" onClick={() => history.push(ROUTES.INCOMPLETE)}>
            Incomplete
          </Link>,
        ]}
      >
        <div className={classes.grow} />
        <TextField
          value={incompleteStatus}
          onChange={handleIncompleteStatusChange}
          variant="standard"
          InputProps={{
            classes: {
              input: classes.selectInput,
            },
            disableUnderline: true,
          }}
          select
        >
          {TransferStatuses.sortAndMap(e => (
            <MenuItem key={e} value={e}>
              {e}
            </MenuItem>
          ))}
        </TextField>
        {divider}
        {isAdmin ? showDeletedButton : null}
        {isAdmin ? divider : null}
        <Button
          size="small"
          onClick={toggleShowAll}
          startIcon={!showAll ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />}
        >
          Assigned to me
        </Button>
        {divider}
        <Button
          size="small"
          onClick={toggleOrder}
          endIcon={
            options.order === 'desc' ? (
              <SortIcon style={{transform: 'rotateX(180deg)'}} />
            ) : (
              <SortIcon />
            )
          }
        >
          {options.order === 'desc' ? 'Newest at Top' : 'Oldest at Top'}
        </Button>

        {isAdmin && (
          <ApplicationsExportButton
            filename="incomplete-applications"
            getRows={fetchAll}
            rows={Object.values(applications.data ?? {})}
            sx={{ml: 2}}
          />
        )}
      </Title>
      <InfiniteDocuments
        documents={applications}
        next={next}
        itemSize={210}
        onDocumentRender={document => (
          <Card
            application={document as ApplicationView}
            profiles={profiles}
            onClick={() =>
              history.push(generatePath(ROUTES.INCOMPLETE_EDIT, {id: document.id}), {
                referrer: 'Incomplete',
              })
            }
            customizeTransferable={true}
            group={groupsMap[(document as ApplicationView).group.id]}
          />
        )}
      />
    </div>
  );
};

export default IncompleteApplications;
