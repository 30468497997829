import {formatNumber, formatUSD} from '../../..';
import {ChartPie, Serie} from '../common/DashboardChartPie';
import {DataIsLoading} from '../common/DataIsLoading';
import {DataIsMissing, NoTransactions} from '../common/DataIsMissing';
import {WidgetProps} from '../common/types';
import {hasData} from '../common/utils';

export const WidgetPinDebitGraph = ({loading, data}: WidgetProps) => {
  if (loading) return <DataIsLoading />;
  if (!data) return <DataIsMissing />;

  const rows: Serie[] = [
    {
      id: 'PIN Debit',
      label: `PIN Debit: ${formatNumber(data.pinDebitTransactionCount)} / ${formatUSD(
        data.pinDebitVolume
      )}`,
      value: data.pinDebitVolume,
    },
    {
      id: 'EBT',
      label: `EBT: ${formatNumber(data.ebtTransactionCount)} / ${formatUSD(data.ebtVolume)}`,
      value: data.ebtVolume,
    },
  ];

  if (!hasData(rows.map(r => r.value))) {
    return <NoTransactions label="No PIN Debit/EBT transactions to graph" />;
  }

  return <ChartPie data={rows} valueFormatter={formatUSD} />;
};
