import PublishIcon from '@mui/icons-material/Publish';
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Collections, StoragePath} from '@ozark/functions/src/constants';
import {
  Resource,
  ResourceDocumentCategoryInput,
  ResourceType,
  ResourceView,
  ResourceViewableBy,
  ResourceViewableByNames,
  ResourceViwableByPortalNames,
} from '@ozark/functions/src/documents';
import {getEnumKeyByValue} from '@ozark/functions/src/helpers';
import {getCloudPreviewImagePath, isPreviewable, rename} from '@ozark/functions/src/shared';
import firebase from 'firebase/compat/app';
import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {v4 as uuidv4} from 'uuid';
import {InputSearch} from '..';
import {Firebase} from '../../firebase';
import {useCallable, useNotification, useResources} from '../../hooks';
import {Loading} from '../Loading';
import {Title} from '../Title';
import {DisplayResources} from './Display';
import {DisplayMode, ResourcesApplicationType} from './types';
import {
  DocumentMetaData,
  DocumentResourceInput,
  UpsertResourceDialog,
} from './UpsertResourceDialog';
import {setGroupsAndViewableBy} from './utils';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      position: 'relative',
    },
    hidden: {
      display: 'none',
    },
    bar: {
      position: 'absolute',
      bottom: 4,
      left: 0,
      width: 'calc(100% - 0px)',
      backgroundColor: 'rgba(255, 255, 255, 0.88)',
      padding: _theme.spacing(2, 9, 2, 2),
      color: '#000',
    },
    uploadButton: {
      width: 180,
    },
    toolbar: {
      paddingLeft: 0,
      paddingRight: _theme.spacing(2),
      justifyContent: 'space-between',
    },
    divider: {
      margin: _theme.spacing(1, 2),
    },
    title: {
      flex: '1 1 100%',
    },
    paper: {
      width: '100%',
      marginBottom: _theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableNameHeader: {
      width: '20%',
      minWidth: 290,
    },
    tableFileLabelHeader: {
      width: '46%',
      textAlign: 'right',
    },
    tableSizeHeader: {
      minWidth: 80,
    },
    tableDateHeader: {
      minWidth: 128,
    },
    tableFileLabelRow: {
      maxWidth: 400,
      textAlign: 'right',
    },
  })
);

type DialogOpen = {
  isOpen: boolean;
  type?: ResourceType;
  resource?: ResourceView;
};

interface Props {
  appType: ResourcesApplicationType;
  groupId?: string;
  allowEdit: boolean;
  historyRoute: string;
}

export const Resources = ({appType, groupId, allowEdit, historyRoute}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const showNotification = useNotification();
  const {resources, documentCategories} = useResources();
  const [currentResources, setCurrentResources] = useState<ResourceView[]>([]);
  const [createDialogOpen, setCreateDialogOpen] = useState<DialogOpen>({isOpen: false});
  const [editMode, setEditMode] = useState<boolean>(allowEdit);
  const [search, setSearch] = useState('');
  const {createResource, updateResource} = useCallable();

  const getResourcesByPermissions = (data: ResourceView[]) => {
    switch (appType) {
      case ResourcesApplicationType.erp: {
        return allowEdit
          ? // display all shared (created from ERP) resources
            data.filter(r => r.shared)
          : // display shared ERP/Both resources for ERP Users
            data.filter(
              r =>
                [ResourceViewableBy.erp, ResourceViewableBy.both].includes(r.viewableBy) && r.shared
            );
      }
      case ResourcesApplicationType.portal: {
        return allowEdit
          ? // display shared Portal/Both resources for Group Admin and Group resources
            data.filter(
              r =>
                ([ResourceViewableBy.portal, ResourceViewableBy.both].includes(r.viewableBy) &&
                  r.shared) ||
                ([ResourceViewableBy.portal, ResourceViewableBy.hidden].includes(r.viewableBy) &&
                  !r.shared &&
                  groupId &&
                  r.ownerGroupId === groupId)
            )
          : // display resources which Group Admin allow to see (shared and group's)
            data.filter(r => r.groups.includes(groupId!));
      }
    }
  };

  useEffect(() => {
    if (resources.promised || !resources.data) return;
    if (search) {
      const searchLower = search.toLowerCase();
      if (search.length === 1) {
        // startWith search
        setCurrentResources(
          getResourcesByPermissions(
            resources.data.filter(
              r =>
                r.title.toLowerCase().startsWith(searchLower) ||
                r.description.toLowerCase().startsWith(searchLower)
            )
          )
        );
      } else {
        // fuzzy search
        setCurrentResources(
          getResourcesByPermissions(
            resources.data.filter(
              r =>
                r.title.toLowerCase().includes(searchLower) ||
                r.description.toLowerCase().includes(searchLower)
            )
          )
        );
      }
    } else {
      setCurrentResources(getResourcesByPermissions(resources.data));
    }
  }, [resources, search]);

  const handleSearchChange = useCallback((search: string) => setSearch(search), [setSearch]);

  const saveResourceAndCategoryData = async (
    pendingResource: DocumentResourceInput,
    existingResource: ResourceView | undefined,
    newCloudPath?: string,
    removeCloudPath?: boolean
  ) => {
    // check if the new category
    if (
      pendingResource.isNewCategory &&
      !documentCategories.data!.map(cat => cat.name).includes(pendingResource.category)
    ) {
      const newDocumentCategory: ResourceDocumentCategoryInput = {
        name: pendingResource.category,
        deleted: false,
        createdAt: new Date(),
      };

      // save new category to Firestore
      await Firebase.firestore
        .collection(Collections.resourceDocumentCategories)
        .add(newDocumentCategory);
    }

    // remove isNewCategory field
    const {isNewCategory, file, ...documentForSave} = pendingResource;
    const resourceDocument: Resource = Object.assign({}, documentForSave);

    // load metadata for the Document
    let documentMetaData: DocumentMetaData = {};
    if (newCloudPath) {
      const cloudPreviewImagePath = isPreviewable(newCloudPath)
        ? getCloudPreviewImagePath(newCloudPath)
        : undefined;
      const storageRef = Firebase.storage.ref(newCloudPath);
      const downloadUrl = await storageRef.getDownloadURL();
      const metadata = await storageRef.getMetadata();
      documentMetaData = {
        downloadUrl,
        size: metadata.size,
        contentType: metadata.contentType || '',
        cloudPath: newCloudPath,
        cloudPreviewImagePath,
        downloadPreviewUrl: null,
      };
      if (existingResource && !cloudPreviewImagePath) {
        documentMetaData.cloudPreviewImagePath = null;
      }
    } else {
      if (existingResource && removeCloudPath) {
        documentMetaData.cloudPath = null;
        documentMetaData.cloudPreviewImagePath = null;
        documentMetaData.size = null;
        documentMetaData.contentType = null;
        documentMetaData.downloadPreviewUrl = null;
      }
    }

    if (existingResource) {
      await handleUpdateResource(
        {
          ...resourceDocument,
          ...documentMetaData,
        } as Resource,
        existingResource.id
      );
      return;
    }

    const newResource = {
      ...resourceDocument,
      ...documentMetaData,
      shared: appType === ResourcesApplicationType.erp, // resources created from ERP shared to Group Admins too
      ownerGroupId: appType === ResourcesApplicationType.portal ? groupId : undefined,
      groups:
        appType === ResourcesApplicationType.portal &&
        resourceDocument.viewableBy === ResourceViewableBy.portal
          ? [groupId!] // save Group Admin group to list of groups who should have access
          : [],
    } as Resource;

    // save the resource to Firestore
    await handleAddResource(newResource);
  };
  const handleSubmitResource = async (
    pendingResource: DocumentResourceInput,
    existingResource?: ResourceView
  ) => {
    if (!pendingResource.file) {
      await saveResourceAndCategoryData(
        pendingResource,
        existingResource,
        undefined,
        !pendingResource.fileName
      );
      return;
    }

    const newFileName = `${pendingResource.title}-${uuidv4()}`;
    const fileInfo = rename(pendingResource.file, newFileName);
    const cloudFullPath = `${StoragePath.resources}/${fileInfo.name}`;
    const uploadTask = Firebase.storage.ref(cloudFullPath).put(fileInfo);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      () => {},
      (err: any) => {
        console.error(err);
        showNotification('error', err);
      },
      () => {
        Firebase.storage
          .ref(StoragePath.resources)
          .child(fileInfo.name)
          .getDownloadURL()
          .then(async () => {
            try {
              let resourceDocument: DocumentResourceInput = {
                ...pendingResource,
              };
              await saveResourceAndCategoryData(resourceDocument, existingResource, cloudFullPath);
            } catch (err) {
              // we want to let the user continue in case of an error
              console.error('failed to save resource to the database', err);
              showNotification('error', 'Failed to save document.');
            } finally {
            }
          });
      }
    );
  };

  const handleMenuItemClick = (resourceType: ResourceType) => () => {
    setCreateDialogOpen({isOpen: true, type: resourceType});
    handleMenuClose();
  };

  const handleAttachClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleUpdateResource = async (data: Resource, resourceId: string) => {
    const snapshot = await Firebase.firestore
      .collection(Collections.resources)
      .doc(resourceId)
      .get();
    if (!snapshot.exists) {
      await handleAddResource(data);
      return;
    }
    const existingData = snapshot.data() as Resource;
    setGroupsAndViewableBy(appType, data, existingData, groupId);

    const result = await updateResource({
      resourceId,
      resource: data,
    });
    if (result.status === 'error') {
      showNotification('error', `An error has happened. Please try again later`);
    } else {
      showNotification('success', `Successfully updated`);
    }
  };

  const handleAddResource = async (data: Resource) => {
    const type = data.type[0].toUpperCase() + data.type.slice(1);

    try {
      const resource: Resource = {
        ...data,
        shared: appType === ResourcesApplicationType.erp, // resources created from ERP shared to Group Admins too
        ownerGroupId: appType === ResourcesApplicationType.portal ? groupId : undefined,
        groups:
          appType === ResourcesApplicationType.portal &&
          data.viewableBy === ResourceViewableBy.portal
            ? [groupId!] // save Group Admin group to list of groups who should have access
            : [],
      };

      var result = await createResource(resource);
      if (result.status === 'error') {
        showNotification('error', `An error has happened`);
      } else {
        showNotification('success', `${type} successfully added`);
      }
    } catch (err) {
      console.error(`Failed to add ${type}`, err);
      showNotification('error', `Failed to add ${type}`);
    }
  };

  const reorderItems = (resourceId: string, newOrder: number) => {
    const resource = currentResources.find(x => x.id === resourceId);
    if (!resource) {
      return;
    }

    const initialResourceOrder = resource.order;

    const updatedResources = [...currentResources];

    for (let i = 0; i < updatedResources.length; i++) {
      const res = updatedResources[i];
      if (res.deleted || res.category !== resource.category || res.type !== resource.type) {
        continue;
      }
      if (res.id === resourceId) {
        res.order = newOrder;
        continue;
      }

      if (
        newOrder < initialResourceOrder &&
        res.order >= newOrder &&
        res.order < initialResourceOrder
      ) {
        // resourceId's order was decreased
        // increase the order of all resources between data.newOrder and resource.order (old order)
        res.order++;
        continue;
      }

      if (
        newOrder > initialResourceOrder &&
        res.order <= newOrder &&
        res.order > initialResourceOrder
      ) {
        // resourceId's order was increased
        // decrease the order of all resources between resource.order (old order) and data.newOrder
        res.order--;
      }
    }
    setCurrentResources(updatedResources);
  };

  const handleEditClick = (resource: ResourceView) => {
    setCreateDialogOpen({isOpen: true, type: resource.type, resource});
    handleMenuClose();
  };

  const handleDeleteResource = (id: string) => async () => {
    try {
      const snapshot = await Firebase.firestore.collection(Collections.resources).doc(id).get();
      if (snapshot.exists) {
        await snapshot.ref.set({deleted: true}, {merge: true});

        const resources = currentResources.filter(x => x.id !== id);
        setCurrentResources(resources);

        showNotification('success', `Resource successfully removed`);
      }
    } catch (err: any) {
      console.error(`failed to delete resource. ${err.toString()}`);
    }
  };

  const getViewableByList = () => {
    switch (appType) {
      case ResourcesApplicationType.erp: {
        return Object.entries(ResourceViewableByNames).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ));
      }
      case ResourcesApplicationType.portal: {
        return Object.entries(ResourceViwableByPortalNames).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ));
      }
    }
  };

  const getResourceTypesMenu = () =>
    Object.values(ResourceType)
      .sort()
      .map((e: ResourceType) => {
        const enumKey = getEnumKeyByValue(ResourceType, e) as string;
        return (
          <MenuItem key={enumKey} onClick={handleMenuItemClick(e)}>
            {`${enumKey.charAt(0).toUpperCase() + enumKey.slice(1)}`}
          </MenuItem>
        );
      });

  const divider = <Divider orientation="vertical" className={classes.divider} flexItem />;

  if (resources.promised || documentCategories.promised) return <Loading />;

  return (
    <div className={classes.root}>
      <Title
        breadcrumbs={[
          <Link component="button" variant="body1" onClick={() => history.push(historyRoute)}>
            Resources
          </Link>,
        ]}
      ></Title>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Toolbar className={classes.toolbar}>
            <InputSearch
              fieldName="searchResources"
              placeholder="Search for resources…"
              onSearchChange={handleSearchChange}
            />
            <Box display="flex" alignItems="center">
              {allowEdit && (
                <Typography component="div">
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>View</Grid>
                    <Grid item>
                      <Switch
                        checked={editMode}
                        onChange={event => setEditMode(event.target.checked)}
                        color="primary"
                        name="editMode"
                        inputProps={{'aria-label': 'primary checkbox'}}
                      />
                    </Grid>
                    <Grid item>Edit</Grid>
                  </Grid>
                </Typography>
              )}
              {allowEdit && divider}
              {allowEdit && (
                <div>
                  <Button
                    id="uploadButton"
                    name="uploadDocument"
                    className={classes.uploadButton}
                    onClick={handleAttachClick}
                    variant="outlined"
                    color="secondary"
                    startIcon={<PublishIcon />}
                  >
                    Add Resource
                  </Button>
                  <Menu
                    id="resource-type-menu"
                    anchorEl={menuAnchorEl}
                    keepMounted
                    open={Boolean(menuAnchorEl)}
                    onClose={handleMenuClose}
                  >
                    {getResourceTypesMenu()}
                  </Menu>
                </div>
              )}
            </Box>
          </Toolbar>
          {currentResources && (
            <DisplayResources
              appType={appType}
              groupId={groupId}
              allowEdit={editMode}
              displayMode={DisplayMode.list}
              isDragHandleAllowed={!search}
              resources={currentResources}
              handleDeleteResource={handleDeleteResource}
              handleEditClick={handleEditClick}
              reorderItems={reorderItems}
            />
          )}
        </Grid>
      </Grid>
      {allowEdit && createDialogOpen.isOpen && createDialogOpen.type && (
        <UpsertResourceDialog
          getViewableByList={getViewableByList}
          onClose={() => setCreateDialogOpen({isOpen: false})}
          onSubmit={handleSubmitResource}
          resourceType={createDialogOpen.type}
          resource={createDialogOpen.resource}
        />
      )}
    </div>
  );
};
