import FilterListIcon from '@mui/icons-material/FilterList';
import {Chip, Grid, IconButton, Typography} from '@mui/material';
import {AllMIDs, SearchCriteria, useApiContainer, useMidsContainer} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {
  ActiveFilter,
  ExportProps,
  Filters,
  FilterUpdate,
  Loading,
  Square,
  Table,
} from '@ozark/common/components';
import {Batch} from '@ozark/functions/src/functions/express/private/types';
import {PaginatedBatchesResponse} from '@ozark/functions/src/functions/express/private/types/PaginatedResponse';
import {format, formatInTimeZone, utcToZonedTime} from 'date-fns-tz';
import {isEmpty} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useUrlQuery} from '../../../hooks/useUrlQuery';
import {Bar, Filter, MidOptionPreselected} from '../common';
import {forceMtdActiveFilter} from '../common/Utils/forceMtdActiveFilter';
import {getFiltersMidFirst} from '../common/Utils/getFiltersMidFirst';
import {
  currentFormatter,
  forceActiveFilter,
  formatMinorUnits,
  useReportingPageStyles,
} from '../Reporting';
import {BatchesFilters} from './Types';

type Props = {
  transactionRouteUrl: string;
} & MidOptionPreselected;

export const Batches = ({transactionRouteUrl, midOptionPreselected}: Props) => {
  const classes = useReportingPageStyles();
  const history = useHistory<{batch: Batch; mid: string}>();
  const [batches, setBatches] = useState<PaginatedBatchesResponse | null>();
  const query = useUrlQuery();
  const urlBatchId = query.get('batchId');
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({
    limit: 20, // page size
    offset: 1, // page
    order: 'desc',
    orderBy: 'batchDate',
  });
  const [filters, setFilters] = useState<Filter>({});
  const {mids, midsOptions, selectedMid, handleSelectMid, forceSelectMidIfNeeded} =
    useMidsContainer();
  const api = useApiContainer();

  const getAllDataForExport = useCallback(async () => {
    if (!api) return [];

    const pageConfigFull: SearchCriteria = {...searchCriteria, offset: 0, limit: 0};
    const result = await api?.transactions.getBatches(
      pageConfigFull,
      selectedMid,
      Object.values(filters)
    );
    return result?.data ?? [];
  }, [searchCriteria, selectedMid, filters]);

  const exportProps: ExportProps = {
    filename: `batches-report-${selectedMid}`,
    rows: batches?.data,
    columnsConfig,
    getRows: getAllDataForExport,
  };

  const setFiltersAndResetOffset = (_filters: Filter | null, filterUpdate?: FilterUpdate) => {
    if (filterUpdate) {
      setFilters(filters => getFiltersMidFirst(filters, filterUpdate));
    } else {
      setFilters(_filters ?? {});
    }
    setSearchCriteria({...searchCriteria, offset: 1});
  };

  const forceFilterByMid = (newMid: string) => {
    const forcedFilterMid = forceActiveFilter(BatchesFilters, 'mid', '__eq', newMid);
    setFiltersAndResetOffset(null, {updateColumnName: 'mid', filter: forcedFilterMid});
  };

  const forceFilterByMtd = () => {
    const forcedFilter = forceMtdActiveFilter(BatchesFilters, 'actualDateRange');
    setFiltersAndResetOffset(null, {updateColumnName: 'batchDate', filter: forcedFilter});
  };

  useEffect(() => {
    forceFilterByMtd();
  }, []);

  useEffect(() => {
    if (mids.promised || !mids.data) return;
    if (midOptionPreselected) {
      onMidSelect(midOptionPreselected.mid);
    } else {
      forceSelectMidIfNeeded();
    }
  }, [mids, midOptionPreselected, forceSelectMidIfNeeded]);

  useEffect(() => {
    if (selectedMid === AllMIDs) return;

    forceFilterByMid(selectedMid);
  }, [selectedMid]);

  useEffect(() => {
    if (isEmpty(filters)) return;
    getBatches();
  }, [searchCriteria, filters]);

  const getBatches = () => {
    api?.transactions
      .getBatches(searchCriteria, selectedMid, Object.values(filters))
      .then((result: any) => {
        setBatches(result || {});
      })
      .catch(error => {
        console.error('Failed at loading Batches with an error:', error);
        setBatches({} as PaginatedBatchesResponse);
      });
  };

  const handleRowClick = (batch: Batch) => {
    history.push(transactionRouteUrl, {batch, mid: selectedMid});
  };

  const onMidSelect = (mid: string) => {
    if (mid !== selectedMid) {
      handleSelectMid(mid);
      forceFilterByMid(mid);
    }
  };

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  };

  const handleDeleteFilter = (id: string) => () => {
    const _filters = {...filters};
    delete _filters[id];
    setFiltersAndResetOffset(_filters);
  };

  const handleApplyFilter = (filter: ActiveFilter) => {
    setFiltersAndResetOffset({...filters, [filter.option.column]: filter});
  };

  useEffect(() => {
    const batchesFilterKeys = BatchesFilters.map(af => af.id);
    for (const key of query.keys()) {
      if (!batchesFilterKeys.includes(key)) continue;
      const batchesFilter = BatchesFilters.find(f => f.id === key);
      if (!batchesFilter) continue;
      if (key === 'mid') {
        const mid = query.get(key);
        if (mid) {
          onMidSelect(mid);
        }
      }
    }
    if (!!urlBatchId) {
      const batchesFilter = BatchesFilters.find(f => f.id === 'id');
      if (!batchesFilter) return;
      handleApplyFilter({
        option: batchesFilter,
        operator: batchesFilter.operators[0],
        value: urlBatchId,
      });
    }
  }, [urlBatchId]);

  if (mids.promised || !mids.data) return <Loading />;

  return (
    <div className={classes.root}>
      <Bar
        title="Batches"
        midsLoaded
        selectedMid={selectedMid}
        mids={midOptionPreselected ? [midOptionPreselected] : midsOptions}
        onMidSelect={onMidSelect}
        Filters={<Filters options={BatchesFilters} onApplyFilter={handleApplyFilter} />}
        exportProps={exportProps}
      />
      {isEmpty(mids.data) && (
        <Typography className={classes.noContent}>
          There are no MIDs associated with your account
        </Typography>
      )}
      {batches && isEmpty(batches) && (
        <Typography className={classes.noContent}>No Batches</Typography>
      )}
      {batches && !isEmpty(batches) && (
        <Grid container spacing={2} direction="row" alignItems="stretch">
          <Grid item xs={12}>
            {filters && !isEmpty(filters) && (
              <IconButton disabled size="large">
                <FilterListIcon />
              </IconButton>
            )}
            {filters &&
              Object.keys(filters).map(key => {
                const filter = filters[key];
                if (filter.option.type === 'monthRange' && filter.operator.id === '__between') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{format(filter.value?.[0] as Date, 'MMMM yyyy')}</b>' and '
                          <b>{format(filter.value?.[1] as Date, 'MMMM yyyy')}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                if (filter.option.type === 'dateOnlyRange' && filter.operator.id === '__between') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{format(filter.value?.[0] as Date, 'MM/dd/yyyy')}</b>' and '
                          <b>{format(filter.value?.[1] as Date, 'MM/dd/yyyy')}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                if (filter.option.type === 'month' && filter.operator.id === '__between') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{format(filter.value?.[0] as Date, 'MMMM yyyy')}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                if (filter.option.type === 'dateOnly' && filter.operator.id === '__eq') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{formatInTimeZone(filter.value?.[0] as Date, 'UTC', 'MM/dd/yyyy')}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                if (filter.option.type === 'currencyRange' && filter.operator.id === '__between') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label}{' '}
                          <b>{currentFormatter.format(filter.value?.[0] as number)}</b>
                          {' and '}
                          <b>{currentFormatter.format(filter.value?.[1] as number)}</b>
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                return (
                  <Chip
                    key={`${key}-${filter.operator.id}`}
                    className={classes.chip}
                    label={
                      <span>
                        <b>{filter.option.label}</b> {filter.operator.label} '
                        <b>
                          {filter.option.type === 'currency'
                            ? `$${filter.value}`
                            : `${filter.value}`}
                        </b>
                        '
                      </span>
                    }
                    variant="outlined"
                    onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                  />
                );
              })}
          </Grid>
          <Grid item xs={3}>
            <Square lines={{'Batched Amount': formatMinorUnits(batches.grossSumAmount)}} center />
          </Grid>
          <Grid item xs={3}>
            <Square
              lines={{
                'Approved Batches': formatMinorUnits(batches.netSumAmount),
              }}
              center
            />
          </Grid>
          <Grid item xs={3}>
            <Square
              lines={{
                Credits: formatMinorUnits(batches.creditsAmount),
              }}
              center
            />
          </Grid>
          <Grid item xs={3}>
            <Square
              lines={{
                'Risk Reviewed Batches<br/>(Delayed Funding)': formatMinorUnits(
                  batches.rejectsAmount
                ),
              }}
              center
            />
          </Grid>
          <Grid item xs={12}>
            <Table
              stickyHeader
              scrollableBody
              customHeight="75vh"
              columns={columnsConfig}
              data={batches}
              onRowClick={handleRowClick}
              onRetrieveData={handleRetrieveData}
              paginate
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const getFormattedDate = (dateString: string | Date) => {
  const date = new Date(dateString);
  return format(utcToZonedTime(date, 'UTC'), 'MM/dd/yyyy');
};

const columnsConfig: Column<Batch>[] = [
  {
    id: 'batchDate',
    label: 'Actual Batch Date',
    numeric: false,
    sortable: true,
    export: row => getFormattedDate(row.batchDate),
    selector: row => getFormattedDate(row.batchDate),
  },
  {
    id: 'statementDate',
    label: 'Statement Date',
    numeric: false,
    sortable: true,
    export: row => format(utcToZonedTime(new Date(row.statementDate), 'UTC'), 'MMMM yyyy'),
    selector: row => format(utcToZonedTime(new Date(row.statementDate), 'UTC'), 'MMMM yyyy'),
  },
  {
    id: 'id',
    label: 'Batch Id',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'netDeposit',
    label: 'Batched Amount',
    numeric: true,
    sortable: true,
    export: row => formatMinorUnits(row.netDeposit - row.creditsAmount),
    selector: row => {
      return (
        <span style={{color: 'green'}}>{formatMinorUnits(row.netDeposit - row.creditsAmount)}</span>
      );
    },
  },
  {
    id: 'credits',
    label: 'Credits',
    numeric: true,
    sortable: true,
    export: row => formatMinorUnits(row.creditsAmount) ?? '0',
    selector: row => {
      return (
        <span style={{color: row.creditsAmount < 0 ? 'red' : 'black'}}>
          {formatMinorUnits(row.creditsAmount)}
        </span>
      );
    },
  },
  {
    id: 'rejects',
    label: 'Risk Reviewed Batches',
    info: <Typography sx={{fontSize: 10}}>(Delayed Funding)</Typography>,
    numeric: true,
    sortable: true,
    export: row => formatMinorUnits(row.rejectsAmount) ?? '0',
    selector: row => {
      return (
        <span style={{color: row.rejectsAmount < 0 ? 'red' : 'black'}}>
          {formatMinorUnits(row.rejectsAmount)}
        </span>
      );
    },
  },
  {
    id: 'amount',
    label: 'Approved Batches',
    numeric: true,
    sortable: true,
    export: row => formatMinorUnits(row.netDeposit - row.rejectsAmount) ?? '0',
    selector: row => formatMinorUnits(row.netDeposit - row.rejectsAmount),
  },
  {
    id: 'transactionsCount',
    label: '# of Transactions',
    numeric: true,
    sortable: false,
    export: true,
    selector: row => {
      return row.transactionsCount;
    },
  },
  {
    id: 'merchantReferenceNumber',
    label: 'Reference Number',
    numeric: false,
    sortable: false,
    export: true,
  },
];
