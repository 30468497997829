import {Agent, SearchCriteria, ViewBase} from '@ozark/common';
import {Table} from '@ozark/common/components';
import {orderBy} from 'lodash';
import {useEffect, useState} from 'react';
import {generatePath, useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {columns} from './columns';

interface Props {
  agents: ViewBase<Agent>[];
}
export const AgentGrid = ({agents}: Props) => {
  const history = useHistory();
  const [filteredAgents, setFilteredAgents] = useState<ViewBase<Agent>[]>([]);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({
    limit: 20, // page size
    offset: 1, // page
    orderBy: 'firstName',
    order: 'asc',
  });
  useEffect(() => {
    if (agents) {
      const filteredAndOrdered = orderBy(
        agents,
        searchCriteria.orderBy,
        searchCriteria.order as 'desc' | 'asc'
      );
      setFilteredAgents(filteredAndOrdered);
    }
  }, [agents, searchCriteria]);

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  };

  const handleRowClick = (row: ViewBase<Agent>) => {
    history.push(generatePath(ROUTES.AGENTS_EDIT, {id: row.id}));
  };

  return (
    <Table
      columns={columns}
      rows={filteredAgents}
      sort={[[searchCriteria.orderBy, searchCriteria.order as 'ASC' | 'DESC']]}
      onRowClick={(row: ViewBase<Agent>) => handleRowClick(row)}
      onRetrieveData={handleRetrieveData}
      stickyHeader
    />
  );
};
