import firebase from 'firebase/compat/app';
import {AsyncState, AuthUserClaims, useApiClient} from '..';
import {ApiContainer} from './containers/ApiContainer';
import {AuthContainer} from './containers/AuthContainer';
import {LoadingContainer} from './containers/LoadingContainer';
import {MidsContainer} from './containers/MidsContainer';

type Props = {
  children: React.ReactNode;
  // these guys are temp - will be removed when migrated to store
  apiClient: ReturnType<typeof useApiClient>['apiClient'];
  authUser: AsyncState<firebase.User | null>;
  claims?: AuthUserClaims | null;
};

export const Store = ({children, apiClient, authUser, claims}: Props) => {
  return (
    <LoadingContainer.Provider>
      <AuthContainer.Provider initialState={{authUser, claims}}>
        <ApiContainer.Provider initialState={{apiClient}}>
          <MidsContainer.Provider>{children}</MidsContainer.Provider>
        </ApiContainer.Provider>
      </AuthContainer.Provider>
    </LoadingContainer.Provider>
  );
};
