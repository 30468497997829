import * as callables from '../functions/callable/index';

export const CallableFunctionName: {
  [_ in keyof typeof callables.default]: string;
} = {
  completeApplication: 'completeApplication',
  cloneApplication: 'cloneApplication',
  startApplication: 'startApplication',
  dispatchCallable: 'dispatchCallable',
  getPortalToken: 'getPortalToken',
  encryptField: 'encryptField',
  getAgentPortalToken: 'getAgentPortalToken',
  getPublishedResidualOptions: 'getPublishedResidualOptions',
};
