import {
  ApplicationDocument,
  AttachedFile,
  AttachedFiles,
  Collections,
  handleAttachmentUpload,
  InputAccept,
  StoragePath,
  useAuthContainer,
  useLoadingContainer,
  useNotification,
} from '@ozark/common';
import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';

interface Props {
  fieldNameFull: string;
  defaultValue?: string;
  applicationId: string;
  attachmentLabel: string;
  verifyFileSize?: {confirmationText: string; maxFileSize: number};
  accept?: InputAccept;
  children: React.ReactNode;
}

export const FieldVarSheet = ({
  fieldNameFull,
  defaultValue,
  applicationId,
  attachmentLabel,
  verifyFileSize,
  children,
}: Props) => {
  const {setIsLoading} = useLoadingContainer();
  const {authUser} = useAuthContainer();
  const {register, setValue} = useFormContext();
  const inputRef = React.createRef<HTMLInputElement>();
  const [message, setMessage] = useState<string>('');
  const [pendingDocument, setPendingDocument] = useState<ApplicationDocument | null>(
    ApplicationDocument.varSheet
  );
  const [currentAttachments, setCurrentAttachments] = useState<AttachedFiles>({});
  const showNotification = useNotification();

  const handleSelectFile = (file: File) => {
    if (!authUser?.data || !authUser.data.uid)
      throw new Error('FieldVarSheet: User data is missing');

    setCurrentAttachments({});
    setIsLoading(true);
    handleAttachmentUpload({
      uid: authUser.data.uid,
      file,
      cloudPath: `${StoragePath.applications}/${applicationId}`,
      attachmentLabel,
      entityId: applicationId,
      collection: Collections.applications,
      pendingDocument,
      setPendingDocument,
      files: currentAttachments,
      setFiles: setCurrentAttachments,
      showNotification,
      onComplete: () => {
        setIsLoading(false);
        setPendingDocument(ApplicationDocument.varSheet);
      },
    });
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) return;
    const file = event.target.files[0];
    if (!file) return;

    if (verifyFileSize && file.size > verifyFileSize.maxFileSize) {
      setMessage(verifyFileSize.confirmationText);
    } else {
      handleSelectFile(file);
    }
  };

  const triggerFilePicker = () => {
    inputRef.current?.click();
  };

  useEffect(() => {
    register(fieldNameFull);
    if (defaultValue) {
      setValue(fieldNameFull, defaultValue as any);
    }
  }, [register, fieldNameFull]);

  useEffect(() => {
    if (currentAttachments && Object.keys(currentAttachments).length) {
      const lastAttachmentIndex = Object.keys(currentAttachments).length - 1;
      const firstAttchament: AttachedFile =
        currentAttachments[Object.keys(currentAttachments)[lastAttachmentIndex]];
      if (firstAttchament?.url) {
        setValue(fieldNameFull, firstAttchament.url, {shouldDirty: true});
      }
    }
  }, [currentAttachments]);

  return (
    <>
      <div onClick={triggerFilePicker}>{children}</div>
      <input
        ref={inputRef}
        style={{display: 'none'}}
        id={fieldNameFull}
        type="file"
        onChange={handleChange}
      />
    </>
  );
};
