import {Autocomplete, Box, TextField} from '@mui/material';
import {omit} from 'lodash';
import {useEffect, useState} from 'react';
import {Collections, Firebase, Merchant, TicketAssignee, TicketMerchant} from '../../../..';
import {FIELD_NAME_MERCHANT} from '../../constants/constants';
import {InputAssigneeError} from '../../types';
import {AssigneeEditorValueGroup} from './AssigneeEditorValueGroup';

type MerchantOption = TicketAssignee['merchant'];

type Props = {
  draft: TicketAssignee;
  setDraft: (draft: TicketAssignee) => void;
  errors: Record<InputAssigneeError, string>;
  setErrors: (errors: Record<InputAssigneeError, string>) => void;
};

const getMerchantDisplayName = (merchant: Merchant, isSort = false): string => {
  let displayName: string = `${isSort ? '-' : ''}${merchant.email}`;

  if (merchant.firstName || merchant.lastName) {
    displayName = `${merchant.firstName} ${merchant.lastName}`.trim();
  }

  return displayName;
};

export const AssigneeEditorValueMerchant = ({draft, setDraft, errors, setErrors}: Props) => {
  const [merchants, setMerchants] = useState<MerchantOption[]>([]);

  useEffect(() => {
    // if group selected - we filter merchants by group
    // if group & agent selected - we filter agents by group!
    const groupId = draft.group?.id;
    if (!groupId) {
      return;
    }

    Firebase.firestore
      .collection(Collections.merchants)
      .where('groupId', '==', groupId)
      .get()
      .then(snap => {
        if (!snap.size) {
          setMerchants([]);
          return;
        }

        const nextMerchantOptions = (
          snap.docs.sortAndMap(
            doc => {
              const data = doc.data() as Merchant;

              // temporary solution TG-2178 to filter inactive merchant accounts
              if (!data.lastLoginAt) {
                return null;
              }

              const merchantOption: MerchantOption = {
                id: doc.id,
                mid: data.applicationMids?.length ? data.applicationMids.toString() : '',
                dba: getMerchantDisplayName(data), // TODO fix inconsistent field name and field data
              };

              return merchantOption;
            },
            doc => {
              const data = doc.data() as Merchant;

              return getMerchantDisplayName(data, true);
            }
          ) || []
        ).filter(Boolean) as MerchantOption[];

        setMerchants(nextMerchantOptions);
      })
      .catch(error => {
        console.error('Error writing document: ', error);
      });
  }, [draft.group?.id]);

  return (
    <>
      <AssigneeEditorValueGroup
        draft={draft}
        setDraft={setDraft}
        errors={errors}
        setErrors={setErrors}
      />
      <Box mt={2}>
        <Autocomplete
          value={draft.merchant}
          onChange={async (
            _: React.SyntheticEvent<Element, Event>,
            newValue: MerchantOption | null
          ) => {
            setDraft({...draft, merchant: newValue ?? undefined});
            if (newValue) {
              setErrors(omit(errors, FIELD_NAME_MERCHANT) as Record<InputAssigneeError, string>);
            }
          }}
          disabled={!draft.group?.id || !merchants.length}
          options={merchants}
          getOptionLabel={getOptionLabel}
          renderInput={params => (
            <TextField
              {...params}
              label="Assignee Merchant"
              required
              variant="standard"
              error={Boolean(errors[FIELD_NAME_MERCHANT])}
              helperText={errors[FIELD_NAME_MERCHANT]}
            />
          )}
        />
      </Box>
    </>
  );
};

const getOptionLabel = (ticketMerchant?: TicketMerchant) => {
  if (!ticketMerchant) return '';
  return `${ticketMerchant.dba ?? ''} ${
    ticketMerchant.mid ? `(${ticketMerchant.mid}}` : ''
  }`.trim();
};
