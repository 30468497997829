import FilterListIcon from '@mui/icons-material/FilterList';
import {Chip, Grid, IconButton, Typography} from '@mui/material';
import {
  ActiveFilter,
  DATETIME_FORMAT,
  DATE_FORMAT_RESERVES,
  FilterOption,
  Filters,
  FilterUpdate,
  Loading,
  ReserveFilters,
  Square,
  Table,
} from '@ozark/common/components';
import {ReserveActivity} from '@ozark/functions/src/functions/express/private/types/Reserve';
import {sumBy} from '@s-libs/micro-dash';
import {format, utcToZonedTime} from 'date-fns-tz';
import {isEmpty} from 'lodash';
import {useEffect, useState} from 'react';
import {AllMIDs, useApiContainer, useMidsContainer} from '../../..';
import {Column} from '../../../api/Column';
import {useExportToCsv} from '../../common';
import {Bar, Filter} from '../common';
import {getFiltersMidFirst} from '../common/Utils/getFiltersMidFirst';
import {
  currentFormatter,
  forceActiveFilter,
  percentFormatter,
  useReportingPageStyles,
} from '../Reporting';

const recordTypeMap = {
  B: 'Calculated Reserve',
  C: 'Calculated Release',
  '3': 'One Time Reserve',
  '4': 'One Time Release',
  '5': 'Record Adjustment Reserve',
  '6': 'Record Adjustment Release',
  '7': 'Transfer Funds on Reserve',
};

type ReservesProps = {
  filterOptions?: FilterOption[];
};

export const Reserves = ({filterOptions = ReserveFilters}: ReservesProps) => {
  const classes = useReportingPageStyles();
  const api = useApiContainer();
  const {mids, midsOptions, selectedMid, handleSelectMid, forceSelectMidIfNeeded} =
    useMidsContainer();
  const [filters, setFilters] = useState<Filter>({});
  const [reserves, setReserves] = useState<ReserveActivity[] | null | undefined>();
  const [balances, setBalances] = useState<{
    balance: number;
    reserves: number;
    releases: number;
  } | null>();

  useEffect(() => {
    const totalReserves = sumBy(reserves || [], e => parseFloat(e.reserveAmount || '0'));
    const totalReleases = sumBy(reserves || [], e => parseFloat(e.releaseAmount || '0'));
    setBalances({
      balance: totalReserves - totalReleases,
      reserves: totalReserves,
      releases: totalReleases,
    });
  }, [reserves]);

  useEffect(() => {
    if (mids.promised || !mids.data) return;

    forceSelectMidIfNeeded();
  }, [mids, forceSelectMidIfNeeded]);

  useEffect(() => {
    if (selectedMid === AllMIDs) return;

    forceFilterByMid(selectedMid);
  }, [selectedMid]);

  useEffect(() => {
    if (isEmpty(filters)) return;
    getReserves();
  }, [filters]);

  const getReserves = () => {
    api?.reserves.getReserveActivity(selectedMid, Object.values(filters)).then(reserves => {
      let sortedByDateDescAndEmptyToEnd = reserves?.sort((x, y) =>
        !x.activityDate || new Date(x.activityDate) < new Date(y.activityDate) ? 1 : -1
      );

      setReserves(sortedByDateDescAndEmptyToEnd);
    });
  };

  const forceFilterByMid = (mid: string) => {
    const midFilter = forceActiveFilter(filterOptions, 'mid', '__eq', mid);
    setFilters(previous => ({...previous, mid: midFilter}));
  };

  const setFiltersInCorrectOrder = (_filters: Filter | null, filterUpdate?: FilterUpdate) => {
    if (filterUpdate) {
      setFilters(filters => getFiltersMidFirst(filters, filterUpdate));
    } else {
      setFilters(_filters ?? {});
    }
  };

  const onMidSelect = (mid: string) => {
    if (mid !== selectedMid) {
      handleSelectMid(mid);
      forceFilterByMid(mid);
    }
  };

  const handleDeleteFilter = (id: string) => () => {
    const _filters = {...filters};
    delete _filters[id];
    setFiltersInCorrectOrder(_filters);
  };

  const handleApplyFilter = (filter: ActiveFilter) => {
    setFiltersInCorrectOrder({...filters, [filter.option.column]: filter});
  };

  const exportToCsv = useExportToCsv({
    filename: 'reserves-report',
    rows: reserves ?? [],
    columnsConfig,
  });

  if (mids.promised || !mids.data) return <Loading />;

  return (
    <div className={classes.root}>
      <Bar
        title="Reserve"
        midsLoaded
        mids={midsOptions}
        selectedMid={selectedMid}
        onMidSelect={onMidSelect}
        Filters={<Filters options={ReserveFilters} onApplyFilter={handleApplyFilter} />}
        exportToCsv={exportToCsv}
      />
      {isEmpty(mids.data) && (
        <Typography className={classes.noContent}>
          There are no MIDs associated with your account
        </Typography>
      )}
      {reserves && (
        <Grid container spacing={2} direction="row" alignItems="stretch">
          <Grid item xs={12}>
            {filters && !isEmpty(filters) && (
              <IconButton disabled size="large">
                <FilterListIcon />
              </IconButton>
            )}
            {filters &&
              Object.keys(filters).map(key => {
                const filter = filters[key];
                if (filter.option.type === 'dateRange' && filter.operator.id === '__between') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{format(filter.value?.[0] as Date, DATETIME_FORMAT)}</b>' and '
                          <b>{format(filter.value?.[1] as Date, DATETIME_FORMAT)}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                if (filter.option.type === 'date' && filter.operator.id === '__between') {
                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>{format(filter.value?.[0] as Date, DATE_FORMAT_RESERVES)}</b>'
                        </span>
                      }
                      variant="outlined"
                      onDelete={handleDeleteFilter(key)}
                    />
                  );
                }
                return (
                  <Chip
                    key={`${key}-${filter.operator.id}`}
                    className={classes.chip}
                    label={
                      <span>
                        <b>{filter.option.label}</b> {filter.operator.label} '
                        <b>
                          {filter.option.type === 'currency'
                            ? `$${filter.value}`
                            : `${filter.value}`}
                        </b>
                        '
                      </span>
                    }
                    variant="outlined"
                    onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                  />
                );
              })}
          </Grid>
          {reserves.length >= 0 && (
            <>
              <Grid item xs={4}>
                <Square
                  lines={{Reserves: currentFormatter.format(balances?.reserves || 0)}}
                  center
                />
              </Grid>
              <Grid item xs={4}>
                <Square
                  lines={{Releases: currentFormatter.format(balances?.releases || 0)}}
                  center
                />
              </Grid>
              <Grid item xs={4}>
                <Square
                  lines={{
                    'Current Reserve Balance': currentFormatter.format(balances?.balance || 0),
                  }}
                  center
                />
              </Grid>

              <Grid item xs={12}>
                <Table
                  stickyHeader
                  scrollableBody
                  customHeight="75vh"
                  rows={reserves}
                  columns={columnsConfig}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </div>
  );
};

const getActivityDate = (row: ReserveActivity) => {
  if (!row.activityDate) return 'No Date';
  const date = new Date(row.activityDate);
  const localTime = utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
  return format(localTime, DATE_FORMAT_RESERVES);
};

const columnsConfig = [
  {
    id: 'activityDate',
    label: 'Activity Date',
    selector: getActivityDate,
    export: getActivityDate,
  },
  {
    id: 'bin',
    label: 'BIN',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'grossSalesAmount',
    label: 'Gross Amount',
    numeric: true,
    selector: row => {
      if (!row.grossSalesAmount) {
        return '';
      }
      return currentFormatter.format(parseFloat(row.grossSalesAmount || '0'));
    },
    export: true,
  },
  {
    id: 'reserveReleasePercent',
    label: 'Reserve Percent',
    numeric: true,
    selector: row => {
      if (!row.reserveReleasePercent) {
        return '';
      }
      return percentFormatter.format(parseFloat(row.reserveReleasePercent || '0') / 100);
    },
    export: true,
  },
  {
    id: 'releaseAmount',
    label: 'Release Amount',
    numeric: true,
    selector: row => {
      if (!row.releaseAmount) {
        return '';
      }
      return currentFormatter.format(parseFloat(row.releaseAmount || '0'));
    },
    export: true,
  },
  {
    id: 'reserveAmount',
    label: 'Reserve Amount',
    numeric: true,
    selector: row => {
      if (!row.reserveAmount) {
        return '';
      }
      return currentFormatter.format(parseFloat(row.reserveAmount || '0'));
    },
    export: true,
  },
  {
    id: 'recordType',
    label: 'Record Type',
    selector: row => recordTypeMap[row.recordType],
    export: row => recordTypeMap[row.recordType],
  },
] as Column<ReserveActivity>[];
