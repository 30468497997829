import {Box, Divider} from '@mui/material';
import {useEffect, useState} from 'react';
import {TicketMerchant, useUserInfo} from '../../../..';
import {getApplicationByMid} from '../../../../firebase/queries/applications';
import {Detail} from '../DetailsRow';

type MerchantInfo = {
  applicationId: string;
  agent?: string;
  group?: string;
};

type Props = {
  merchant?: TicketMerchant;
};

export const AssociationInfoMerchant = ({merchant}: Props) => {
  const {isAgent, isAgentMember, uid, isAgentAdmin, isMerchant, userGroupId} = useUserInfo();
  const [merchantInfo, setMerchnatInfo] = useState<MerchantInfo>();

  useEffect(() => {
    if (!merchant?.mid) return;
    let mounted = true;

    (async () => {
      const agentUid = isAgentMember ? uid : undefined;
      const groupUid = isAgentAdmin ? userGroupId : undefined;
      const app = await getApplicationByMid(merchant.mid, agentUid, groupUid);
      if (!app) return;
      if (!mounted) return;
      setMerchnatInfo({
        applicationId: app.id,
        agent: app.agent && `${app.agent.firstName} ${app.agent.lastName}`.trim(),
        group: app.group && app.group.name,
      });
    })();

    return () => {
      mounted = false;
    };
  }, [merchant?.mid]);

  if (!merchant) return null;

  if (isMerchant) {
    return (
      <>
        <Detail title={merchant.dba} description="Merchant" />
        {merchant.mid && (
          <>
            <Divider sx={{my: 0.5}} />
            <Detail
              title={<Box sx={{fontSize: '0.95rem', whiteSpace: 'nowrap'}}>{merchant.mid}</Box>}
              description="Associated MID"
            />
          </>
        )}
      </>
    );
  }

  const applicationsBaseUrl = isAgent ? 'application' : 'applications';
  return (
    <>
      <Detail
        title={merchant.dba}
        description="Merchant"
        url={
          merchantInfo?.applicationId
            ? `/${applicationsBaseUrl}/${merchantInfo.applicationId}`
            : undefined
        }
      />

      {merchantInfo?.agent && (
        <>
          <Divider sx={{my: 0.5}} />
          <Detail title={merchantInfo.agent} description="Agent" />
        </>
      )}

      {merchantInfo?.group && (
        <>
          <Divider sx={{my: 0.5}} />
          <Detail title={merchantInfo.group} description="Group" />
        </>
      )}
    </>
  );
};
