import AddIcon from '@mui/icons-material/Add';
import {Box, Button, ButtonTypeMap, Grid} from '@mui/material';
import {
  ApplicationEquipment,
  ApplicationType,
  Dispositions,
  EquipmentConnectionType,
  EquipmentDurationType,
  EquipmentFileType,
  PaymentType,
  PlacementType,
  Platforms,
  ProcessingTypes,
} from '@ozark/common';
import {useEquipment} from '@ozark/common/hooks/useEquipment';
import {useCallback, useState} from 'react';
import {useFieldArray} from 'react-hook-form';
import {EquipmentDescriptionDialog} from './EquipmentDescriptionDialog';
import {EquipmentListItem} from './EquipmentListItem';

const FIELD_NAME_E = 'equipment';
const FIELD_NAME_EA = 'equipmentAdditional';

const scrollLastEquipmentIntoView = () => {
  setTimeout(() => {
    const equipmentAdditionalElements = document.querySelectorAll('.equipment-item');
    if (equipmentAdditionalElements && equipmentAdditionalElements.length) {
      const elementLast = equipmentAdditionalElements[equipmentAdditionalElements.length - 1];
      elementLast.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, 300);
};

type Props = {
  applicationId: string;
  applicationType?: ApplicationType;
  applicationProcessingType: ProcessingTypes;
  readonly: boolean;
  softwareName?: string;
  disposition?: Dispositions;
};

export const EquipmentList = ({
  applicationId,
  applicationType,
  applicationProcessingType,
  readonly,
  disposition,
}: Props) => {
  const {equipment} = useEquipment(applicationProcessingType);
  const [descriptionDialogOpen, setNewItemDialogOpen] = useState(false);
  const {fields, append, remove} = useFieldArray({
    name: FIELD_NAME_EA,
  });

  const handleRemoveEquipment = useCallback(
    (index: number) => {
      remove(index);
      setTimeout(() => {
        document.body.scrollIntoView({behavior: 'smooth', block: 'end'});
      }, 100);
    },
    [remove]
  );

  const handleEquipmentAddDialogOpen = () => {
    setNewItemDialogOpen(true);
  };

  const handleEquipmentAdd = (description: string) => {
    const equipmentItemNew = {...equipmentNewTemplate, description};
    append(equipmentItemNew);
    setNewItemDialogOpen(false);
    scrollLastEquipmentIntoView();
  };

  return (
    <>
      <Box flexGrow={1}>
        <EquipmentListItem
          key={'equipment'}
          applicationId={applicationId}
          applicationType={applicationType}
          equipment={equipment}
          fieldName={FIELD_NAME_E}
          readonly={readonly}
          disposition={disposition}
        />
        {fields.map((eq, index) => (
          <EquipmentListItem
            key={index}
            fieldName={FIELD_NAME_EA}
            index={index}
            data={eq as ApplicationEquipment}
            applicationId={applicationId}
            applicationType={applicationType}
            equipment={equipment}
            readonly={readonly}
            remove={handleRemoveEquipment}
          />
        ))}
      </Box>
      {!readonly && (
        <Grid item xs={12}>
          <Box pt={3} mb={2} borderTop={1} borderColor="#d7dcddbf">
            <Button
              aria-label="Add Additional Equipment"
              onClick={handleEquipmentAddDialogOpen}
              startIcon={<AddIcon />}
              size="large"
              style={{
                minHeight: '56px',
              }}
              {...buttonProps}
            >
              Add Additional Equipment
            </Button>
          </Box>
        </Grid>
      )}
      <EquipmentDescriptionDialog
        dialogTitle="Add Additional Equipment"
        okTitle="Add"
        defaultValue=""
        open={descriptionDialogOpen}
        onClose={() => setNewItemDialogOpen(false)}
        onOk={handleEquipmentAdd}
      />
    </>
  );
};

const buttonProps: ButtonTypeMap['props'] = {
  color: 'primary',
  variant: 'outlined',
  fullWidth: true,
};

const equipmentNewTemplate: ApplicationEquipment = {
  autoBatch: false,
  autoBatchTime: '',
  cashDiscount: false,
  cashDiscountPercent: 0,
  connectionType: EquipmentConnectionType.ethernet,
  contactless: false,
  fileType: EquipmentFileType.retail,
  id: '',
  imageUrl: '',
  isGateway: false,
  name: '',
  paymentType: PaymentType.purchaseNewDeviceAgent,
  placementType: PlacementType.purchase,
  price: 0,
  platform: Platforms.evolve5846,
  duration: EquipmentDurationType.monthly,
};
