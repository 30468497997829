import {
  ResourceView,
  ResourceViewableBy,
  ResourceViewableByNames,
  ResourceViwableByPortalNames,
} from '@ozark/common';
import {TooltipPlacement} from '../../../material-ui';
import {InlineSelect} from '../../Inline';
import {ResourcesApplicationType} from '../types';
import {EditableField, useStyles} from './constants';
import {useDisplayContext} from './DisplayContext';
import {DisplayText} from './DisplayText';

interface Props {
  resourceView: ResourceView;
}

export const DisplayViewableBy = ({resourceView}: Props) => {
  const classes = useStyles();
  const {appType, groupId, allowEdit, handleSaveField} = useDisplayContext();
  const resourceNames =
    appType === ResourcesApplicationType.erp
      ? ResourceViewableByNames
      : ResourceViwableByPortalNames;
  const displayValue =
    appType === ResourcesApplicationType.erp
      ? resourceNames[resourceView.viewableBy]
      : groupId && resourceView.groups.includes(groupId!)
      ? ResourceViwableByPortalNames[ResourceViewableBy.portal]
      : ResourceViwableByPortalNames[ResourceViewableBy.hidden];
  return allowEdit ? (
    <InlineSelect
      tooltip="Click to change Permissions"
      value={displayValue}
      className={classes.resourceFont}
      noSort={true}
      options={Object.entries(resourceNames).map(([key, value]) => ({
        key: key,
        value: value,
      }))}
      setValue={newValue => {
        const viewableByValue = Object.entries(resourceNames)
          .filter(([_, value]) => value === newValue)
          .map(([key, _]) => key);
        handleSaveField(EditableField.viewableBy, resourceView.id, viewableByValue[0]);
      }}
      tooltipPlacement={TooltipPlacement.BottomStart}
    />
  ) : (
    <DisplayText value={resourceView.viewableBy} />
  );
};
