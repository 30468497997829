import {useApiClient} from '@ozark/common';
import {createContainer} from 'unstated-next';
import {useAuthProfile} from '../hooks/useAuthProfile';
import {useAuthUser} from '../hooks/useAuthUser';
import {useGroups} from '../hooks/useGroups';
import {useSearchableProfiles} from '../hooks/useSearchableProfiles';

const useStoreBase = () => {
  const authUserStore = useAuthUser();
  const authProfileStore = useAuthProfile(authUserStore);
  const profilesStore = useSearchableProfiles(authUserStore);
  const httpClientStore = useApiClient(authUserStore.authUser);
  const groupsStore = useGroups();

  return {
    ...authUserStore,
    ...authProfileStore,
    profiles: profilesStore.profiles,
    ...httpClientStore,
    ...groupsStore,
  };
};

const Store = createContainer(useStoreBase);

export {Store};
