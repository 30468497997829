import {ApiBase, TrailingAgentVolume} from '@ozark/common';
import {ContextKey} from '@ozark/functions/src/functions/express/private/types/Context';
import {
  MccInfo,
  MidInfo,
  MonthlyAuthorizationResult,
  MonthlyVolumeResult,
  PortfolioStatistics,
  VolumeResult,
} from '@ozark/functions/src/functions/express/private/types/Reports';
import {MonthlySalesSummary} from '@ozark/functions/src/shared';
import {VolumeFilter} from '../components/Dashboard/FilterPeriodSelect';

export class ReportsApi extends ApiBase {
  getYearlySummary = (mid?: string) => {
    return this.get<MonthlySalesSummary[]>(`reports/yearly-sales-summary`, {
      [ContextKey.Mid]: mid,
    });
  };
  getPortfolioYearlySummary = (selectedAgentId?: string) => {
    return this.post<PortfolioStatistics>(`reports/yearly-portfolio-statistics`, {
      [ContextKey.AgentId]: selectedAgentId,
    });
  };
  getMonthlyVolumeDetail = (monthYear: string, mid?: string) => {
    return this.post<MonthlyVolumeResult>(
      `reports/monthly-volume-detail`,
      {
        [ContextKey.MonthYear]: monthYear,
      },
      {[ContextKey.Mid]: mid}
    );
  };
  getVolumeDetail = (volumeFilter: VolumeFilter, mid?: string) => {
    return this.post<VolumeResult>(
      `reports/volume-detail`,
      {
        [ContextKey.FilterType]: volumeFilter.type,
        [ContextKey.StartDateTime]: volumeFilter.startDate,
        [ContextKey.EndDateTime]: volumeFilter.endDate,
      },
      {[ContextKey.Mid]: mid}
    );
  };
  getTrailingVolume = (monthYear?: string, groupId?: string, agentId?: string, mid?: string) => {
    return this.post<TrailingAgentVolume[]>(
      `reports/trailing-volume`,
      {
        [ContextKey.MonthYear]: monthYear,
        [ContextKey.AgentId]: agentId,
        [ContextKey.GroupId]: groupId,
      },
      {[ContextKey.Mid]: mid}
    );
  };
  getMonthlyAuthorizationDetail = (monthYear: string, mid?: string) => {
    return this.post<MonthlyAuthorizationResult>(
      `reports/monthly-authorization-detail`,
      {
        [ContextKey.MonthYear]: monthYear,
      },
      {[ContextKey.Mid]: mid}
    );
  };
  getMccsBySales = (year: number, month: number) =>
    this.get<MccInfo[]>(`reports/mcc-by-sales?year=${year}&month=${month}`);

  getMidsByProcessing = (year: number, month: number) =>
    this.get<MidInfo[]>(`reports/mids-by-processing?year=${year}&month=${month}`);
}
