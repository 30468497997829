import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import GroupIcon from '@mui/icons-material/Group';
import MapIcon from '@mui/icons-material/Map';
import TodayIcon from '@mui/icons-material/Today';
import {alpha, Avatar, Chip, Divider, Paper, Tooltip, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  AccountStatus,
  ApplicationView,
  Dispositions,
  getColor,
  GroupView,
  Platforms,
} from '@ozark/common';
import {getApplicationDate} from '@ozark/common/components';
import {ActionDate} from '@ozark/common/components/Application/ActionDate';
import {
  BusinessAddressEntry,
  CardDataEntry,
} from '@ozark/common/components/Application/CardDataEntry';
import {GroupInfo} from '@ozark/common/components/Application/Elements/GroupInfo';
import {useUserInfo} from '@ozark/common/hooks/useUserInfo';
import clsx from 'clsx';
import {useHistory} from 'react-router';
import {useStore} from '../../store/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      cursor: 'pointer',
      height: 196,
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      color: '#4d6575',
      padding: theme.spacing(2, 2, 0, 0),
      margin: theme.spacing(0, 0, 2, 0),
      borderLeft: `solid 8px ${theme.palette.primary.light}`,
      width: '100%',
      transition: theme.transitions.create(['padding', 'border-color', 'box-shadow']),
      duration: theme.transitions.duration.complex,
      '&:hover': {
        boxShadow: `0px 1px 3px 0px ${alpha(theme.palette.primary.light, 0.9)}`,
      },
    },
    inViewport: {
      borderLeft: `solid 8px ${theme.palette.primary.light}`,
    },
    icon: {
      height: '100%',
      padding: 0,
      margin: theme.spacing(0, 1),
      '& > *': {
        fill: '#4d6575',
      },
    },
    avatar: {
      height: '100%',
      flexBasis: '10%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(0, 2),
      minWidth: 120,
    },
    disposition: {
      width: 120,
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    column: {
      height: '100%',
      padding: theme.spacing(0, 3, 0, 0),
      flexBasis: '18%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      transition: theme.transitions.create(['flexBasis'], {
        duration: theme.transitions.duration.standard,
      }),
      [theme.breakpoints.down('xl')]: {
        '&:hover': {
          flexBasis: '40%',
        },
      },
    },
    single: {
      height: '100%',
      flexBasis: '10%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    divider: {
      padding: 0,
    },
    grow: {
      height: '100%',
      flexGrow: 1,
    },
    speedDial: {
      position: 'absolute',
      top: `calc(50% - 28px)`,
      right: theme.spacing(2),
      padding: 0,
    },
    fab: {
      backgroundColor: 'transparent',
      boxShadow: 'none !important',
      color: '#b2c2cd',
      '&:hover,&:focus': {
        color: '#1c252c',
        backgroundColor: '#f5fafc',
      },
      '&:hover $icon': {
        fill: '#1c252c',
      },
    },
    actionFab: {
      color: '#b2c2cd',
      '&:hover,&:focus': {
        color: '#1c252c',
        backgroundColor: '#f5fafc',
      },
      '&:hover $icon': {
        fill: '#1c252c',
      },
    },
  })
);

type Props = {
  application: ApplicationView;
  group?: GroupView;
};

export const Card = ({application, group}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const {profiles} = useStore();
  const {isAgent} = useUserInfo();

  const profile = application?.uid && profiles.dictionary[application.uid];

  const {dateString: applicationDateString, label: applicationDateLabel} =
    getApplicationDate(application);

  return (
    <Paper
      className={classes.paper}
      style={{
        borderLeftColor: getColor(
          application.disposition || Dispositions.incomplete,
          application.isClosed
        ),
      }}
      onClick={() => history.push(`boarded/${application.id}`, {referrer: 'Boarded'})}
    >
      <div className={classes.avatar}>
        <Tooltip title={profile ? `${profile.firstName} ${profile.lastName}` : 'Unassigned'}>
          {profile ? <Avatar src={profile.photoUrl}></Avatar> : <Avatar>?</Avatar>}
        </Tooltip>

        <Typography variant="caption" align="center" noWrap className={classes.disposition}>
          {application.accountStatus?.status === AccountStatus.closed ? (
            'Closed'
          ) : (
            <Chip
              size="small"
              label={application.disposition}
              variant="outlined"
              sx={{fontWeight: '500', mt: 1}}
            />
          )}
        </Typography>

        {application.boardedAt && (
          <ActionDate date={application.boardedAt.toDate()} caption={'Boarded date'} />
        )}
      </div>

      <Divider className={classes.divider} orientation="vertical" flexItem />

      <div className={classes.icon}>
        <GroupIcon color="inherit" />
      </div>

      <div className={classes.column}>
        <Typography variant="subtitle2" component="p" noWrap gutterBottom>
          Group / Agent
        </Typography>

        <GroupInfo application={application} group={group} />

        <CardDataEntry caption="Agent">
          {application.agent ? (
            <>
              {application.agent?.firstName ?? ''} {application.agent?.lastName ?? ''}
            </>
          ) : (
            'Unassigned'
          )}
        </CardDataEntry>
      </div>

      <Divider className={classes.divider} orientation="vertical" flexItem />

      <div className={classes.icon}>
        <TodayIcon color="inherit" />
      </div>

      <div className={classes.column}>
        <Typography variant="subtitle2" noWrap gutterBottom>
          Application Info
        </Typography>

        <CardDataEntry caption={applicationDateLabel}>{applicationDateString}</CardDataEntry>
        <CardDataEntry caption="MID">{application.mid}</CardDataEntry>
        <CardDataEntry caption="Application ID">{application.distinguishableId}</CardDataEntry>
      </div>

      <Divider className={classes.divider} orientation="vertical" flexItem />

      <div className={clsx(classes.icon)}>
        <MapIcon color="inherit" />
      </div>

      <div className={classes.column}>
        <Typography variant="subtitle2" noWrap gutterBottom>
          Business Info
        </Typography>

        <CardDataEntry caption="DBA">{application.doingBusinessAs}</CardDataEntry>
        <CardDataEntry caption="Legal Business Name">{application.legalBusinessName}</CardDataEntry>
        <CardDataEntry caption="Business Address">
          {application.businessAddress1 && <BusinessAddressEntry application={application} />}
        </CardDataEntry>
      </div>

      <Divider className={classes.divider} orientation="vertical" flexItem />

      <div className={clsx(classes.icon)}>
        <ContactPhoneIcon color="inherit" />
      </div>

      <div className={classes.column}>
        <Typography variant="subtitle2" component="p" noWrap gutterBottom>
          Signer Info
        </Typography>

        <CardDataEntry caption="Signer Name">
          {application.firstName && `${application.firstName} ${application.lastName}`}
        </CardDataEntry>

        <CardDataEntry caption="Business Phone">
          {application.businessPhone && (
            <a href={`tel:${application.businessPhone}`}>{application.businessPhone}</a>
          )}
        </CardDataEntry>

        <CardDataEntry caption="Customer Service Email">
          {application.customerServiceEmail && (
            <a href={`mailto:${application.customerServiceEmail}`}>
              {application.customerServiceEmail}
            </a>
          )}
        </CardDataEntry>
      </div>

      {!isAgent && (
        <>
          <Divider className={classes.divider} orientation="vertical" flexItem />

          <div className={classes.single}>
            <CreditCardIcon />

            <Typography variant="caption" noWrap>
              {application.platform || Platforms.evolve5846}
            </Typography>
          </div>
        </>
      )}
    </Paper>
  );
};
