import {TransferIntent, useCallable, useNotification} from '../../index';
import {ConfirmationDialog, ConfirmationDialogField} from '../ConfirmationDialog';

interface Props {
  emailSuggestions?: string[];
  applicationId?: string;
  validate?: () => boolean;
  onClose: () => void;
  onBeforeTransfer?: () => Promise<void>;
  onSuccess?: () => void;
}

interface TransferForm {
  email: string;
}

export const TransferToMerchantConfirmationDialogEx = ({
  emailSuggestions,
  applicationId,
  validate,
  onSuccess,
  onClose,
  onBeforeTransfer,
}: Props) => {
  const {transferApplication} = useCallable();
  const showNotification = useNotification();
  const handleSendToMerchant = async (email: string, transferIntent: TransferIntent) => {
    if (validate && !validate()) {
      return;
    }
    if (!applicationId) {
      return;
    }
    try {
      if (onBeforeTransfer) {
        await onBeforeTransfer();
      }
      await transferApplication({
        email: email.trim(),
        transferIntent: transferIntent,
        applicationId: applicationId,
      });
      if (onSuccess) {
        await onSuccess();
      }
      showNotification('success', `Email successfully sent to ${email}.`);
    } catch (_err) {
      console.error('Error has occured during sending to merchant', _err);
      showNotification('error', `Error sending email.`);
    }
  };

  return (
    <ConfirmationDialog
      title="Confirmation"
      message={`Where would you like to have this email sent?`}
      helperText={
        !!emailSuggestions?.length
          ? `You can use one of these emails: ${emailSuggestions?.join(', ')}`
          : ''
      }
      fields={[new ConfirmationDialogField('email', 'Email Address', '', null)]}
      onClose={() => onClose()}
      onConfirm={(form: TransferForm) =>
        handleSendToMerchant(form?.email, TransferIntent.transferApplication)
      }
    />
  );
};
