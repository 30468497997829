import {AsyncState, Collections, Firebase, UniversalSnapshot} from '@ozark/common';
import {
  BusinessCategory,
  BusinessCategoryCodeValue,
  BusinessCategoryView,
  selectBusinessCategoryView,
} from '@ozark/functions/src/documents/BusinessCategory';
import {
  BusinessType,
  BusinessTypeView,
  selectBusinessTypeView,
} from '@ozark/functions/src/documents/BusinessType';
import {useCallback, useEffect, useState} from 'react';

type BusinessCategoryData = BusinessCategoryView & {
  types: BusinessTypeView[];
};
export const useBusinessCategories = (activeOnly: boolean = true) => {
  const [businessCategories, setBusinessCategories] = useState<AsyncState<BusinessCategoryData[]>>({
    promised: true,
  });
  const [mccCodes, setMccCodes] = useState<{[_ in string]: string}>({});

  useEffect(() => {
    const collectionQuery = Firebase.firestore.collection(Collections.businessCategories);

    const unsubscribe = (
      activeOnly ? collectionQuery.where('deleted', '==', false) : collectionQuery
    ).onSnapshot(async snapshot => {
      if (snapshot.size === 0) {
        setBusinessCategories({data: [], promised: false});
        return;
      }

      const mccCodesData: {[_ in string]: string} = {};

      const data = await Promise.all(
        snapshot.docs.map(async doc => {
          const category: BusinessCategoryData = {
            ...selectBusinessCategoryView(doc as UniversalSnapshot<BusinessCategory>),
            types: [],
          };

          // get types
          const typeSnapshot = await Firebase.firestore
            .collection(Collections.businessCategories)
            .doc(doc.id)
            .collection(Collections.businessTypes)
            .get();

          if (typeSnapshot.size) {
            const types = typeSnapshot.docs.map(type =>
              selectBusinessTypeView(type as UniversalSnapshot<BusinessType>)
            );
            category.types.push(...types);

            // save mcc codes:
            types.forEach(type => {
              mccCodesData[type.name] = type.mcc;
            });
          }

          return category;
        })
      );

      setMccCodes(mccCodesData);
      setBusinessCategories({data, promised: false});
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isBusinessCategoriesLoaded = !businessCategories.promised;

  const getMccByBusinessType = useCallback(
    (businessType: string): string => {
      return mccCodes[businessType];
    },
    [mccCodes]
  );

  const getBusinessCategoryByName = (name: string): BusinessCategoryView | undefined => {
    if (businessCategories.promised || !businessCategories.data) {
      return undefined;
    }
    return businessCategories.data.filter(x => x.name === name)?.[0];
  };

  const getBusinessTypeByName = (category: string, type: string): BusinessTypeView | undefined => {
    return getBusinessCategoryByName(category)?.types.filter(
      (x: BusinessTypeView) => x.name === type
    )?.[0];
  };

  const getBusinessCategoryNames = (activeOnly: boolean = true): string[] =>
    getBusinessCategories(activeOnly).map(x => x.name);

  const getBusinessTypeNames = (category: string, activeOnly: boolean = true): string[] =>
    getBusinessTypes(category, activeOnly).map(x => x.name);

  const getBusinessCategoryByCode = (
    code: BusinessCategoryCodeValue
  ): BusinessCategoryView | undefined => {
    if (businessCategories.promised || !businessCategories.data) {
      return undefined;
    }
    return businessCategories.data?.filter(x => x.code === code)?.[0];
  };

  const getBusinessCategories = (activeOnly: boolean = true): BusinessCategoryView[] => {
    if (businessCategories.promised || !businessCategories.data) {
      return [];
    }
    return (
      activeOnly ? businessCategories.data.filter(x => !x.deleted) : businessCategories.data
    ).sort((a, b) => a.order - b.order || a.name.localeCompare(b.name));
  };

  const getBusinessTypes = (category: string, activeOnly: boolean = true): BusinessTypeView[] => {
    if (businessCategories.promised || !businessCategories.data) {
      return [];
    }
    return (
      (activeOnly ? businessCategories.data.filter(x => !x.deleted) : businessCategories.data)
        .filter(x => x.name === category)?.[0]
        ?.types?.sort((a, b) => a.order - b.order || a.name.localeCompare(b.name)) ?? []
    );
  };

  return {
    isBusinessCategoriesLoaded,
    getMccByBusinessType,
    getBusinessTypeByName,
    getBusinessCategoryByName,
    getBusinessCategoryNames,
    getBusinessTypeNames,
    getBusinessCategoryByCode,
  };
};
