import {BusinessIDS} from '../lib/integrations/lexis/TopBusinessSearchResponse';

export enum ScorecardServiceName {
  experian = 'Experian',
  lexisNexis = 'LexisNexis',
  match = 'Match',
  plaid = 'Plaid',
  finicity = 'Finicity',
}

export type ScorecardReportId =
  | 'creditReport'
  | 'personReport'
  | 'businessReport'
  | 'instantIdQandA'
  | 'terminations'
  | 'linkedAccount'
  | 'connectFinicityInstitution';

export type LexisNexisAction =
  | 'peopleSearch'
  | 'peopleReport'
  | 'businessSearch'
  | 'businessReport';

export type ExperianAction = 'creditReport';

export type MasterCardAction = 'terminationInquery';

export type ScorecardAction = LexisNexisAction | ExperianAction | MasterCardAction;

export type LexisNexisPeopleReportActionPaylod = {
  type: 'people';
  uniqueId: string;
};

export type LexisNexisBusinessReportActionPayload = {
  type: 'business';
} & BusinessIDS;

export type ActionPayload =
  | LexisNexisPeopleReportActionPaylod
  | LexisNexisBusinessReportActionPayload;

export enum ScorecardExecutionStatus {
  neverRan = 'Never Ran',
  running = 'Running',
  complete = 'Complete',
  failed = 'Failed',
  actionRequired = 'Action Required',
  initialized = 'Initialized',
}
