import {MidInfo} from '@ozark/functions/src/functions/express/private/types/Reports';
import {useCallback, useEffect, useState} from 'react';
import {useApiContainer} from '../../../store';

const calculateFields = (data: MidInfo[]): MidInfo[] => {
  if (!data) return data;
  const totalSalesAmount = data.reduce((acc: number, next: MidInfo) => acc + next.salesAmount, 0);
  return data.map(i => ({
    ...i,
    percentOfTotal: i.salesAmount / totalSalesAmount,
    avgTicket: i.salesAmount / i.salesCount,
  }));
};

type UseMidsByProcessingDataProps = {
  month: number;
  year: number;
};

const useMidsByProcessingData = ({month, year}: UseMidsByProcessingDataProps) => {
  const [allData, setAllData] = useState<MidInfo[]>([]);
  const [top15Data, setTop15Data] = useState<MidInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const api = useApiContainer();

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await api?.reports.getMidsByProcessing(year, month);

      if (!response) {
        setAllData([]);
        setTop15Data([]);
        return;
      }

      const preparedData = calculateFields(response);

      setAllData(preparedData);
      setTop15Data(preparedData.slice(0, 15));
    } catch (err) {
      console.error(`An error occurred while getting MIDS by Processing data`, err);
      setAllData([]);
      setTop15Data([]);
    } finally {
      setIsLoading(false);
    }
  }, [month, setAllData, setIsLoading, setTop15Data, year]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {allData, isLoading, top15Data} as const;
};

export {useMidsByProcessingData};
