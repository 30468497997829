import AttachFileIcon from '@mui/icons-material/AttachFile';
import BusinessIcon from '@mui/icons-material/Business';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import HistoryIcon from '@mui/icons-material/History';
import {Box, Button, Divider, Grid, Paper, Tooltip, Typography} from '@mui/material';
import {Column} from '@ozark/common/api/Column';
import {GroupInfo, Line, SummaryBox, Table} from '@ozark/common/components';
import {
  currentFormatter,
  getCardNumber,
  getCaseType,
  getDate,
  getItemType,
  useReportingPageStyles,
} from '@ozark/common/components/reports';
import {Chargebacks, PaginatedResponse} from '@ozark/functions/src/functions/express/private/types';
import {ChargebacksHistory} from '@ozark/functions/src/functions/express/private/types/Chargebacks';
import {format, utcToZonedTime} from 'date-fns-tz';
import {isEmpty} from 'lodash';
import {Fragment, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {
  DisputeAttachment,
  DisputeAttachmentStatus,
  Firebase,
  getFileNameFromCloudPath,
  handleDisputeResponseUpload,
} from '../../..';
import {useDisputeAttachmentFiles, useNotification} from '../../../hooks';
import {FileToUpload, ResponseWizard, ResponseWizardFormValues} from './ResponseWizard';

type DialogOpen = {
  isOpen: boolean;
};

type Props = {
  userId: string | undefined;
  userName: string;
};

export const ChargebackDetails = ({userId, userName}: Props) => {
  const classes = useReportingPageStyles();
  const showNotification = useNotification();
  const history = useHistory<{
    chargeback: Chargebacks;
    caseHistory: ChargebacksHistory[];
    mid: string;
  }>();
  const [chargeback, setChargeback] = useState<Chargebacks>();
  const [paginatedCaseHistory, setCaseHistory] =
    useState<PaginatedResponse<ChargebacksHistory | {}>>();
  const [chargebackFiles, setChargebackFiles] =
    useState<PaginatedResponse<DisputeAttachment | []>>();
  const [chargebackResponseFiles, setChargebackResponseFiles] =
    useState<PaginatedResponse<DisputeAttachment | []>>();
  const [respondWizardOpen, setRespondWizardOpen] = useState<DialogOpen>({isOpen: false});
  const {disputeAttachments, saveResponse, onComplete} = useDisputeAttachmentFiles([
    ...new Set(
      paginatedCaseHistory?.data?.map(x => (x as ChargebacksHistory).caseNumber!.toString())
    ),
  ]);

  useEffect(() => {
    if (disputeAttachments.promised || !disputeAttachments.data) return;

    // system dispute notices
    const notices = disputeAttachments.data.filter(x => !x.author);
    setChargebackFiles({
      limit: 5,
      offset: 0,
      totalCount: disputeAttachments.data.length,
      sort: [['fileDate', 'DESC']],
      data: notices as DisputeAttachment[],
    });

    // merchant responses
    const response = disputeAttachments.data.filter(x => !!x.author);
    setChargebackResponseFiles({
      limit: 5,
      offset: 0,
      totalCount: disputeAttachments.data.length,
      sort: [['fileDate', 'DESC']],
      data: response as DisputeAttachment[],
    });
  }, [disputeAttachments]);

  const uploadAttachment = (
    responseId: string,
    filesToUpload: FileToUpload[],
    index: number,
    resolve: (value: unknown) => void
  ) => {
    if (index > filesToUpload.length - 1 || !chargeback) {
      resolve(true);
      return;
    }

    const fileToUpload = filesToUpload[index];

    handleDisputeResponseUpload({
      responseId,
      uid: userId,
      userName,
      mid: chargeback.mid,
      caseNumber: chargeback.caseNumber,
      file: fileToUpload.file,
      showNotification: showNotification,
      onComplete: () => {
        uploadAttachment(responseId, filesToUpload, index + 1, resolve);
      },
      skipSuccessNotification: true,
    });
  };

  const handleFinishWizard = async (data: ResponseWizardFormValues) => {
    try {
      if (!chargeback) {
        return;
      }
      // save response
      const responseId = await saveResponse(
        userId || 'N/A',
        userName,
        chargeback.mid,
        chargeback.caseNumber.toString(),
        data
      );

      // upload supporting documents
      if (data.filesToUpload && data.filesToUpload?.length > 0) {
        await new Promise(resolve => {
          uploadAttachment(responseId, data.filesToUpload!, 0, resolve);
        });
      }

      // complete response
      await onComplete(chargeback.caseNumber.toString(), responseId);

      showNotification(
        'success',
        'Response successfully saved. The file will appear in the list after uploading to SFTP.'
      );
      setRespondWizardOpen({isOpen: false});
    } catch (err: any) {
      console.error(`failed to save response. ${err.toString()}`);
      showNotification('error', 'Failed to save response.');
    }
  };

  useEffect(() => {
    const {state} = history.location;
    if (state.chargeback && state.caseHistory) {
      setChargeback(state.chargeback);
      setCaseHistory({
        limit: 5,
        offset: 0,
        totalCount: state.caseHistory.length,
        sort: [['caseAmount', 'DESC']],
        data: state.caseHistory as ChargebacksHistory[],
      });
    }
  }, [history]);

  const getDisputeInformationInfo = (): GroupInfo[] => {
    let col1: Line = {
      'Item Type': getItemType(chargeback?.itemType as number),
      'Case Type': getCaseType(chargeback?.caseType as number),
      'Case Amount': currentFormatter.format(chargeback?.caseAmount || 0),
      'Visa RDR': chargeback?.visaRdrIndicator === 'Y' ? 'Yes' : 'No',
    };
    let col2: Line = {
      'Merchant Reference ID': chargeback?.mid,
      'Date Loaded': getDate(chargeback?.dateLoaded),
      Reason: `${chargeback?.reasonDesc} `,
      Code: chargeback?.reasonCode,
    };

    return [
      {
        icon: <BusinessIcon />,
        title: 'Dispute Information',
        columns: [col1, col2],
      },
    ];
  };

  const getDisputeTransactionSummaryInfo = (): GroupInfo[] => {
    return [
      {
        icon: <BusinessIcon />,
        title: 'Dispute Transaction Summary',
        columns: [
          {
            'Transaction ID': chargeback?.transId,
            Card: getCardNumber(chargeback?.cardholderAccountNumber),
            'Transaction Amount': currentFormatter.format(chargeback?.merchAmount || 0),
            'Date of Transaction': getDate(chargeback?.dateTransaction),
          },
        ],
      },
    ];
  };

  const boxHeader = (icon: JSX.Element, title: string) => (
    <Typography
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        height: 48,
        alignItems: 'center',
        m: 0,
        p: 2,
        pt: 3.5,
        pb: 2.5,
        mb: 0.5,
        '& > *': {
          mt: 0,
          mb: 0,
          ml: 1,
          mr: 1,
        },
        whiteSpace: 'normal',
        color: '#4d6575',
      }}
      variant="body1"
      component="div"
      noWrap
    >
      {icon} <b style={{flex: 1}}>{title}</b>
    </Typography>
  );

  const isRDR = () => chargeback?.visaRdrIndicator === 'Y' && chargeback?.cardBrand === 1;

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="row" alignItems="stretch">
        <Grid item xs={6}>
          <SummaryBox
            groups={getDisputeInformationInfo()}
            paperStyle={{borderTop: theme => `solid 4px ${theme.palette.primary.main}`}}
          />
        </Grid>
        <Grid item xs={6}>
          <SummaryBox
            groups={getDisputeTransactionSummaryInfo()}
            paperStyle={{borderTop: theme => `solid 4px ${theme.palette.primary.main}`}}
          />
        </Grid>
        <Grid item xs={6}>
          <Paper sx={{borderTop: '4px solid', borderTopColor: 'primary.main'}}>
            {boxHeader(<HistoryIcon />, 'Case History')}
            <Divider />
            {isEmpty(paginatedCaseHistory) && (
              <Typography sx={{mt: 4, textAlign: 'center'}}>No Case History</Typography>
            )}
            {!isEmpty(paginatedCaseHistory) && (
              <Table
                stickyHeader
                scrollableBody
                customHeight="75vh"
                columns={historyColumsConfig}
                data={paginatedCaseHistory as PaginatedResponse<ChargebacksHistory>}
                paginate
              />
            )}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            sx={{borderTop: '4px solid', borderTopColor: 'primary.main', minHeight: 180, mb: 2}}
          >
            {boxHeader(<FeedbackIcon />, 'Cardholder Dispute Notice(s)')}
            <Divider />
            {isEmpty(chargebackFiles?.data) && (
              <Typography sx={{mt: 4, textAlign: 'center'}}>
                No Cardholder Dispute Notices
              </Typography>
            )}
            {!isEmpty(chargebackFiles?.data) && (
              <Table
                stickyHeader
                scrollableBody
                customHeight="75vh"
                columns={filesColumnsConfig}
                data={chargebackFiles as PaginatedResponse<DisputeAttachment>}
                paginate
              />
            )}
          </Paper>
          <Paper sx={{borderTop: '4px solid', borderTopColor: 'primary.main', minHeight: 180}}>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              {boxHeader(<DriveFolderUploadIcon />, 'Merchant Response(s)')}
              <Button
                disabled={!chargeback || isRDR()}
                variant="contained"
                onClick={() => setRespondWizardOpen({isOpen: true})}
                size="small"
                sx={{mt: 1.5, mb: 1.5, mr: 2}}
              >
                {isRDR() ? 'RDR Completed' : 'Respond'}
              </Button>
            </Box>
            <Divider />
            {isEmpty(chargebackResponseFiles?.data) && (
              <Typography sx={{mt: 4, textAlign: 'center'}}>No Merchant Responses</Typography>
            )}
            {!isEmpty(chargebackResponseFiles?.data) && (
              <Table
                stickyHeader
                scrollableBody
                customHeight="75vh"
                columns={filesColumnsConfig}
                data={chargebackResponseFiles as PaginatedResponse<DisputeAttachment>}
                paginate
              />
            )}
          </Paper>
        </Grid>
        {respondWizardOpen.isOpen && (
          <ResponseWizard
            onSubmit={handleFinishWizard}
            onClose={() => setRespondWizardOpen({isOpen: false})}
          />
        )}
      </Grid>
    </div>
  );
};

const filesColumnsConfig: Column<DisputeAttachment>[] = [
  {
    id: 'label',
    numeric: false,
    sortable: false,
    export: false,
    label: 'File Name',
    selector: row => {
      return (
        <Link
          to={'#'}
          onClick={async e => {
            e.stopPropagation();
            const storageRef = Firebase.storage.ref(row.cloudPathView);
            const a = document.createElement('a');
            a.href = await storageRef.getDownloadURL();
            a.setAttribute(
              'download',
              getFileNameFromCloudPath(row.cloudPathView) ?? 'IN_5846_ZipCases_.TSYSI'
            );
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }}
        >
          {row.label}
        </Link>
      );
    },
  },
  {
    id: 'author',
    numeric: false,
    sortable: false,
    export: false,
    label: 'Author',
    selector: row => {
      if (row.author) {
        return row.author.name;
      } else {
        return 'System';
      }
    },
  },
  {
    id: 'fileDate',
    numeric: false,
    sortable: false,
    export: false,
    label: 'Date Loaded',
    selector: row => {
      const localTime = utcToZonedTime(
        row.fileDate.toDate(),
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      return (
        <Fragment>
          <Box display="flex" alignItems="center">
            <Tooltip
              key={`tooltip-${row.id}`}
              title={
                row.status === DisputeAttachmentStatus.Created
                  ? 'The response file created but not uploaded to TSYS yet'
                  : 'The response file already sent to TSYS'
              }
            >
              {row.status === DisputeAttachmentStatus.Uploaded ? (
                <FileDownloadDoneIcon style={{marginRight: 15}} fontSize="small" />
              ) : (
                <AttachFileIcon style={{marginRight: 15}} fontSize="small" />
              )}
            </Tooltip>
            <span>{format(localTime, 'MM-dd-yyyy-MM HH:mm')}</span>
          </Box>
        </Fragment>
      );
    },
  },
];

const historyColumsConfig: Column<ChargebacksHistory>[] = [
  {
    id: 'caseNumber',
    numeric: false,
    sortable: false,
    export: false,
    label: 'Case ID',
  },
  {
    id: 'itemType',
    numeric: false,
    sortable: false,
    export: false,
    label: 'Item Type',
    selector: row => getItemType(row.itemType),
  },
  {
    id: 'caseType',
    numeric: false,
    sortable: false,
    export: false,
    label: 'Case Type',
    selector: row => getCaseType(row.caseType),
  },
  {
    id: 'dateLoaded',
    numeric: false,
    sortable: false,
    export: false,
    label: 'Date Loaded',
    selector: row => getDate(row.dateLoaded),
  },
];
