import {Dispositions, Markdown, MarkdownOptions, ReasonInputType} from '@ozark/common';
import {CreateDisputeReasonProps, DisputeReason} from '../../ApplicationDisputeHandlers';
import {createDisputeReason} from '../createDisputeReason';

export type PendingReasonSettings = CreateDisputeReasonProps & {
  title: string;
  showDepositVerification?: boolean;
  text: string;
  document?: string;
  info: string;
};

export enum MerchantDocumentsPendingApplication {
  depositAccountVerification = 'Deposit Account Verification',
  einVerification = 'Tax Identification Number (EIN) Verification',
  ssnVerification = 'Social Security Number Verification',
  proofOfPayments = 'Tax Liens Proof of Payment',
  letterOfAttestation = 'Letter Of Attestation',
  marketingMaterials = 'Marketing Materials',
  proofOfPhysicalLocation = 'Proof of Physical Location',
  monthlyProcessingLimits = 'Monthly Processing Limits',
  singleTransactionLimits = 'Single Transaction Limits',
  stateOfGoodStanding = 'Secretary of State Good Standing',
  otherDocuments = 'Other Documents',
}

export const UrlToOpenTemplate: string = '{url_to_open}';

export const PendingReasons: PendingReasonSettings[] = [
  {
    name: 'Unable to Validate Banking',
    description: `We are unable to validate the bank deposit account provided. Please provide one of the following:
* Voided check
* Bank letter containing the account and routing number and business name
* Bank statement showing the complete account number and routing number
  `,
    type: ReasonInputType.Checkbox,
    title: 'Deposit Account Verification',
    showDepositVerification: true,
    text: 'Click here to upload a voided check, bank letter, or bank statement issued from your bank',
    document: MerchantDocumentsPendingApplication.depositAccountVerification,
    info: `The voided check, bank letter, or bank statements must be printed from your bank displaying your company name with the corresponding routing number and account number provided.`,
  },
  {
    name: 'Unable to Validate EIN',
    description: `We are unable to validate the Tax Identification Number (EIN) of the legal entity. Please provide the correct EIN# or the IRS SS-4 letter`,
    type: ReasonInputType.Checkbox,
    title: 'Tax Identification Number (EIN) Verification',
    text: `Click here to upload a copy of the applicant's IRS SS-4 letter displaying the company name and 9 digit Tax Identification Number (EIN) issued from the IRS or please provide the correct number below.`,
    document: MerchantDocumentsPendingApplication.einVerification,
    info: `The IRS SS-4 letter must be legible, include all pages, and be in the business name provided.`,
  },
  {
    name: 'Unable to Validate SSN',
    description: `We are unable to validate the Social Security Number provided. Please provide one of the following:
  * Applicant’s SSN card
  * First page of applicant’s personal tax return
  `,
    type: ReasonInputType.Checkbox,
    title: 'Social Security Number Verification',
    text: `Click here to upload a copy of the applicant's Social Security Card or the first page of the personal tax return displaying the name and social security number provided.`,
    document: MerchantDocumentsPendingApplication.ssnVerification,
    info: `The Social Security Card or first page of the personal tax return must be legible and be in the applicant's name provided.`,
  },
  {
    name: 'Credit Report Fraud Alert',
    description: `The applicant’s credit profile shows a fraud alert has been added. We will need to clear the fraud alert by calling the merchant at the phone number listed on the credit report. Please advise of a date and timeframe for us to make an outbound call.`,
    type: ReasonInputType.Checkbox,
    title: 'Identity Verification',
    text: `The applicant's credit profile shows a fraud alert. Please call our offices at 866-849-2445 to confirm your identity per your request.`,
    info: `This security verification is to help protect and validate your identity per your request.`,
  },
  {
    name: 'Credit Report Frozen',
    description: `The applicant currently has a security freeze on their credit profile. Please contact the credit bureau Experian to request a temporary lift by calling 800-509-8495 x2, x1, x1, x1. Please advise once this has been completed so we may attempt to access the applicant's credit profile.`,
    type: ReasonInputType.Checkbox,
    title: 'Identity Security Frozen',
    text: `The applicant's credit profile has a security freeze. Please contact the credit bureau Experian to request a temporary lift by calling 800-509-8495 x2, x1, x1, x1. Please advise once this has been completed so we may attempt to access the applicant's credit profile.`,
    info: `This security verification is to help protect and validate your identity per your request.`,
  },
  {
    name: 'Merchant Reserve Agreement',
    description: `Please acknowledge a merchant reserve agreement will be applied that reflects the following terms:`,
    type: ReasonInputType.RichText,
    title: 'Merchant Reserve Agreement',
    text: `Please acknowledge a merchant reserve agreement will be applied that reflects the following terms: <a href=${UrlToOpenTemplate} rel='noreferrer' target='_blank'>Merchant Reserve Agreement Terms & Conditions</a>`,
    info: `Our credit team has determined that we are able to approve your account with a merchant reserve to help offset the increased risk exposure on your account. The acknowledgement of the merchant reserve will approve your account. As you process with good performance and build a positive history, your account will be re-evaluated to possibly reduce or remove the merchant reserve.`,
  },
  {
    name: 'Tax Liens Proof of Payment',
    description: `The applicant's credit profile lists one or more state and/or federal tax liens above our risk threshold. Please provide a copy of the printout from the Federal/State showing a zero balance`,
    type: ReasonInputType.Checkbox,
    title: 'Tax Liens Proof of Payment',
    text: `Click here to upload a copy of the printout from the Federal/State showing a zero balance.`,
    document: MerchantDocumentsPendingApplication.proofOfPayments,
    info: `The tax lien proof of payment documentation must be legible, include all pages, and be in the applicant's name or business name provided.`,
  },
  {
    name: 'Letter of Attestation',
    description: `Please provide a letter of attestation regarding the following:`,
    type: ReasonInputType.RichText,
    title: 'Letter of Attestation',
    text: `Click here to upload a signed letter stating the attached`,
    document: MerchantDocumentsPendingApplication.letterOfAttestation,
    info: `The letter of attestation is required for compliance due to the nature of the industry your business is in.`,
  },
  {
    name: 'Marketing Materials',
    description: `Merchants that are MOTO are required to provide a description of all products and services they offer. Please provide the following:
* Company marketing material
* Picture(s) of the inventory
* Detailed description addressing the timeframe of the delivery methods, order process, billing process and shipping process
* Sample contract provided to the consumer including the refund policy
* Price points and fee schedule for all products & services offered`,
    type: ReasonInputType.Checkbox,
    title: 'Marketing Materials',
    text: `Click here to upload a copy of the following:\n
* Company marketing material\n
* Picture(s) of the inventory\n
* Detailed description addressing the timeframe of the delivery methods, order process, billing process and shipping process\n
* Sample contract provided to the consumer including the refund policy\n
* Price points and fee schedule for all products & services offered`,
    document: MerchantDocumentsPendingApplication.marketingMaterials,
    info: `The marketing material documents must be legible and be in the applicant's name or business name provided.`,
  },
  {
    name: 'Proof of Physical Location',
    description: `We are unable to validate the applicant's business online through websites, marketing sites, reviews, images, etc. Please provide the merchant's current business license and/or store lease agreement`,
    type: ReasonInputType.Checkbox,
    title: 'Proof of Physical Location',
    text: `Click here to upload a copy of the applicant's business license and/or store lease agreement.`,
    document: MerchantDocumentsPendingApplication.proofOfPhysicalLocation,
    info: `The business license or store lease agreement must be legible and be in the applicant's name or business name provided.`,
  },
  {
    name: 'Website Updates',
    description: `The website appears to be under construction or has not been completed with all required compliance documentation. The website must include the following information:
* Home Page
* Contact Page
* Terms and Conditions
* Privacy Policy
* Refund Policy
* Shipping Policy (if applicable)
* Products/Ingredients List (if applicable)
`,
    type: ReasonInputType.Checkbox,
    title: 'Website Updates',
    text: `The applicant's website is missing required regulatory documentation. Please advise once all items have been confirmed visible on the website:\n
* Home Page\n
* Contact Page\n
* Terms and Conditions\n
* Privacy Policy\n
* Refund Policy\n
* Shipping Policy (if applicable)\n
* Products/Ingredients List (if applicable)`,
    info: `The website requirements are required for compliance and to help reduce customer disputes. Please comment below once all items have been confirmed to be visible on the website provided.`,
  },
  {
    name: 'Request for Docs - AMV',
    description: `Due to the high average monthly volume, please provide one of the following:
* 3 months most recent business bank statements
* 3 months most recent processing statements
* 2 years most recent business tax returns, P&L, and Balance Sheet
  `,
    type: ReasonInputType.Checkbox,
    title: 'Higher Monthly Processing Limit',
    showDepositVerification: true,
    text: `Click here to upload a copy of one of the following:\n
* 3 months most recent business bank statements in business name\n
* 3 months most recent processing statements in business name\n
* 2 years most recent business tax returns, P&L, and Balance Sheet in business name`,
    document: MerchantDocumentsPendingApplication.monthlyProcessingLimits,
    info: `Bank statements, processing statement, business tax returns, P&L, and balance sheets must be legible, include all pages, and be in the business name provided.`,
  },
  {
    name: 'Request for Docs - High Ticket',
    description: `Due to the high ticket amount, please provide one of the following:
* 3 months most recent business bank statements
* 3 months most recent processing statements
* 2 years most recent business tax returns, P&L, and Balance Sheet
  `,
    type: ReasonInputType.Checkbox,
    title: 'Higher Single Transaction Limit',
    showDepositVerification: true,
    text: `Click here to upload a copy of one of the following:\n
* 3 months most recent business bank statements in business name\n
* 3 months most recent processing statements in business name\n
* 2 years most recent business tax returns, P&L, and Balance Sheet in business name`,
    document: MerchantDocumentsPendingApplication.singleTransactionLimits,
    info: `Bank statements, processing statement, business tax returns, P&L, and balance sheets must be legible, include all pages, and be in the business name provided.`,
  },
  {
    name: 'Secretary of State Good Standing',
    description: `The applicant's business Secretary of State shows that the registration is either past due or they are not in good standing/pending inactive. Please provide proof of reinstatement or good standing.`,
    type: ReasonInputType.Checkbox,
    title: 'Secretary of State Good Standing',
    text: `Click here to upload a copy of the applicant's business Secretary of State reinstatement or good standing.`,
    document: MerchantDocumentsPendingApplication.stateOfGoodStanding,
    info: `The Secretary of State good standing is required for compliance by your state. The reinstatement or good standing letter must be legible, include all pages, and be in the business name provided.`,
  },
  {
    name: 'Other Reason',
    description: `Other Reason`,
    type: ReasonInputType.RichText,
    document: MerchantDocumentsPendingApplication.otherDocuments,
    title: 'Other Reason',
    text: `Click here to upload`,
    info: `All requested documents must be legible, include all pages, and be in the business name provided.`,
  },
];

export const pending = {
  [Dispositions.uwPending]: PendingReasons.map(reason => {
    const {name, description, type, params} = reason as CreateDisputeReasonProps;
    return createDisputeReason({
      name: name,
      description: Markdown?.render(description, {linkify: true} as MarkdownOptions) || '',
      type: type,
      params: params,
    });
  }) as DisputeReason[],
} as {[key in Dispositions]?: DisputeReason[]};
