import {Grid, Paper} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ApplicationView, useNotification} from '@ozark/common';
import IframeResizer from 'iframe-resizer-react';
import {useEffect, useRef} from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    iframe: {
      minHeight: 600,
      width: 1,
      minWidth: '100%',
      border: 'none',
    },
  })
);

export const ApplicationEdit = ({
  application,
  token,
}: {
  application: ApplicationView;
  token: string | null | undefined;
}) => {
  const classes = useStyles();
  const iframeRef = useRef(null);
  const showNotification = useNotification();

  useEffect(() => {
    const handleMessage = (event: MessageEvent<any>) => {
      if (event.data.action === 'alert') {
        showNotification(event.data.severity, event.data.message);
      }
    };
    window.addEventListener('message', handleMessage, false);
    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.container}>
          <IframeResizer
            className={classes.iframe}
            forwardRef={iframeRef}
            heightCalculationMethod="lowestElement"
            inPageLinks
            log={false}
            checkOrigin={false}
            src={`${process.env.REACT_APP_APP_URL}/portal?token=${token}&disableLogRocket=1`}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ApplicationEdit;
export {Card} from './Card';
export {DisputeReasonsDetail} from './DisputeReasonsDetail';
export {PricingEquipment} from './PricingEquipment';
export {ResendDisputeEmailsDialog} from './ResendDisputeEmailsDialog';
