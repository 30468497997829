import {Box, Typography} from '@mui/material';
import {VolumeFilterTypes} from '@ozark/functions/src/functions/express/private/types/Reports';
import {AllMIDs} from '@ozark/functions/src/shared';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {APPLICATIONS_ANALYTICS_DETAILS_ROUTE, SALES_LEADS_ROUTE} from '../../constants/routes';
import {useUserInfo} from '../../hooks';
import {useApiContainer, useMidsContainer} from '../../store';
import {formatNumber} from '../../util';
import {BoxFlexed} from '../common';
import {LoadingContainer} from '../Loading/LoadingContainer';
import {CountTile, CountTileProp} from '../Tiles';
import {getFilterPeriod, VolumeFilter} from './FilterPeriodSelect';

type Props = {
  filters: VolumeFilter;
};

export enum ApplicationAnalyticValueTypes {
  totalDeals = 'totalDeals',
  approved = 'approved',
  // conditionallyApproved = 'conditionallyApproved',
  pending = 'pending',
  declined = 'declined',
  withdrawn = 'withdrawn',
  salesLeads = 'salesLeads',
  signed = 'signed',
  sent = 'sent',
  pendingSignature = 'pendingSignature',
  incomplete = 'incomplete',
}

export const ApplicationAnalyticsPage = ({filters}: Props) => {
  const {isErpUser, isAgent} = useUserInfo();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [applicationAnalytics, setApplicationAnalytics] = useState<CountTileProp[]>([]);
  const {selectedMid} = useMidsContainer();

  const applicationAnalyticDetailsOnClick = (type: ApplicationAnalyticValueTypes) => {
    history.push(APPLICATIONS_ANALYTICS_DETAILS_ROUTE, {
      type,
      title: tilesConfig[type].name,
      startDate: filters.startDate,
      endDate: filters.endDate,
    });
  };

  const salesLeadsOnClick = () => {
    history.push(SALES_LEADS_ROUTE, {
      startDate: filters.startDate,
      endDate: filters.endDate,
    });
  };

  const tilesConfig: {[_ in ApplicationAnalyticValueTypes]: {name: string; onClick?: () => void}} =
    {
      [ApplicationAnalyticValueTypes.totalDeals]: {
        name: 'Total Deals',
        onClick: () => applicationAnalyticDetailsOnClick(ApplicationAnalyticValueTypes.totalDeals),
      },
      [ApplicationAnalyticValueTypes.approved]: {
        name: 'Approved',
        onClick: () => applicationAnalyticDetailsOnClick(ApplicationAnalyticValueTypes.approved),
      },
      // [ApplicationAnalyticValueTypes.conditionallyApproved]: {
      //   name: 'Conditionally Approved',
      //   onClick: () =>
      //     applicationAnalyticDetailsOnClick(ApplicationAnalyticValueTypes.conditionallyApproved),
      // },
      [ApplicationAnalyticValueTypes.pending]: {
        name: 'Pending',
        onClick: () => applicationAnalyticDetailsOnClick(ApplicationAnalyticValueTypes.pending),
      },
      [ApplicationAnalyticValueTypes.declined]: {
        name: 'Declined',
        onClick: () => applicationAnalyticDetailsOnClick(ApplicationAnalyticValueTypes.declined),
      },
      [ApplicationAnalyticValueTypes.withdrawn]: {
        name: 'Withdrawn',
        onClick: () => applicationAnalyticDetailsOnClick(ApplicationAnalyticValueTypes.withdrawn),
      },
      [ApplicationAnalyticValueTypes.salesLeads]: {name: 'Sales Leads', onClick: salesLeadsOnClick},
      [ApplicationAnalyticValueTypes.signed]: {
        name: 'Applications Signed',
        onClick: () => applicationAnalyticDetailsOnClick(ApplicationAnalyticValueTypes.signed),
      },
      [ApplicationAnalyticValueTypes.sent]: {
        name: 'Applications Sent',
        onClick: () => applicationAnalyticDetailsOnClick(ApplicationAnalyticValueTypes.sent),
      },
      [ApplicationAnalyticValueTypes.pendingSignature]: {
        name: 'Pending Signature',
        onClick: () =>
          applicationAnalyticDetailsOnClick(ApplicationAnalyticValueTypes.pendingSignature),
      },
      [ApplicationAnalyticValueTypes.incomplete]: {
        name: 'Incomplete Apps',
        onClick: () => applicationAnalyticDetailsOnClick(ApplicationAnalyticValueTypes.incomplete),
      },
    };

  const api = useApiContainer();

  useEffect(() => {
    setLoading(true);

    (async () => {
      try {
        const applicationAnalyticsResult = await api?.dashboard.getApplicationAnalytics(
          filters ?? getFilterPeriod(VolumeFilterTypes.MTD),
          selectedMid === AllMIDs ? undefined : selectedMid
        );

        if (applicationAnalyticsResult) {
          const analytics = Object.entries(applicationAnalyticsResult)
            .map(([key, value]) => {
              if (!(key in ApplicationAnalyticValueTypes)) {
                return null;
              }

              const tileConfig = tilesConfig[key as keyof typeof ApplicationAnalyticValueTypes];
              const isTileClickable =
                isErpUser || (isAgent && key !== ApplicationAnalyticValueTypes.salesLeads);

              return {
                name: tileConfig.name,
                count: formatNumber(value),
                onClick: isTileClickable ? tileConfig.onClick : undefined,
              } as CountTileProp;
            })
            .filter(x => x) as CountTileProp[];

          setApplicationAnalytics(analytics);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [filters, selectedMid, api]);

  return (
    <Box>
      <Typography variant="h5" gutterBottom color="secondary.main" fontSize="1.7rem">
        Application Analytics
      </Typography>
      <LoadingContainer loading={loading} minHeight={250}>
        <BoxFlexed flexWrap="wrap">
          {applicationAnalytics.map(item => (
            <CountTile key={item.name} {...item} />
          ))}
        </BoxFlexed>
      </LoadingContainer>
    </Box>
  );
};
