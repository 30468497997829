import {Button, CircularProgress, Grid, Grow} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {
  ApplicationView,
  AssociationType,
  Ticket,
  TicketStatus,
  useNotification,
  useUserInfo,
} from '../../index';
import {TicketStatusNames} from '../Tickets/constants/constants';
import {useTicketsAssociated} from '../Tickets/hooks/ticketsSlices/useTicketsAssociated';
import {TicketsListCard} from '../Tickets/TicketsList/TicketsListCard';

const MenuProps = {
  PaperProps: {
    style: {
      width: 300,
    },
  },
};

const DEFAULT_TICKET_STATUSES = [
  TicketStatus.Open,
  TicketStatus.InProgress,
  TicketStatus.Closed,
  TicketStatus.Unresolved,
];

type TicketsProps = {
  application: ApplicationView;
};

const ApplicationTickets = ({application}: TicketsProps) => {
  const [statuses, setStatuses] = useState(DEFAULT_TICKET_STATUSES);
  const [tickets, setTickets] = useState<Ticket[]>([]);

  const {isErpUser} = useUserInfo();
  const history = useHistory();

  const {
    ticketsAssociated: {
      promised: isLoadingApplicationTickets,
      data: applicationTickets,
      error: applicationTicketsError,
    },
  } = useTicketsAssociated(
    AssociationType.application,
    'association.application.id',
    application.id,
    statuses
  );

  const {
    ticketsAssociated: {
      promised: isLoadingMerchantTickets,
      data: merchantTickets,
      error: merchantTicketsError,
    },
  } = useTicketsAssociated(
    AssociationType.merchant,
    'association.merchant.mid',
    application.mid,
    statuses
  );

  const isLoading = isLoadingApplicationTickets || isLoadingMerchantTickets;

  const showNotification = useNotification();

  useEffect(() => {
    let nextTickets = merchantTickets ?? [];

    if (isErpUser && applicationTickets) {
      nextTickets = nextTickets.concat(applicationTickets ?? []);
    }

    setTickets(nextTickets);
  }, [isErpUser, applicationTickets, merchantTickets, setTickets]);

  useEffect(() => {
    if (applicationTicketsError) {
      showNotification('error', applicationTicketsError.message);
    }

    if (merchantTicketsError) {
      showNotification('error', merchantTicketsError.message);
    }
  }, [applicationTicketsError, merchantTicketsError, showNotification]);

  const onStatusesChange = useCallback((event: SelectChangeEvent<typeof statuses>) => {
    const {
      target: {value},
    } = event;

    const nextStatuses = (typeof value === 'string' ? value.split(',') : value) as TicketStatus[];

    setStatuses(nextStatuses);
  }, []);

  return (
    <Paper square elevation={1} sx={{p: 2}}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h6">Tickets</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() =>
              history.push('/tickets/view/all', {
                createNewTicket: true,
                association: {
                  type: AssociationType.merchant,
                  merchant: {
                    platform: application.platform,
                    mid: application.mid,
                    dba: application.doingBusinessAs,
                    legalName: application.legalBusinessName,
                  },
                },
              })
            }
          >
            New Ticket
          </Button>
        </Grid>
      </Grid>

      <Box alignItems="center" display="flex" my={2}>
        <FormControl sx={{m: 1, width: 350}}>
          <InputLabel id="statuses-select-label">Ticket Statuses</InputLabel>
          <Select
            multiple
            input={<OutlinedInput label="Ticket Statuses" />}
            labelId="statuses-select-label"
            MenuProps={MenuProps}
            renderValue={selected => selected.map(x => TicketStatusNames[x]).join(', ')}
            value={statuses}
            onChange={onStatusesChange}
          >
            {DEFAULT_TICKET_STATUSES.map(status => (
              <MenuItem key={status} value={status}>
                <Checkbox checked={statuses.includes(status)} />
                <ListItemText primary={TicketStatusNames[status]} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {isLoading && <CircularProgress sx={{ml: 2}} />}
      </Box>

      <Box my={2}>
        {!isLoading && (!tickets || !tickets.length) && (
          <Typography variant="subtitle1">No tickets found</Typography>
        )}

        {!!tickets?.length &&
          tickets.map((ticket: Ticket) => (
            <Grow in key={ticket.id}>
              <Box mr={2} mb={2}>
                {ticket.name}
                <TicketsListCard ticket={ticket} />
              </Box>
            </Grow>
          ))}
      </Box>
    </Paper>
  );
};

export {ApplicationTickets};
