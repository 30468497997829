import {CardBrand} from '../../../..';
import {FilterOption, MtdDefaultDateRange} from '../../../Filters';

export const DATETIME_FORMAT_CHARGEBACK = 'MMMM dd, yyyy ';

export enum TransactionType {
  'All' = 'All',
  'Chargeback' = 'Chargeback',
  'RDR' = 'RDR',
}

export const statusType: {[_: number]: string} = {
  0: 'N/A',
  1: 'New Case - First Chargeback',
  2: 'Case Update - Reversal',
  3: 'Case Update - PreArbitration',
  4: 'Case Update - Arbitration',
  5: 'Case Update',
  6: 'Fulfilled Documentation Request - Retrieval',
  7: 'Retrieval Request Update',
  8: "Dispute Resolved in Merchant's Favor - First Chargeback",
  9: "Dispute Resolved in Merchant's Favor - Reversal",
  10: "Dispute Resolved in Merchant's Favor - PreArbitration",
  11: "Dispute Resolved in Merchant's Favor - Arbitration",
  12: "Dispute Resolved In Merchant's Favor",
  13: "Dispute Resolved in Cardholder's Favor - First Chargeback",
  14: "Dispute Resolved in Cardholder's Favor - Reversal",
  15: "Dispute Resolved in Cardholder's Favor",
  16: "Dispute Resolved in Cardholder's Favor - PreArbitration",
  17: "Dispute Resolved in Cardholder's Favor - Arbitration",
  18: 'Case Correction/Update - First Chargeback',
  19: 'Case Correction/Update - Retrieval',
  20: 'Case Correction/Update - Chargeback',
  21: 'Case Correction/Update - Allocation',
  22: 'Case Correction/Update',
  23: 'New Retrieval Request',
  24: 'Second Retrieval Request',
  25: 'Outgoing PreArb - Reversal',
  26: 'PreArbitration',
  27: 'Arbitration',
  28: 'Collection - Exceeded Cardbrand Time Frame',
  29: 'Outgoing Compliance Case',
};

const chargebackTransactionTypeFilter: FilterOption = {
  id: 'transactionType',
  column: 'transactionType',
  label: 'Transaction Type',
  type: 'list',
  options: [
    {
      value: 'All',
      key: TransactionType.All,
    },
    {
      value: 'RDR (Visa only)',
      key: TransactionType.RDR,
    },
    {
      value: 'Chargeback',
      key: TransactionType.Chargeback,
    },
  ],
  operators: [
    {
      id: '__eq',
      label: 'equals',
    },
  ],
};

export const ChargebacksFilters: FilterOption[] = [
  {
    id: 'mid',
    column: 'mid',
    label: 'MID',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'bin',
    column: 'bin',
    label: 'BIN',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'caseNumber',
    column: 'caseNumber',
    label: 'Case number',
    type: 'number',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'dateLoaded',
    column: 'dateLoaded',
    label: 'Date Range',
    type: 'dateRange',
    dateFormat: DATETIME_FORMAT_CHARGEBACK,
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  chargebackTransactionTypeFilter,
];

export const ChargebacksSummaryFilters: FilterOption[] = [
  {
    id: 'originalTransactionDateRange',
    column: 'dateTransaction',
    label: 'Original Transaction Date',
    type: 'dateRange',
    dateFormat: DATETIME_FORMAT_CHARGEBACK,
    defaultDateRange: MtdDefaultDateRange,
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'dateLoaded',
    column: 'dateLoaded',
    label: 'Date of Dispute',
    type: 'dateRange',
    dateFormat: DATETIME_FORMAT_CHARGEBACK,
    defaultDateRange: MtdDefaultDateRange,
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'dateDisputeOrTransactionRange',
    column: 'dateDisputeOrTransaction',
    label: 'Date Range',
    type: 'dateRange',
    dateFormat: DATETIME_FORMAT_CHARGEBACK,
    defaultDateRange: MtdDefaultDateRange,
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'lastDateLoadedRange',
    column: 'lastDateLoaded',
    label: 'Last Change Date',
    type: 'dateRange',
    dateFormat: DATETIME_FORMAT_CHARGEBACK,
    defaultDateRange: MtdDefaultDateRange,
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
  {
    id: 'caseNumber',
    column: 'caseNumber',
    label: 'Case Number',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'reasonCode',
    column: 'reasonCode',
    label: 'Reason Code',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'reasonDesc',
    column: 'reasonDesc',
    label: 'Reason Description',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'contains',
      },
    ],
  },
  {
    id: 'bin',
    column: 'bin',
    label: 'BIN',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'dateDisputeOrTransaction',
    column: 'dateDisputeOrTransaction',
    label: 'Date',
    type: 'date',
    operators: [
      {
        id: '__between',
        label: 'equals',
      },
    ],
  },
  {
    id: 'last4OfCardNumber',
    column: 'last4OfCardNumber',
    label: 'Last 4 of Card Number',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'caseAmount',
    column: 'caseAmount',
    label: 'Dispute Amount',
    type: 'currency',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'cardBrand',
    column: 'cardBrand',
    label: 'Card Brand',
    type: 'multiSelect',
    options: [
      {
        value: 'Visa',
        key: CardBrand.visa,
      },
      {
        value: 'MasterCard',
        key: CardBrand.mastercard,
      },
      {
        value: 'Discover',
        key: CardBrand.discover,
      },
      {
        value: 'American Express',
        key: CardBrand.amex,
      },
    ],
    operators: [
      {
        id: '__in',
        label: 'equals',
      },
    ],
  },
  {
    id: 'status',
    column: 'status',
    label: 'Current Status',
    type: 'list',
    options: Object.keys(statusType).map(key => {
      return {
        value: statusType[+key],
        key: key,
      };
    }),
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  chargebackTransactionTypeFilter,
];

export const itemType: {[_: number]: string} = {
  1: 'New Cases',
  2: 'Fulfilled Retrieval Requests',
  3: 'Worked Case Resolved to Other',
  4: 'Worked Case Resolved to Merchant',
  5: 'Corrected Case',
  6: 'New Retrievals',
  7: 'Second Retrieval Requests',
  8: 'Outgoing PreArb',
};

export const caseType: {[_: number]: string} = {
  0: 'Retrieval',
  1: 'First Chargeback',
  2: 'Second Chargeback',
  3: 'First Reversal',
  4: 'Second Reversal',
  9: 'Visa Pre Arbs',
  11: 'Discover Retrieval',
  12: 'Discover Chargeback',
  13: 'Discover Reversal',
  14: 'Discover PreArbitration',
  15: 'Discover Incoming Dispute Arb',
  16: 'Discover PreArb/Arb Debit',
  17: 'American Express Retrieval',
  18: 'American Express Chargeback',
  19: 'American Express Reversal',
  20: 'Allocation',
  21: 'Collaboration',
  22: 'Allocation Reversal',
  23: 'Collaboration Reversal',
  24: 'Collaboration PreArbitration',
  25: 'MasterCard PreArbitration',
  26: 'MasterCard Arbitration',
};

export const getStatusName = (status: number) => {
  return statusType[status];
};

export const getDisputeTypeName = (status: number) => {
  return statusType[status]?.toLowerCase()?.indexOf('retrieval') > -1 ? 'Retrieval' : 'Chargeback';
};
