import {DashboardTable, DashTableRow} from '../common/DashboardTable';
import {DataIsLoading} from '../common/DataIsLoading';
import {DataIsMissing} from '../common/DataIsMissing';
import {WidgetProps} from '../common/types';
import {DataFormat} from '../common/utils';

export function WidgetPinDebit({loading, data}: WidgetProps) {
  if (loading) return <DataIsLoading />;
  if (!data) return <DataIsMissing />;

  const rows: DashTableRow[] = [
    {
      label: 'PIN Debit Transaction Count',
      value: data.pinDebitTransactionCount,
      dataFormat: DataFormat.number,
    },
    {
      label: 'PIN Debit Volume',
      value: data.pinDebitVolume,
      dataFormat: DataFormat.currency,
    },
    {
      label: 'EBT Transaction Count',
      value: data.ebtTransactionCount,
      dataFormat: DataFormat.number,
    },
    {
      label: 'EBT Volume',
      value: data.ebtVolume,
      dataFormat: DataFormat.currency,
    },
  ];

  return <DashboardTable data={rows} />;
}
