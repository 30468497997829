import {yupResolver} from '@hookform/resolvers/yup';
import DeleteIcon from '@mui/icons-material/Delete';
import {LoadingButton} from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField as MuiTextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useState} from 'react';
import {FileUploader} from 'react-drag-drop-files';
import {Control, Controller, SubmitHandler, useFieldArray, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {getErrorMessage, UploadDocument} from '../../util';
import {acceptedExtensions} from './constants';

const useStyles = makeStyles(() =>
  createStyles({
    fileUploader: {
      maxWidth: '100%!important',
    },
  })
);

interface Props {
  onClose: () => void;
  initialFolderName: string | null;
  documentTypeOptions?: string[];
  folderNamesOptions: string[];
  onSubmit: (filesToUpload: FileToUpload[]) => Promise<void>;
  hideFolder?: boolean;
}

export interface FileToUpload {
  file: File;
  folderName: string;
  uploadDocument: UploadDocument | null;
}

type FormValues = {
  defaultFolderName: string | null;
  filesToUpload: FileToUpload[];
};

interface UploadedFileProps {
  fileToUpload: FileToUpload;
  control: Control<FormValues, FormValues>;
  index: number;
  removeItem: (index: number) => void;
  documentTypeOptions?: string[];
  disabled: boolean;
  folderNamesOptions: string[];
  errors: any;
  hideFolder?: boolean;
}

interface DeleteFileProps {
  disabled: boolean;
  removeItem: () => void;
}

interface DocumentOptionsProps {
  disabled: boolean;
  index: number;
  documentTypeOptions: string[];
  errors: any;
  control: Control<FormValues, FormValues>;
}

const fileToUploadSchema = yup.object({
  uploadDocument: yup.string().required('Type is required').nullable(),
});

export const insertFormSchema = yup.object({
  filesToUpload: yup.array().of(fileToUploadSchema),
});

const DeleteFile = ({disabled, removeItem}: DeleteFileProps) => {
  return (
    <IconButton
      disabled={disabled}
      onClick={() => removeItem()}
      sx={{
        height: '24px',
        marginTop: 'auto!important',
        marginBottom: 'auto!important',
        padding: 0,
      }}
      color="secondary"
    >
      <DeleteIcon />
    </IconButton>
  );
};

const DocumentOptions = ({
  disabled,
  index,
  documentTypeOptions,
  errors,
  control,
}: DocumentOptionsProps) => {
  return (
    <Controller
      name={`filesToUpload.${index}.uploadDocument`}
      control={control}
      rules={{required: true}}
      render={({field: {onChange, value, ...props}}) => (
        <Autocomplete
          sx={{width: '100%'}}
          options={documentTypeOptions}
          disableClearable={!!value}
          disabled={disabled}
          onChange={(_e, value) => {
            onChange(value);
          }}
          renderInput={params => (
            <MuiTextField
              {...params}
              error={Boolean(getErrorMessage(`filesToUpload.${index}.uploadDocument`, errors))}
              helperText={getErrorMessage(`filesToUpload.${index}.uploadDocument`, errors)?.message}
              label="Type"
              required
              variant="outlined"
              margin="normal"
            />
          )}
          {...props}
        />
      )}
    />
  );
};

const UploadedFile = ({
  fileToUpload,
  control,
  index,
  removeItem,
  documentTypeOptions,
  disabled,
  folderNamesOptions,
  errors,
  hideFolder,
}: UploadedFileProps) => {
  return (
    <>
      <Grid item xs={6}>
        <MuiTextField
          variant="outlined"
          margin="normal"
          value={fileToUpload.file.name}
          label="File Name"
          disabled
          fullWidth
        />
      </Grid>
      {hideFolder && !!documentTypeOptions && (
        <Grid item xs={6}>
          <Stack direction="row" spacing={1} sx={{width: '100%'}}>
            <DocumentOptions
              documentTypeOptions={documentTypeOptions}
              disabled={disabled}
              index={index}
              control={control}
              errors={errors}
            />
            <DeleteFile disabled={disabled} removeItem={() => removeItem(index)} />
          </Stack>
        </Grid>
      )}
      {!hideFolder && !!documentTypeOptions && (
        <Grid item xs={3}>
          <DocumentOptions
            documentTypeOptions={documentTypeOptions}
            disabled={disabled}
            index={index}
            control={control}
            errors={errors}
          />
        </Grid>
      )}
      {!hideFolder && (
        <Grid item xs={!!documentTypeOptions ? 3 : 6}>
          <Stack direction="row" spacing={1} sx={{width: '100%'}}>
            <Controller
              name={`filesToUpload.${index}.folderName`}
              control={control}
              render={({field: {onChange, ...props}}) => (
                <Autocomplete
                  options={folderNamesOptions}
                  freeSolo
                  disabled={disabled}
                  onChange={(_e, value) => {
                    onChange(value);
                  }}
                  onInputChange={(_e, value) => {
                    onChange(value);
                  }}
                  sx={{width: '100%'}}
                  renderInput={params => (
                    <MuiTextField
                      {...params}
                      fullWidth
                      label="Folder Name"
                      variant="outlined"
                      margin="normal"
                    />
                  )}
                  {...props}
                />
              )}
            />
            <DeleteFile disabled={disabled} removeItem={() => removeItem(index)} />
          </Stack>
        </Grid>
      )}
    </>
  );
};

export const InsertAttachmentsDialog = ({
  onClose,
  initialFolderName,
  documentTypeOptions,
  folderNamesOptions,
  onSubmit,
  hideFolder,
}: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isSaving, setIsSaving] = useState(false);

  const {
    formState: {errors},
    control,
    handleSubmit,
    getValues,
  } = useForm<FormValues>({
    resolver: documentTypeOptions ? yupResolver(insertFormSchema) : undefined,
    defaultValues: {
      filesToUpload: [],
      defaultFolderName: initialFolderName,
    },
  });
  const {fields, append, remove} = useFieldArray({
    name: 'filesToUpload',
    control,
  });

  const handleChange = (filesToAdd: Array<File>) => {
    const defaultFolderName = getValues('defaultFolderName');

    const newFilesToUpload = Object.values(filesToAdd).map(file => {
      return {
        file,
        folderName: defaultFolderName,
        uploadDocument: null,
      } as FileToUpload;
    });
    append(newFilesToUpload);
  };

  const onSuccess: SubmitHandler<FormValues> = async data => {
    setIsSaving(true);
    try {
      await onSubmit(data.filesToUpload);
    } finally {
      setIsSaving(false);
    }
  };

  const onSubmitClick = async () => {
    await handleSubmit(onSuccess)();
  };

  const removeItem = (index: number) => {
    remove(index);
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="create-dialog-title"
      fullScreen={fullScreen}
      maxWidth={'lg'}
    >
      <DialogTitle id="create-dialog-title">Add Attachments</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              width: 900,
            },
          }}
        >
          <Grid container spacing={1}>
            {!hideFolder && (
              <Grid item xs={12}>
                <Controller
                  name="defaultFolderName"
                  control={control}
                  render={({field: {onChange, ...props}}) => (
                    <Autocomplete
                      options={folderNamesOptions}
                      freeSolo
                      disabled={isSaving}
                      onChange={(_e, value) => {
                        onChange(value);
                      }}
                      onInputChange={(_e, value) => {
                        onChange(value);
                      }}
                      renderInput={params => (
                        <MuiTextField
                          {...params}
                          label="Default Folder Name"
                          variant="outlined"
                          margin="normal"
                        />
                      )}
                      {...props}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FileUploader
                multiple={true}
                handleChange={handleChange}
                name="file"
                classes={classes.fileUploader}
                types={acceptedExtensions}
                disabled={isSaving}
              />
            </Grid>
            {fields.map((field, index) => {
              return (
                <UploadedFile
                  key={field.id}
                  fileToUpload={field as FileToUpload}
                  control={control}
                  index={index}
                  removeItem={removeItem}
                  documentTypeOptions={documentTypeOptions}
                  disabled={isSaving}
                  folderNamesOptions={folderNamesOptions}
                  errors={errors}
                  hideFolder={hideFolder}
                />
              );
            })}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          onClick={onSubmitClick}
          color="primary"
          disabled={fields.length == 0}
          loading={isSaving}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
