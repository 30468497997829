import {useCallback} from 'react';
import {
  AssigneeType,
  Collections,
  TicketAssignee,
  TicketHistoryRecordType,
  TicketTypeAndCategory,
} from '../../..';
import {Firebase} from '../../../firebase';
import {getTicketCategory} from '../utils/ticketCategory';
import {useTicketHistoryRecord} from './useTicketHistoryRecord';

export const useTicketAutoAssignee = () => {
  const {addTicketHistoryRecord} = useTicketHistoryRecord();
  const autoSetAssignee = useCallback(
    async (ticketId: string, isDraftTicket: boolean, draft?: TicketTypeAndCategory) => {
      const ticketTypeCategory = await getTicketCategory(draft?.type?.id, draft?.category?.id);
      const autoAssignedDepartment = ticketTypeCategory?.assignment;
      const autoAssignedAssignee: TicketAssignee = {
        type: AssigneeType.erpDepartment,
        erpDepartment: autoAssignedDepartment,
      };

      await Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .update({assignee: autoAssignedAssignee, updatedAt: Firebase.now()});
      await addTicketHistoryRecord(
        ticketId,
        isDraftTicket,
        `Assignee auto-changed to ${autoAssignedDepartment} (ERP Department)`,
        TicketHistoryRecordType.Assignee,
        undefined,
        `Assignee auto-changed to ${autoAssignedDepartment}`
      );
      return autoAssignedAssignee;
    },
    [addTicketHistoryRecord]
  );
  return {
    autoSetAssignee,
  };
};
