import {SyntheticListenerMap} from '@dnd-kit/core/dist/hooks/utilities';
import {TableCell} from '@mui/material';
import {ResourceView} from '@ozark/common';
import {format} from 'date-fns';
import prettyBytes from 'pretty-bytes';
import {useDisplayContext} from './DisplayContext';
import {DisplayDeleteButton} from './DisplayDeleteButton';
import {DisplayDescription} from './DisplayDescription';
import {DisplayDragHandle} from './DisplayDragHandle';
import {DisplayEditButton} from './DisplayEditButton';
import {DisplayText} from './DisplayText';
import {DisplayTitle} from './DisplayTitle';
import {DisplayViewableBy} from './DisplayViewableBy';

interface Props {
  resourceView: ResourceView;
  listeners?: SyntheticListenerMap | undefined;
}

export const DisplayDocumentAsRowContent = ({resourceView, listeners}: Props) => {
  const {allowEdit, isDragHandleAllowed} = useDisplayContext();
  return (
    <>
      <TableCell>
        <DisplayTitle resourceView={resourceView} />
      </TableCell>
      <TableCell>
        <DisplayDescription resourceView={resourceView} />
      </TableCell>
      {allowEdit && (
        <TableCell>
          <DisplayViewableBy resourceView={resourceView} />
        </TableCell>
      )}
      <TableCell>
        <DisplayText value={resourceView.size ? prettyBytes(resourceView.size) : ''} />
      </TableCell>
      <TableCell>
        <DisplayText value={resourceView.contentType ?? ''} />
      </TableCell>
      <TableCell>
        <DisplayText value={format(resourceView.createdAt.toDate(), 'MM/dd/yyyy h:mm a')} />
      </TableCell>
      <DisplayEditButton resourceView={resourceView} />
      <DisplayDeleteButton resourceId={resourceView.id} />
      {isDragHandleAllowed && <DisplayDragHandle listeners={listeners} />}
    </>
  );
};
