import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, TableCell} from '@mui/material';
import {useDisplayContext} from './DisplayContext';

interface Props {
  resourceId: string;
}

export const DisplayDeleteButton = ({resourceId}: Props) => {
  const {allowEdit, handleDeleteClick} = useDisplayContext();
  const deleteButton = (
    <IconButton size="small" onClick={() => handleDeleteClick(resourceId)}>
      <DeleteIcon />
    </IconButton>
  );

  if (!allowEdit) {
    return null;
  }

  return <TableCell>{deleteButton}</TableCell>;
};
