/* eslint-disable react-hooks/exhaustive-deps */
import SortIcon from '@mui/icons-material/Sort';
import {Button, Link} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationView,
  Collections,
  Dispositions,
  Firebase,
  InfiniteSnapshotOptions,
  Lead,
  selectApplicationView,
  selectLeadView,
  UniversalSnapshot,
  useInfiniteSnapshots,
} from '@ozark/common';
import {InfiniteDocuments, Loading, Title} from '@ozark/common/components';
import {useState} from 'react';
import {useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {ApplicationsExportButton} from '../ApplicationsExportButton';
import {Card} from './Card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    grow: {
      flex: 1,
    },
    divider: {
      margin: theme.spacing(2),
    },
    placeholder: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    selectInput: {
      backgroundColor: 'transparent !important',
    },
  })
);

const Boarded = () => {
  const classes = useStyles();
  const history = useHistory();

  const {isUserAdmin, groupsMap} = useStore();

  const [options, setOptions] = useState<Partial<InfiniteSnapshotOptions>>({
    order: 'desc',
    orderBy: 'dispositionUpdatedAt',
    limit: 50,
    filter: query => query.where('disposition', '==', Dispositions.boarded),
  });

  const fetchAll = async () => {
    let query = Firebase.firestore
      .collection(Collections.applications)
      .where('disposition', '==', Dispositions.boarded)
      .orderBy('dispositionUpdatedAt', 'desc');

    const snapshot = await query.get();

    if (snapshot.empty || snapshot.docs.length === 0) {
      return [];
    }

    return snapshot.docs.map(doc => selectLeadView(doc as UniversalSnapshot<Lead>));
  };

  const {documents: applications, next} = useInfiniteSnapshots<ApplicationView>(
    Collections.applications,
    selectApplicationView,
    options
  );

  const toggleOrder = () =>
    setOptions({...options, order: options.order === 'asc' ? 'desc' : 'asc'});

  if (applications.promised) return <Loading />;

  return (
    <div className={classes.root}>
      <Title
        breadcrumbs={[
          <Link component="button" variant="body1" onClick={() => history.push(ROUTES.BOARDED)}>
            Boarded
          </Link>,
        ]}
      >
        <div className={classes.grow} />
        <Button
          size="small"
          onClick={toggleOrder}
          endIcon={
            options.order === 'desc' ? (
              <SortIcon style={{transform: 'rotateX(180deg)'}} />
            ) : (
              <SortIcon />
            )
          }
        >
          {options.order === 'desc' ? 'Newest at Top' : 'Oldest at Top'}
        </Button>
        {isUserAdmin() && (
          <ApplicationsExportButton
            filename="boarded-applications"
            getRows={fetchAll}
            rows={Object.values(applications.data ?? {})}
            sx={{ml: 2}}
            isBoardedAppsExport
          />
        )}
      </Title>
      <InfiniteDocuments
        documents={applications}
        next={next}
        itemSize={210}
        onDocumentRender={document => (
          <Card application={document} group={groupsMap[(document as ApplicationView).group.id]} />
        )}
      />
    </div>
  );
};

export default Boarded;
