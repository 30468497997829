import {
  ApplicationType,
  BillingMethod,
  EquipmentFileType,
  isOtherNotListedEquipment,
  PCIFee,
  PCIFeeDefaultValues,
  RateSet,
} from '@ozark/common';
import {emptyStringToNull} from '@ozark/common/helpers/schemaValidationHelpers';
import * as yup from 'yup';

const ZERO_OR_GREATER = 'Must be a number 0 or greater';

const equipmentSchema = yup.object({
  id: yup.string().required('Equipment selection is required'),
  softwareName: yup.string().when(['isVarSheet', 'name'], {
    is: (isVarSheet: boolean | undefined, name: string | undefined) => {
      return !!isVarSheet || isOtherNotListedEquipment(name);
    },
    then: yup.string().required('Software Name is required'),
    otherwise: yup.string().nullable(),
  }),
  name: yup.string(),
  price: yup
    .number()
    .min(0, ZERO_OR_GREATER)
    .when(['isGateway', 'isOther'], {
      is: (isGateway: boolean, isOther: boolean) => {
        return !Boolean(isGateway) && !Boolean(isOther);
      },
      then: yup
        .number()
        .min(0, ZERO_OR_GREATER)
        .required('Price is required')
        .typeError('Price must be a number 0 or greater'),
    })
    .typeError('Price must be a number 0 or greater'),
  connectionType: yup.string(),
  placementType: yup.string().required('Placement type is required'),
  paymentType: yup.string(),
  virtualSetupFee: yup
    .number()
    .min(0, ZERO_OR_GREATER)
    .typeError('Setup Fee must be a number 0 or greater'),
  virtualMonthlyFee: yup
    .number()
    .min(0, ZERO_OR_GREATER)
    .typeError('Monthly Fee must be a number 0 or greater'),
  virtualTransactionFee: yup
    .number()
    .min(0, ZERO_OR_GREATER)
    .typeError('Trans Fee must be a number 0 or greater'),
  fileType: yup.string().nullable(),
  tipMode: yup
    .string()
    .nullable()
    .when('fileType', (fileType: EquipmentFileType, schema: any) => {
      if (fileType === EquipmentFileType.restaurant) {
        return schema.required('Tip Mode is required');
      }
    }),
  cashDiscount: yup.boolean().transform(emptyStringToNull).nullable(),
  cashDiscountPercent: yup.number().when(['cashDiscount', 'isOther'], {
    is: (cashDiscount: boolean, isOther: boolean) => {
      return Boolean(cashDiscount) && !Boolean(isOther);
    },
    then: yup
      .number()
      .min(0, ZERO_OR_GREATER)
      .required('Cash Discount Percent Billed to Card Holder is required')
      .typeError('Cash Discount Percent Billed to Card Holder is required'),
    otherwise: yup.number().transform(emptyStringToNull).nullable(),
  }),
  autoBatch: yup.boolean().transform(emptyStringToNull).nullable(),
  autoBatchTime: yup.string().when('autoBatch', (autoBatch: boolean, schema: any) => {
    if (autoBatch) {
      return schema.required('Time is required').typeError('Time is required');
    }
    return schema.transform(emptyStringToNull).nullable();
  }),
  contactless: yup.boolean().transform(emptyStringToNull).nullable(),
  shippingSpeed: yup.string().nullable(),
  tid: yup.string(),
  sendTrackingNumber: yup.string(),
  receiveTrackingNumber: yup.string(),
});

export type EquipmentSchemaType = {
  id: string;
  name: string;
  platform: string;
  price: number;
  isGateway?: boolean;
  isVarSheet?: boolean;
  description: string;
  duration: string;
  imageUrl: string;
  connectionType: string;
  placementType: string;
  paymentType: string;
  virtualSetupFee: number;
  virtualMonthlyFee: number;
  virtualTransactionFee: number;
  fileType?: string;
  tipMode?: string;
  cashDiscount?: boolean;
  cashDiscountPercent?: number | null;
  autoBatch?: boolean;
  autoBatchTime?: string;
  contactless?: boolean;
  shippingSpeed: string;
  tid?: string;
  sendTrackingNumber?: string;
  receiveTrackingNumber?: string;
  accessories?: string[];
  softwareName?: string;
  serialNumber?: string;
};

export const pricingEquipmentSchema = (applicationType?: ApplicationType) =>
  yup.object().shape({
    equipmentAdditional: yup.array().of(equipmentSchema).ensure(),
    equipment: equipmentSchema,
    percentReserve: yup
      .number()
      .min(0, ZERO_OR_GREATER)
      .max(100, 'Number should be within 0-100 range')
      .transform(emptyStringToNull)
      .nullable(),
    rateSet: yup.object({
      qualifiedDiscountRateVisaMastercardDiscover: yup
        .number()
        .transform(emptyStringToNull)
        .nullable(),
      qualifiedTransactionFeeVisaMastercardDiscover: yup
        .number()
        .transform(emptyStringToNull)
        .nullable(),
      midQualifiedDiscountRateVisaMastercardDiscover: yup
        .number()
        .transform(emptyStringToNull)
        .nullable(),
      midQualifiedTransactionFeeVisaMastercardDiscover: yup
        .number()
        .transform(emptyStringToNull)
        .nullable(),
      nonQualifiedDiscountRateVisaMastercardDiscover: yup
        .number()
        .transform(emptyStringToNull)
        .nullable(),
      nonQualifiedTransactionFeeVisaMastercardDiscover: yup
        .number()
        .transform(emptyStringToNull)
        .nullable(),
      qualifiedDiscountRateAmex: yup.number().transform(emptyStringToNull).nullable(),
      qualifiedTransactionFeeAmex: yup.number().transform(emptyStringToNull).nullable(),
      midQualifiedDiscountRateAmex: yup.number().transform(emptyStringToNull).nullable(),
      midQualifiedTransactionFeeAmex: yup.number().transform(emptyStringToNull).nullable(),
      nonQualifiedDiscountRateAmex: yup.number().transform(emptyStringToNull).nullable(),
      nonQualifiedTransactionFeeAmex: yup.number().transform(emptyStringToNull).nullable(),
      qualifiedDebitPINRate: yup.number().transform(emptyStringToNull).nullable(),
      qualifiedDebitPINFee: yup.number().transform(emptyStringToNull).nullable(),
      ebtDiscountRate: yup.number().transform(emptyStringToNull).nullable(),
      ebtTransactionFee: yup.number().transform(emptyStringToNull).nullable(),
      qualifiedDebitNonPINRate: yup.number().transform(emptyStringToNull).nullable(),
      qualifiedDebitNonPINFee: yup.number().transform(emptyStringToNull).nullable(),
      otherVolumeRate: yup.number().transform(emptyStringToNull).nullable(),
      otherItemFee: yup.number().transform(emptyStringToNull).nullable(),
      accountOnFileFee: yup.number().transform(emptyStringToNull).nullable(),
      chargebackFee: yup.number().transform(emptyStringToNull).nullable(),
      retrievalFee: yup.number().transform(emptyStringToNull).nullable(),
      batchFee: yup
        .number()
        .min(0, ZERO_OR_GREATER)
        .typeError('Batch Fee must be a number 0 or greater'),
      monthlyMinimumFee: yup
        .number()
        .min(0, ZERO_OR_GREATER)
        .typeError('Monthly Minimum Fee must be a number 0 or greater'),
      debitAccessFee: yup
        .number()
        .min(0, ZERO_OR_GREATER)
        .typeError('Debit Access Fee must be a number 0 or greater'),
      additionalServicesFee: yup
        .number()
        .min(0, ZERO_OR_GREATER)
        .typeError('Additional Service Fees must be a number 0 or greater'),
      earlyDeconversionFee: yup
        .number()
        .min(0, ZERO_OR_GREATER)
        .typeError('Early Deconversion Fee must be a number 0 or greater'),
      pciFee: yup.string(),
      pciFeeValue: yup
        .number()
        .transform(emptyStringToNull)
        .nullable()
        .when('pciFee', (pciFee: PCIFee, schema: any) => {
          if (pciFee && pciFee !== PCIFee.waived) {
            const maxValue = PCIFeeDefaultValues[pciFee as PCIFee];
            return schema
              .min(0, 'PCI Fee must be a number 0 or greater')
              .max(maxValue, `PCI Fee must be equal or less than $${maxValue}`)
              .required('PCI Fee Value is required')
              .typeError('PCI Fee Value is required');
          }
        }),
      annualFee: yup
        .number()
        .min(0, ZERO_OR_GREATER)
        .typeError('Annual Fee must be a number 0 or greater'),
      regulatoryFee: yup
        .number()
        .min(0, ZERO_OR_GREATER)
        .typeError('Regulatory Fee must be a number 0 or greater'),
      avsFee: yup
        .number()
        .min(0, ZERO_OR_GREATER)
        .typeError('AVS Fee must be a number 0 or greater'),
      mailStatement: yup
        .string()
        .transform(emptyStringToNull)
        .required('Mail Statement is required')
        .typeError('Mail Statement is required'),
      statementType: yup
        .string()
        .transform(emptyStringToNull)
        .nullable()
        .when('applicationType', (applicationType: ApplicationType, schema: any) => {
          if (applicationType === ApplicationType.interchange) {
            return schema.required('Statement Type is required');
          }
        }),
    }),
    shipping: yup.object({
      other: yup.boolean().default(false).transform(emptyStringToNull).nullable(),
      select: yup
        .string()
        .transform(emptyStringToNull)
        .nullable()
        .when('$equipment', {
          is: (equipment?: {isGateway?: boolean}) => Boolean(equipment?.isGateway),
          then: yup.string().nullable(false).required('Select is required'),
        }),
      address1: yup
        .string()
        .transform(emptyStringToNull)
        .nullable()
        .when('other', {
          is: true,
          then: yup.string().nullable(false).required('Address is required'),
        }),
      city: yup
        .string()
        .transform(emptyStringToNull)
        .nullable()
        .when('other', {
          is: true,
          then: yup.string().nullable(false).required('City is required'),
        }),
      state: yup
        .string()
        .transform(emptyStringToNull)
        .nullable()
        .when('other', {
          is: true,
          then: yup.string().nullable(false).required('State is required'),
        }),
      zip: yup
        .string()
        .transform(emptyStringToNull)
        .nullable()
        .when('other', {
          is: true,
          then: yup
            .string()
            .nullable(false)
            .required('Zip Code is required')
            .min(5, 'Must be a valid zip code'),
        }),
    }),
    merchantAdvantageProgram: yup
      .object({
        amount: yup.number().required('Amount is required').typeError('Amount is required'),
        billingMethod: yup
          .mixed()
          .oneOf(Object.values(BillingMethod), 'Please select value from list')
          .required('Billing Method is required'),
      })
      .default(undefined),
  });

export type PricingEquipmentFormInput = {
  equipmentAdditional: EquipmentSchemaType[];
  equipment: EquipmentSchemaType;
  rateSet: RateSet;
  percentReserve?: number | null;
  shipping: {
    attention?: string;
    other?: boolean;
    select?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  merchantAdvantageProgram?: {
    amount: number;
    billingMethod: BillingMethod;
  };
};
