import {ApplicationView} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {ButtonExportCsv, ExportProps, getApplicationDate} from '@ozark/common/components';

const applicationsExportConfig: Column<ApplicationView>[] = [
  {
    id: 'applicationDateLabel',
    numeric: false,
    sortable: false,
    label: 'Date Label',
    export: applicationView => getApplicationDate(applicationView).label,
  },
  {
    id: 'applicationDateValue',
    numeric: false,
    sortable: false,
    label: 'Application Date',
    export: applicationView => getApplicationDate(applicationView).dateString,
  },
  {
    id: 'agentGroupAndName',
    numeric: false,
    sortable: false,
    label: 'Agent Name',
    export: ({agent}) => (agent ? `${agent.firstName} ${agent.lastName}` : ''),
  },
  {
    id: 'distinguishableId',
    numeric: false,
    sortable: false,
    label: 'Application ID',
    export: true,
  },
  {
    id: 'disposition',
    numeric: false,
    sortable: false,
    label: 'Application Disposition',
    export: true,
  },
  {
    id: 'legalBusinessName',
    numeric: false,
    sortable: false,
    label: 'Legal Name',
    export: ({legalBusinessName}) => legalBusinessName ?? '',
  },
  {
    id: 'doingBusinessAs',
    numeric: false,
    sortable: false,
    label: 'DBA Name',
    export: ({doingBusinessAs}) => doingBusinessAs ?? '',
  },
  {
    id: 'signerName',
    numeric: false,
    sortable: false,
    label: 'Signer Name',
    export: ({firstName, lastName}) => `${firstName ?? ''} ${lastName ?? ''}`,
  },
  {
    id: 'businessPhone',
    numeric: false,
    sortable: false,
    label: 'Business Phone Number',
    export: ({businessPhone}) => businessPhone ?? '',
  },
  {
    id: 'customerServiceEmail',
    numeric: false,
    sortable: false,
    label: 'Customer Service Email',
    export: ({customerServiceEmail}) => customerServiceEmail ?? '',
  },
  {
    id: 'mid',
    numeric: false,
    sortable: false,
    label: 'MID',
    export: ({mid}) => mid ?? '',
  },
];

const boardedApplicationsExportConfig: Column<ApplicationView>[] = [
  ...applicationsExportConfig,
  {
    id: 'platform',
    numeric: false,
    sortable: false,
    label: 'Platform',
    export: ({platform}) => platform ?? '',
  },
  {
    id: 'legalAddress1',
    numeric: false,
    sortable: false,
    label: 'Legal Address 1',
    export: ({businessAddress1}) => businessAddress1 ?? '',
  },
  {
    id: 'legalAddress2',
    numeric: false,
    sortable: false,
    label: 'Legal Address 2',
    export: ({businessAddress2}) => businessAddress2 ?? '',
  },
  {
    id: 'legalCity',
    numeric: false,
    sortable: false,
    label: 'Legal City',
    export: ({businessCity}) => businessCity ?? '',
  },
  {
    id: 'legalState',
    numeric: false,
    sortable: false,
    label: 'Legal State',
    export: ({businessState}) => businessState ?? '',
  },
  {
    id: 'legalZip',
    numeric: false,
    sortable: false,
    label: 'Legal ZIP',
    export: ({businessZip}) => businessZip ?? '',
  },

  {
    id: 'mailingAddress1',
    numeric: false,
    sortable: false,
    label: 'Mailing Address 1',
    export: ({mailingAddress1}) => mailingAddress1 ?? '',
  },
  {
    id: 'mailingAddress2',
    numeric: false,
    sortable: false,
    label: 'Mailing Address 2',
    export: ({mailingAddress2}) => mailingAddress2 ?? '',
  },
  {
    id: 'mailingCity',
    numeric: false,
    sortable: false,
    label: 'Mailing City',
    export: ({mailingCity}) => mailingCity ?? '',
  },
  {
    id: 'mailingState',
    numeric: false,
    sortable: false,
    label: 'Mailing State',
    export: ({mailingState}) => mailingState ?? '',
  },
  {
    id: 'mailingZip',
    numeric: false,
    sortable: false,
    label: 'Mailing ZIP',
    export: ({mailingZip}) => mailingZip ?? '',
  },
];

type Props = ExportProps & {
  isBoardedAppsExport?: boolean;
};

const ApplicationsExportButton = ({filename, getRows, rows, sx, isBoardedAppsExport}: Props) => {
  return (
    <ButtonExportCsv
      columnsConfig={
        isBoardedAppsExport ? boardedApplicationsExportConfig : applicationsExportConfig
      }
      filename={filename}
      getRows={getRows}
      rows={rows}
      sx={sx}
    />
  );
};

export {ApplicationsExportButton};
