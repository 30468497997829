import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {useEffect} from 'react';

const ANIM_DURATION = 700;
const ANIM_DELAY = 300;

type Props = {
  children: React.ReactNode;
  itemId: string;
  removedItemId?: string;
  onItemRemoved: (itemRemovedId: string) => void;
};

export const ItemRemoveAnimator = ({children, itemId, removedItemId, onItemRemoved}: Props) => {
  const classes = useStyles();

  useEffect(() => {
    if (removedItemId) {
      const to = setTimeout(() => {
        onItemRemoved(removedItemId);
      }, ANIM_DURATION);

      return () => clearTimeout(to);
    }
  }, [removedItemId, onItemRemoved]);

  return (
    <div
      className={
        removedItemId === itemId ? `${classes.item} ${classes.itemRemoved}` : `${classes.item}`
      }
    >
      {children}
    </div>
  );
};

const useStyles = makeStyles(_ =>
  createStyles({
    item: {
      transition: `opacity ${ANIM_DURATION}ms ease-in ${ANIM_DELAY}ms, transform ${ANIM_DURATION}ms cubic-bezier(0.7, 0, 0.2, 1) ${ANIM_DELAY}ms`,
    },
    itemRemoved: {
      transform: 'translateX(100%) scale(1, 0.5625)',
      opacity: 0,
    },
  })
);
