import {DocumentBase, DocumentCreatedBy} from '.';
import {FieldValue, UniversalTimestamp} from './compat';

export enum TicketHistoryRecordType {
  Assignee = 'Assignee',
  Association = 'Association',
  Category = 'Category',
  Create = 'Create',
  Description = 'Description',
  Status = 'Status',
  Subject = 'Subject',
  Label = 'Label',
  ViewableBy = 'ViewableBy',
}

export type TicketHistoryRecord = DocumentBase &
  DocumentCreatedBy & {
    userName: string;
    title: string;
    titlePortal?: string;
    createdAt: UniversalTimestamp | FieldValue;
    recordType: TicketHistoryRecordType;
  };
