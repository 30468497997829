import {useCallback} from 'react';
import {TicketView, ViewableByType} from '../../../..';
import {useUserInfo} from '../../../../hooks';

export const useTicketsViewableBy = () => {
  const {isErpUser} = useUserInfo();
  const filterOutInternalTickets = useCallback(
    (tickets: TicketView[]) => {
      if (isErpUser) {
        return tickets;
      }
      return tickets.filter(ticket => ticket.viewableBy !== ViewableByType.internalOnly);
    },
    [isErpUser]
  );

  return {
    filterOutInternalTickets,
  };
};
