import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {Box, Chip, Divider, Tooltip, Typography} from '@mui/material';
import {format} from 'date-fns';
import {useMemo} from 'react';
import {generatePath} from 'react-router-dom';
import {TICKET_EDIT} from '../../constants/routes';
import {TicketSearchItemModel} from '../../index';
import {BoxFlexed} from '../common';
import {BoxEllipsis} from '../common/BoxEllipsis';
import {SearchOptionLinkWrapper} from '../GlobalSearch/SearchOptionLinkWrapper';

type Props = {
  ticketSearchItem: TicketSearchItemModel;
  wideSubject?: boolean;
};

export const TicketSearchItem = ({ticketSearchItem, wideSubject}: Props) => {
  if (!ticketSearchItem) {
    return <Typography>no data item</Typography>;
  }

  const openedAtParts = useMemo(() => {
    if (!ticketSearchItem.openedAt) {
      return null;
    }

    return format(ticketSearchItem.openedAt as Date, 'MM/dd/yy - hh:mm a').split(' - ');
  }, [ticketSearchItem.openedAt]);

  return (
    <SearchOptionLinkWrapper
      linkRoute={ticketSearchItem.id && generatePath(TICKET_EDIT, {id: ticketSearchItem.id})}
    >
      <Typography variant="body2" width="100%" fontSize={12} component="div">
        <BoxFlexed justifyContent="space-between" width="100%" alignItems="center">
          <Box width="10%" pr={1}>
            {openedAtParts && (
              <>
                {openedAtParts[0]}
                <br />
                {openedAtParts[1]}
              </>
            )}
          </Box>

          <Divider orientation="vertical" flexItem variant="middle" />

          <Box width="10%" px={1} display="flex" alignItems="center">
            {ticketSearchItem.distinguishableId}
          </Box>

          <Divider orientation="vertical" flexItem variant="middle" />

          <Box
            width={wideSubject ? '46.5%' : '26%'}
            px={1}
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            <Tooltip title={ticketSearchItem.subject ?? ''}>
              <BoxEllipsis alignItems="flex-start" maxWidth={200} mb={0.5}>
                {ticketSearchItem.subject ?? ''}
              </BoxEllipsis>
            </Tooltip>

            {ticketSearchItem.labels && (
              <>
                <Box px={1} display="flex" alignItems="center" flexWrap="wrap">
                  {ticketSearchItem.labels?.split(',').map(l => (
                    <Chip label={l} sx={{mr: 1, mb: 1, maxWidth: 200}} />
                  ))}
                </Box>
                <Divider orientation="vertical" flexItem variant="middle" />
              </>
            )}
          </Box>

          <Divider orientation="vertical" flexItem variant="middle" />

          <Box width="18%" px={1} display="flex" alignItems="center">
            <Tooltip title={`Assignee: ${ticketSearchItem.assignee}`}>
              <Chip icon={<AccountCircleIcon />} label={ticketSearchItem.assignee} size="small" />
            </Tooltip>
          </Box>

          <Divider orientation="vertical" flexItem variant="middle" />

          <Box width="18%" pl={1} display="flex" alignItems="center">
            <Tooltip title={`Creator: ${ticketSearchItem.creator}`}>
              <Chip
                icon={<AccountCircleOutlinedIcon />}
                label={ticketSearchItem.creator}
                size="small"
              />
            </Tooltip>
          </Box>
        </BoxFlexed>
      </Typography>
    </SearchOptionLinkWrapper>
  );
};
