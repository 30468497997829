import {DocumentBase} from '.';
import {UniversalTimestamp} from './compat';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export enum ResourceViewableBy {
  hidden = 'hidden',
  erp = 'erp',
  portal = 'portal',
  both = 'both',
}

export const ResourceViewableByNames: {[_ in ResourceViewableBy]: string} = {
  [ResourceViewableBy.hidden]: 'Hidden',
  [ResourceViewableBy.erp]: 'ERP - Users',
  [ResourceViewableBy.portal]: 'Portal - Groups',
  [ResourceViewableBy.both]: 'Both (ERP & Portal)',
};

export const ResourceViwableByPortalNames: {[_: string]: string} = {
  [ResourceViewableBy.hidden]: 'Hidden',
  [ResourceViewableBy.portal]: 'Group',
};

export enum ResourceType {
  video = 'video',
  document = 'document',
}

export type VideoResource = {
  vimeoLink: string;
};

export type Resource = DocumentBase & {
  type: ResourceType;
  title: string;
  description: string;
  viewableBy: ResourceViewableBy;
  shared: boolean; // true if resource created by ERP Admin
  ownerGroupId?: string; // groupId of the Group Admin created this resource
  groups: string[]; // list of Portal groups who have permissions to see
  createdAt: UniversalTimestamp;
  deleted: boolean;
  category: string;
  cloudPath?: string | null;
  cloudPreviewImagePath?: string | null;
  downloadUrl?: string | null;
  downloadPreviewUrl?: string | null;
  size?: number | null;
  contentType?: string | null;
  order: number;
} & VideoResource;

export type ResourceInput = Resource;

export type ResourceView = ViewBase<Resource>;

export const selectResourceView: ViewSelector<ResourceView, Resource> = snapshot => {
  const resource: ResourceView = {
    ...defaultViewSelector(snapshot),
  };
  return resource;
};
