import {ScorecardReportId, ScorecardServiceName} from '@ozark/common';
import {FC} from 'react';
import {AccordionProps} from '.';
import {ExperianScorecardAccordion} from './accordions/ExperianScorecardAccordion';
import {FinicityScorecardAccordion} from './accordions/FinicityScorecardAccordion';
import {LexisNexisBusinessReportAccordion} from './accordions/LexisNexisBusinessReportAccordion';
import {LexisNexisInstantIdAccordion} from './accordions/LexisNexisInstantIdAccordion';
import {LexisNexisPeopleSearchAccordion} from './accordions/LexisNexisPeopleSearchAccordion';
import {MatchScorecardAccordion} from './accordions/MatchScorecardAccordion';
import {PlaidScorecardAccordion} from './accordions/PlaidScorecardAccordion';

export type ScorecardReportStep = 'step1' | 'step2';

export type ScorecardReport = {
  id: ScorecardReportId;
  serviceName: ScorecardServiceName;
  title: string;
  accordion: FC<React.PropsWithChildren<AccordionProps>>;
};

export const scorecardReports: ScorecardReport[] = [
  {
    id: 'creditReport',
    serviceName: ScorecardServiceName.experian,
    title: 'Credit Report',
    accordion: ExperianScorecardAccordion,
  },
  {
    id: 'terminations',
    serviceName: ScorecardServiceName.match,
    title: 'Termination Inquery',
    accordion: MatchScorecardAccordion,
  },
  {
    id: 'personReport',
    serviceName: ScorecardServiceName.lexisNexis,
    title: 'Person Report',
    accordion: LexisNexisPeopleSearchAccordion,
  },
  {
    id: 'businessReport',
    serviceName: ScorecardServiceName.lexisNexis,
    title: 'Business Report',
    accordion: LexisNexisBusinessReportAccordion,
  },
  {
    id: 'instantIdQandA',
    serviceName: ScorecardServiceName.lexisNexis,
    title: 'Instant ID Q&A',
    accordion: LexisNexisInstantIdAccordion,
  },
  {
    id: 'linkedAccount',
    serviceName: ScorecardServiceName.plaid,
    title: 'Plaid Bank Account',
    accordion: PlaidScorecardAccordion,
  },
  {
    id: 'connectFinicityInstitution',
    serviceName: ScorecardServiceName.finicity,
    title: 'Finicity Bank Account',
    accordion: FinicityScorecardAccordion,
  },
];
