import {
  AssigneeType,
  Collections,
  InfiniteSnapshotOptions,
  selectTicketView,
  TicketStatus,
  TicketView,
  useInfiniteSnapshots,
} from '@ozark/common';
import {subDays} from 'date-fns';
import firebase from 'firebase/compat/app';
import {useEffect, useMemo, useState} from 'react';
import {useUserInfo} from '../../../hooks';
import {FilterKey} from '../types';
import {useTicketsFiltersContainer} from './useTicketsFiltersContainer';
import {TicketsSortBy, useTicketsQuery} from './useTicketsQuery';

export function useTicketsAllErp(
  statusSet: TicketStatus[],
  filterKey?: FilterKey,
  isCounter?: boolean
) {
  const {sortBy, sortDir} = useTicketsQuery();
  const {uid} = useUserInfo();
  const {
    assigneeTypeFilter,
    departmentFilter,
    groupIdFilter,
    ticketTypeFilter,
    ticketCategoryFilter,
  } = useTicketsFiltersContainer();

  const getTicketsFilter = useMemo(
    () =>
      (
        query: firebase.firestore.Query<firebase.firestore.DocumentData>
      ): firebase.firestore.Query<firebase.firestore.DocumentData> => {
        query = query.where('status', 'in', statusSet);

        // Draft are always local
        if (statusSet.includes(TicketStatus.Draft)) {
          query = query.where('author.id', '==', uid);
        }

        // Filters
        if (ticketTypeFilter) {
          query = query.where('category.type.name', '==', ticketTypeFilter);
        }
        if (ticketCategoryFilter) {
          query = query.where('category.category.name', '==', ticketCategoryFilter);
        }
        if (assigneeTypeFilter) {
          query = query.where('assignee.type', '==', assigneeTypeFilter);
        }
        if (assigneeTypeFilter === AssigneeType.erpDepartment && departmentFilter) {
          query = query.where('assignee.erpDepartment', '==', departmentFilter);
        }
        if (assigneeTypeFilter === AssigneeType.erpUser && departmentFilter) {
          query = query.where('assignee.erpDepartment', '==', departmentFilter);
        }
        if (assigneeTypeFilter === AssigneeType.agent && groupIdFilter) {
          query = query.where('assignee.group.id', '==', groupIdFilter);
        }
        if (filterKey) {
          switch (filterKey) {
            case FilterKey.moreThen24h:
              query = query.where('openedAt', '<=', subDays(new Date(), 1));
              break;
          }
        }

        return query;
      },
    [
      uid,
      statusSet,
      assigneeTypeFilter,
      departmentFilter,
      groupIdFilter,
      ticketTypeFilter,
      ticketCategoryFilter,
      filterKey,
    ]
  );

  const limit = 100;

  // Initially for drafts we used "createdAt" as order by. But in that case we have an error
  // FirebaseError: Invalid query. You have a where filter with an inequality (<, <=, !=, not-in, >, or >=) on field 'openedAt' and so you must also use 'openedAt' as your first argument to Query.orderBy(), but your first orderBy() is on field 'createdAt' instead.
  // For drafts it is fine to use openedAt in order by because long time ago we started to populate 'openedAt' with the same value as 'createdAt'
  const orderBy: TicketsSortBy = 'openedAt';

  const [options, setOptions] = useState<Partial<InfiniteSnapshotOptions>>({
    orderBy: orderBy,
    order: sortDir,
    limit: limit,
    filter: getTicketsFilter,
  });

  const {documents: infiniteTickets, next: infiniteTicketsNext} = useInfiniteSnapshots<TicketView>(
    Collections.tickets,
    selectTicketView,
    options
  );

  useEffect(() => {
    setOptions({
      orderBy: orderBy,
      order: sortDir,
      limit: limit,
      filter: getTicketsFilter,
    });
  }, [sortBy, sortDir, getTicketsFilter]);

  const hasData = Boolean(infiniteTickets?.size) || infiniteTickets.hasNextPage;
  const loading = Boolean(infiniteTickets?.promised) || (infiniteTickets?.size === 0 && hasData);

  return {
    tickets: infiniteTickets,
    counter: infiniteTickets.size ?? 0,
    infiniteTicketsNext,
    loading,
    hasData,
  };
}
