import {WorkingHoursTimeFormat} from '@ozark/common';
import {Select} from '@ozark/common/components';
import {timeList} from '@ozark/common/helpers';

interface Props {
  control: any;
  label: string;
  fieldName: string;
  setValue: any;
  errors: any;
  isReadOnly?: boolean;
}

export const ChooseTimeControl = ({
  control,
  label,
  fieldName,
  setValue,
  errors,
  isReadOnly = false,
}: Props) => {
  const onClear = () => {
    setValue(fieldName, null, {shouldDirty: true});
  };

  return !isReadOnly ? (
    <Select
      name={fieldName}
      label={label}
      errors={errors}
      control={control}
      options={timeList(WorkingHoursTimeFormat, [0, 30])}
      compareFn={() => 0}
      onClear={onClear}
    />
  ) : (
    <Select
      name={fieldName}
      disabled={true}
      label={label}
      errors={errors}
      control={control}
      options={timeList(WorkingHoursTimeFormat, [0, 30])}
      compareFn={() => 0}
    />
  );
};
