import {DocumentBase} from '.';
import {ShippingSpeed} from '../constants';
import {Platforms} from '../constants/Platforms';
import {ProcessingTypes} from '../constants/ProcessingTypes';
import {UniversalTimestamp} from './compat';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export enum EquipmentAvailability {
  inStock = 'In Stock',
  outOfStock = 'Out of Stock',
  notAvailable = 'Not Available',
}

export enum EquipmentConnectionType {
  wifi = 'WiFi',
  dial = 'Dial',
  cellular = 'Cellular',
  ethernet = 'IP (Ethernet)',
  bluetooth = 'Smart Phone Bluetooth Connection with Data Service',
}

export enum EquipmentFileType {
  retail = 'Retail',
  restaurant = 'Restaurant',
}

export enum EquipmentViewableBy {
  none = 'None',
  all = 'All',
  merchant = 'Merchant',
  agent = 'Agent',
  erpUser = 'ERP User',
}

export enum EquipmentDurationType {
  monthly = 'Monthly',
  device = 'Device',
}

export enum EquipmentTipMode {
  tipAdjust = 'Tip Adjust',
  tipInTransaction = 'Tip in Transaction',
}

export enum EquipmentYesOrNo {
  yes = 'Yes',
  no = 'No',
}

export const EquipmentOtherName = 'Other';

export const defaultEquipmentOptions: EquipmentOptions = {
  connectionType: Object.values(EquipmentConnectionType).map(e => e as EquipmentConnectionType),
  fileType: Object.values(EquipmentFileType).map(e => e as EquipmentFileType),
  tipMode: Object.values(EquipmentTipMode).map(e => e as EquipmentTipMode),
  cashDiscount: Object.values(EquipmentYesOrNo).map(e => e as EquipmentYesOrNo),
  autoBatch: Object.values(EquipmentYesOrNo).map(e => e as EquipmentYesOrNo),
  contactless: Object.values(EquipmentYesOrNo).map(e => e as EquipmentYesOrNo),
};

export type EquipmentOptions = Partial<{
  connectionType: EquipmentConnectionType[];
  fileType: EquipmentFileType[];
  tipMode: EquipmentTipMode[];
  cashDiscount: EquipmentYesOrNo[];
  autoBatch: EquipmentYesOrNo[];
  contactless: EquipmentYesOrNo[];
  accessories: string[];
}>;

export type EquipmentDefaults = Partial<{
  connectionType: EquipmentConnectionType;
  fileType: EquipmentFileType;
  tipMode: EquipmentTipMode;
  cashDiscount: boolean;
  cashDiscountPercent: number;
  autoBatch: boolean;
  autoBatchTime: string;
  contactless: boolean;
  accessories: string[];
  shippingSpeed: ShippingSpeed;
}>;

export type EquipmentAdditionalField = {
  required?: boolean;
  defaultValue?: string;
  label: string;
  name: string;
  type: string;
};

export type Equipment = DocumentBase & {
  name: string;
  isGateway?: boolean;
  isVarSheet?: boolean;
  softwareName?: string;
  price: number;
  duration: EquipmentDurationType;
  programs: string[] | null;
  imageUrl: string;
  description: string;
  features: string[];
  processingTypes: ProcessingTypes[];
  platform: Platforms;
  availability?: EquipmentAvailability;
  options?: EquipmentOptions;
  defaults?: EquipmentDefaults;
  viewableBy: EquipmentViewableBy;
  placementType?: string;
  virtualSetupFee?: number;
  virtualMonthlyFee?: number;
  virtualTransactionFee?: number;
  createdAt: UniversalTimestamp;
  additionalFields?: EquipmentAdditionalField[];
};

export type EquipmentView = ViewBase<Equipment> & {isOther: boolean};

export const selectEquipmentView: ViewSelector<EquipmentView, Equipment> = snapshot => {
  const equipment = {
    ...defaultViewSelector(snapshot),
  };

  const isOther = equipment.name === EquipmentOtherName;
  equipment.options = isOther ? defaultEquipmentOptions : equipment.options;

  return {...equipment, isOther};
};

export const isVarSheetOtherEquipment = (equipmentName: string | undefined) =>
  equipmentName === 'VAR Sheet - Other';
export const isOtherNotListedEquipment = (equipmentName: string | undefined) =>
  equipmentName === 'Other - Not Listed';
