import {SocialDistance} from '@mui/icons-material';
import RoomIcon from '@mui/icons-material/Room';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ApplicationView} from '@ozark/common';
import {useCallback, useState} from 'react';
import Map from '../../Map';
import {CompleteApplicationInfo} from './CompleteApplicationInfo';
import {StartApplicationInfo} from './StartApplicationInfo';
import {TransferApplicationInfo} from './TransferApplicationInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: '100%',
    },
    map: {
      width: '100%',
      height: 250,
    },
    list: {
      padding: theme.spacing(0),
      '& > *': {
        margin: 0,
      },
    },
    listSubheader: {
      color: '#1c252c',
      backgroundColor: '#f5fafc',
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  })
);

type LocationProps = {
  application: ApplicationView;
};

export const Location = ({application}: LocationProps) => {
  const classes = useStyles();
  const [distance, setDistance] = useState<{miles: number}>();

  const onDistance = useCallback((miles: number) => {
    setDistance({miles});
  }, []);

  const startLocation =
    application.startIpInfo?.latitude && application.startIpInfo?.longitude
      ? {lat: application.startIpInfo?.latitude, lng: application.startIpInfo?.longitude}
      : undefined;

  const businessLocation =
    application.businessLocation?.lat && application.businessLocation?.lng
      ? {lat: application.businessLocation?.lat, lng: application.businessLocation?.lng}
      : undefined;

  return (
    <Paper className={classes.paper} square>
      {startLocation && (
        <div className={classes.map}>
          <Map
            startIpInfo={startLocation}
            businessLocation={businessLocation}
            onDistance={onDistance}
          />
        </div>
      )}

      <List className={classes.list}>
        {distance && (
          <>
            <ListSubheader className={classes.listSubheader}>
              Starting &lt;-&gt; Business Address Distance
            </ListSubheader>
            <ListItem>
              <ListItemIcon>
                <SocialDistance />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    component="p"
                    noWrap
                    style={{color: distance.miles > 100 ? 'red' : 'black'}}
                  >
                    Miles: {Math.round(distance.miles)}
                  </Typography>
                }
              />
            </ListItem>
          </>
        )}
        <ListSubheader className={classes.listSubheader}>Business Address</ListSubheader>
        <ListItem>
          <ListItemIcon>
            <RoomIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2" component="p" noWrap>
                {application.businessAddress1} {application.businessAddress2}
              </Typography>
            }
            secondary={
              <Typography variant="body2" component="p" noWrap>
                {application.businessCity}, {application.businessState}
                {application.businessZip}
              </Typography>
            }
          />
        </ListItem>
        <ListSubheader className={classes.listSubheader}>Starting Location</ListSubheader>
        <ListItem>
          <ListItemIcon>
            <RoomIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2" component="p" noWrap>
                {application.startIpInfo?.city}
              </Typography>
            }
            secondary={
              <Typography variant="body2" component="p" noWrap>
                {application.startIpInfo?.ip}
              </Typography>
            }
          />
        </ListItem>
        <StartApplicationInfo application={application} />
        <TransferApplicationInfo application={application} />
        <CompleteApplicationInfo application={application} />

        {application.endIpInfo && (
          <>
            <ListSubheader className={classes.listSubheader}>Signature Location</ListSubheader>
            <ListItem>
              <ListItemIcon>
                <RoomIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" component="p" noWrap>
                    {application.startIpInfo?.city}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" component="p" noWrap>
                    {application.startIpInfo?.ip}
                  </Typography>
                }
              />
            </ListItem>
          </>
        )}
      </List>
    </Paper>
  );
};
