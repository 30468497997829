import {Autocomplete, Box, TextField} from '@mui/material';
import {omit, sortBy} from 'lodash';
import {useEffect, useState} from 'react';
import {
  Agent,
  Collections,
  Firebase,
  selectAgentView,
  TicketAssignee,
  UniversalSnapshot,
} from '../../../..';
import {FIELD_NAME_AGENT} from '../../constants/constants';
import {InputAssigneeError} from '../../types';
import {AssigneeEditorValueGroup} from './AssigneeEditorValueGroup';

type AgentOption = TicketAssignee['agent'];

type Props = {
  draft: TicketAssignee;
  setDraft: (draft: TicketAssignee) => void;
  errors: Record<InputAssigneeError, string>;
  setErrors: (errors: Record<InputAssigneeError, string>) => void;
};

export const AssigneeEditorValueGroupAgent = ({draft, setDraft, errors, setErrors}: Props) => {
  const [agents, setAgents] = useState<AgentOption[]>([]);

  useEffect(() => {
    let isMounted = true;
    if (draft.group?.id) {
      Firebase.firestore
        .collection(Collections.agents)
        .where('group.id', '==', draft.group.id)
        .where('isActive', '==', true)
        .get()
        .then(snap => {
          if (snap.size) {
            const agentViews = sortBy(
              snap.docs.map(doc => {
                const agentView = selectAgentView(doc as UniversalSnapshot<Agent>);
                return {
                  id: agentView.id,
                  name: `${agentView.firstName} ${agentView.lastName}`.trim(),
                };
              }),
              e => e.name
            );
            isMounted && setAgents(agentViews);
          } else {
            isMounted && setAgents([]);
          }
        })
        .catch(error => {
          console.error('Error writing document (InputAgent): ', error);
        });
    }
    return () => {
      isMounted = false;
    };
  }, [draft.group?.id]);

  return (
    <>
      <AssigneeEditorValueGroup
        draft={draft}
        setDraft={setDraft}
        errors={errors}
        setErrors={setErrors}
      />
      <Box mt={2}>
        <Autocomplete
          value={draft.agent}
          onChange={async (
            _: React.SyntheticEvent<Element, Event>,
            newValue: AgentOption | null
          ) => {
            setDraft({...draft, agent: newValue ?? undefined});
            if (newValue) {
              setErrors(omit(errors, FIELD_NAME_AGENT) as Record<InputAssigneeError, string>);
            }
          }}
          disabled={!draft.group?.id || !agents.length}
          options={agents}
          renderInput={params => (
            <TextField
              {...params}
              label="Assignee Agent"
              required
              variant="standard"
              error={Boolean(errors[FIELD_NAME_AGENT])}
              helperText={errors[FIELD_NAME_AGENT]}
            />
          )}
          getOptionLabel={(item: AgentOption) => item?.name ?? 'n/a'}
          renderOption={(props, option) => (
            <li {...props} key={option?.id}>
              {option?.name ?? 'n/a'}
            </li>
          )}
        />
      </Box>
    </>
  );
};
