import {Grid} from '@mui/material';
import {Square} from '@ozark/common/components';
import {currentFormatter, percentFormatter} from '../Reporting';

type Props = {
  setTotalCbFilter: () => void;
  setCbFilter: () => void;
  setRdrFilter: () => void;
  cbAmount: number;
  cbCount: number;
  rdrCbAmount: number;
  rdrCbCount: number;
  totalCbAmount: number;
  totalCbCount: number;
  totalCbAmountRatio: number;
  totalCbCountRatio: number;
};

export const ChargebacksTotals = ({
  setTotalCbFilter,
  setCbFilter,
  setRdrFilter,
  cbAmount,
  cbCount,
  rdrCbAmount,
  rdrCbCount,
  totalCbAmount,
  totalCbCount,
  totalCbAmountRatio,
  totalCbCountRatio,
}: Props) => {
  return (
    <>
      <Grid item xs={12} container spacing={1} direction="row">
        <Grid item xs={3}>
          <Square
            lines={{
              'Chargeback Amount': currentFormatter.format(cbAmount),
            }}
            onClick={setCbFilter}
            center
          />
        </Grid>
        <Grid item xs={3}>
          <Square
            lines={{
              'Chargeback Count': cbCount,
            }}
            onClick={setCbFilter}
            center
          />
        </Grid>
        <Grid item xs={3}>
          <Square
            lines={{
              'RDR Amount': currentFormatter.format(rdrCbAmount),
            }}
            onClick={setRdrFilter}
            center
          />
        </Grid>
        <Grid item xs={3}>
          <Square
            lines={{
              'RDR Count': rdrCbCount,
            }}
            onClick={setRdrFilter}
            center
          />
        </Grid>
      </Grid>

      <Grid item xs={12} container spacing={1} direction="row">
        <Grid item xs={3}>
          <Square
            lines={{
              'Total Chargeback Amount': currentFormatter.format(totalCbAmount),
            }}
            onClick={setTotalCbFilter}
            center
          />
        </Grid>
        <Grid item xs={3}>
          <Square
            lines={{
              'Total Chargeback Count': totalCbCount,
            }}
            onClick={setTotalCbFilter}
            center
          />
        </Grid>

        <Grid item xs={3}>
          <Square
            lines={{
              'Total Chargeback Amount Ratio': percentFormatter.format(totalCbAmountRatio),
            }}
            onClick={setTotalCbFilter}
            center
          />
        </Grid>
        <Grid item xs={3}>
          <Square
            lines={{
              'Total CB Count Ratio': percentFormatter.format(totalCbCountRatio),
            }}
            onClick={setTotalCbFilter}
            center
          />
        </Grid>
      </Grid>
    </>
  );
};
