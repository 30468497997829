import {
  Alert,
  Box,
  Button,
  Divider,
  MenuItem,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Agent, AgentStatus, useAgents, useGroups, UserRoles, ViewBase} from '@ozark/common';
import {AutoCompleteTextField, Loading, SearchInput, Title} from '@ozark/common/components';
import {filterListByInput} from '@ozark/common/helpers';
import {useEffect, useState} from 'react';
import debounceRender from 'react-debounce-render';
import {useStore} from '../../store/helpers';
import {AgentGrid} from './AgentGrid';
import {CreateDialog} from './CreateDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupFilter: {
      minWidth: 200,
    },
    statusFilter: {
      minWidth: 150,
    },
    divider: {
      margin: theme.spacing(1, 2),
    },
    selectInput: {
      backgroundColor: 'transparent !important',
    },
  })
);

const getFilteredAgents = (
  agents: ViewBase<Agent>[],
  groupId: string,
  agentStatus: AgentStatus,
  selectedAgent?: ViewBase<Agent>
) => {
  let _filteredAgents = [...agents] as ViewBase<Agent>[];

  if (groupId !== '0') {
    _filteredAgents = _filteredAgents.filter(
      (agent: Agent) => agent.group && agent.group.id === groupId
    );
  }

  switch (agentStatus) {
    case AgentStatus.active:
      _filteredAgents = _filteredAgents.filter(
        (agent: Agent) => agent.deleted !== true && agent.isActive
      );
      break;
    case AgentStatus.inactive:
      _filteredAgents = _filteredAgents.filter(
        (agent: Agent) => agent.deleted !== true && !agent.isActive && agent.isDisabled !== true
      );
      break;
    case AgentStatus.disabled:
      _filteredAgents = _filteredAgents.filter(
        (agent: Agent) => agent.deleted !== true && agent.isDisabled === true
      );
      break;
    default:
      throw RangeError(`invalid agentStatus ${agentStatus}`);
  }

  if (selectedAgent && _filteredAgents.length) {
    _filteredAgents = [selectedAgent];
  }

  return _filteredAgents;
};

const DebouncedAgentGrid = debounceRender(AgentGrid, 300);

const Agents = () => {
  const biggerThan1550 = useMediaQuery('(min-width:1550px)');
  const biggerThan1450 = useMediaQuery('(min-width:1450px)');
  const classes = useStyles();
  const {documents: groups} = useGroups();
  const {documents: agents} = useAgents();
  const [filterText, setFilterText] = useState('');

  const [groupId, setGroupId] = useState<string>('0');
  const [agentStatus, setAgentStatus] = useState(AgentStatus.active);

  const {authProfile} = useStore();
  const canEdit =
    authProfile.data?.role === UserRoles.admin || authProfile.data?.role === UserRoles.agentSupport;

  const [filteredAgents, setFilteredAgents] = useState<ViewBase<Agent>[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<ViewBase<Agent>>();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const handleFilterChange = (id: string) => {
    setGroupId(id);
  };

  const handleAgentStatusChange = (event: any) => {
    setAgentStatus(event.target.value);
  };

  const onTextChange = (value: string) => {
    setFilterText(value);
  };
  useEffect(() => {
    if (agents.data) {
      const filteredListBySearch = filterListByInput(agents.data, filterText);
      const filtered = getFilteredAgents(filteredListBySearch, groupId, agentStatus, selectedAgent);
      setFilteredAgents(filtered);
    }
  }, [agents, groupId, agentStatus, setFilteredAgents, selectedAgent, filterText]);

  if (agents.promised || groups.promised) {
    return <Loading />;
  }

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Title breadcrumbs={[<Typography>Agents</Typography>]} />
      {createDialogOpen && <CreateDialog onClose={() => setCreateDialogOpen(false)} />}
      {!canEdit && (
        <Alert severity="info" sx={{mb: 2}}>
          You currently have <b>read only</b> access to this page.
        </Alert>
      )}
      <Box display="flex" justifyContent="space-between" pb={1}>
        <Box>
          <Button disabled={!canEdit} onClick={() => setCreateDialogOpen(true)} variant="outlined">
            Create New Agent
          </Button>
        </Box>
        <Box display="flex">
          {biggerThan1450 && (
            <>
              <SearchInput
                filterText={filterText}
                disableExtendOnFocus={!biggerThan1550}
                widthOnFocus="20ch"
                onTextChange={onTextChange}
              />
              <Divider orientation="vertical" className={classes.divider} flexItem />
            </>
          )}
          <AutoCompleteTextField
            placeholder="Filter by Name"
            options={agents.data ?? ([] as ViewBase<Agent>[])}
            selected={selectedAgent}
            setSelected={setSelectedAgent}
            getOptionLabel={(agent: ViewBase<Agent>) => {
              return `${agent.firstName ?? ''} ${agent.lastName ?? ''}`.trim();
            }}
            onItemSelect={(agent: ViewBase<Agent> | null) => setSelectedAgent(agent ?? undefined)}
          />
          <Divider orientation="vertical" className={classes.divider} flexItem />
          <TextField
            value={agentStatus}
            onChange={handleAgentStatusChange}
            variant="standard"
            label="Filter by Status"
            InputProps={{
              classes: {
                input: classes.selectInput,
              },
              disableUnderline: true,
            }}
            select
            className={classes.statusFilter}
          >
            {Object.values(AgentStatus).sortAndMap(e => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
          </TextField>
          <Divider orientation="vertical" className={classes.divider} flexItem />
          <TextField
            defaultValue="0"
            className={classes.groupFilter}
            variant="standard"
            select
            label="Filter by Group"
            InputProps={{disableUnderline: true, classes: {input: classes.selectInput}}}
            onChange={event => handleFilterChange(event.target.value as string)}
          >
            <MenuItem value="0">All</MenuItem>
            {groups.data?.sortAndMap(
              group => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ),
              group => group.name
            )}
          </TextField>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          justifyContent: 'flex-start',
          display: biggerThan1450 ? 'none' : 'block',
          marginBottom: theme => theme.spacing(2),
        }}
      >
        <SearchInput filterText={filterText} inputSmallWidth="100%" onTextChange={onTextChange} />
      </Box>
      <Box position="relative" flex={1}>
        {filteredAgents && (
          <Box position="absolute" width="100%" height="100%" overflow="auto">
            <DebouncedAgentGrid agents={filteredAgents} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Agents;
