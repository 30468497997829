import AttachFileIcon from '@mui/icons-material/AttachFile';
import {Box, IconButton, Menu, MenuItem} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  AgentSubCollection,
  ApplicationDocument,
  ApplicationSubCollection,
  AttachedFiles,
  AttachmentView,
  Collections,
  Firebase,
  getEnumKeyByValue,
  handleAttachmentUpload,
  LeadSubCollection,
  NoteAttachment,
  useNotification,
  useUserInfo,
} from '@ozark/common';
import React, {useRef, useState} from 'react';
import {acceptedExtensions} from '../Attachments/constants';
import {UploadButton} from '../UploadButton';
import {useNoteContext} from './utils/NoteContext';

const useStyles = makeStyles({
  hidden: {
    display: 'none',
  },
});

type Props = {
  applicationId?: string;
  noteId: string;
  notesSubCollection:
    | AgentSubCollection.notes
    | ApplicationSubCollection.uwRiskNotes
    | ApplicationSubCollection.supportNotes
    | LeadSubCollection.notes;
};

export const NoteFileUpload = ({applicationId, noteId, notesSubCollection}: Props) => {
  const classes = useStyles();
  const fileUploadButtonRef = useRef<HTMLInputElement | null>(null);
  const [currentAttachments, setCurrentAttachments] = useState<AttachedFiles>({});
  const {uid} = useUserInfo();
  const showNotification = useNotification();
  const [pendingDocument, setPendingDocument] = useState<ApplicationDocument | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuItemClick = (applicationDocument: ApplicationDocument) => () => {
    setPendingDocument(applicationDocument);
    fileUploadButtonRef.current?.click();
    handleMenuClose();
  };

  const handleAttachClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  const entityId = applicationId;
  const collection = Collections.applications;
  const getCloudPath = () => `${collection}/${entityId}`;
  const onComplete = async (attachment?: AttachmentView) => {
    if (!attachment) {
      return;
    }
    const noteAttachment: NoteAttachment = {
      attachmentId: attachment.id,
      cloudPath: attachment.cloudPath,
      name: attachment.name,
      createdAt: attachment.createdAt,
    };
    await Firebase.firestore
      .collection(collection)
      .doc(entityId)
      .collection(notesSubCollection)
      .doc(noteId)
      .collection(Collections.attachments)
      .add(noteAttachment);
  };

  const handleSelectFile = ({file}: {file: File}) => {
    if (!uid || !entityId) {
      throw new Error('auth data is missing');
    }

    handleAttachmentUpload({
      uid,
      file: file,
      cloudPath: getCloudPath(),
      entityId: entityId,
      collection: collection,
      pendingDocument: pendingDocument ?? file.name,
      setPendingDocument: setPendingDocument,
      files: currentAttachments,
      setFiles: setCurrentAttachments,
      showNotification: showNotification,
      onComplete: onComplete,
      noteId: noteId,
    });
  };

  const {documentTypeOptions} = useNoteContext();

  return (
    <>
      <Menu
        id="attachment-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        {documentTypeOptions.sort().map((e: any) => {
          const enumKey = getEnumKeyByValue(ApplicationDocument, e) as string;
          return (
            <MenuItem key={enumKey} onClick={handleMenuItemClick(e)}>
              {e}
            </MenuItem>
          );
        })}
      </Menu>
      <Box>
        <UploadButton
          id="uploadButton"
          className={classes.hidden}
          name="uploadDocument"
          variant="outlined"
          color="secondary"
          errors={{uploadDocument: null}}
          size="large"
          setInputRef={inputRef => {
            fileUploadButtonRef.current = inputRef;
          }}
          onSelectFile={handleSelectFile}
          acceptedExtensions={acceptedExtensions}
        />
        <IconButton
          aria-label="add attachment"
          component="span"
          size="small"
          onClick={handleAttachClick}
        >
          <AttachFileIcon />
        </IconButton>
      </Box>
    </>
  );
};
