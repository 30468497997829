import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Button,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {OzarkUserRoles, useMerchant} from '@ozark/common';
import {Loading, MerchantEdit, Title} from '@ozark/common/components';
import {useSiteJumpAsUser} from '@ozark/common/hooks/useSiteJumpAsUser';
import {useHistory, useParams} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {MerchantDeleteDialog} from './MerchantDeleteDialog';
import {MerchantResetPasswordDialog} from './MerchantResetPasswordDialog';

const MerchantDetails = () => {
  const {authUser} = useStore();
  const history = useHistory();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('lg'));
  const {id} = useParams<{id: string}>();
  const {authProfile} = useStore();
  const {siteJumpAsUser, loading: siteJumpLoading} = useSiteJumpAsUser();
  const canEdit = OzarkUserRoles.some(role => role === authProfile.data?.role);

  const {
    document: {data: merchant},
  } = useMerchant(id);

  if (!merchant || !authUser.data) return <Loading />;

  return (
    <div>
      <Title
        breadcrumbs={
          smDown
            ? [
                <IconButton onClick={() => history.goBack()} size="large">
                  <ChevronLeftIcon />
                </IconButton>,
              ]
            : [
                <Link
                  component="button"
                  variant="body1"
                  onClick={() => history.push(ROUTES.MERCHANTS)}
                >
                  Merchants
                </Link>,
                <Typography variant="body1">{`${merchant.firstName || ''} ${
                  merchant.lastName || ''
                }`}</Typography>,
              ]
        }
      />
      <Paper>
        <Stack direction="row" spacing={2} p={1} justifyContent="flex-end">
          <MerchantResetPasswordDialog merchantEmail={merchant.email} />
          <MerchantDeleteDialog merchantUid={id} />
          <Button
            onClick={() => siteJumpAsUser({uid: id})}
            size="large"
            disabled={siteJumpLoading}
            startIcon={<OpenInNewIcon />}
          >
            Jump to Portal
          </Button>
        </Stack>
        <MerchantEdit merchantId={merchant.id} isAuthUserAdmin={canEdit} />
      </Paper>
    </div>
  );
};

export default MerchantDetails;
