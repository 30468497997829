import {useCallback} from 'react';
import {
  Collections,
  Firebase,
  useAuthContainer,
  useLoadingContainer,
  useNotification,
} from '../../..';
import type {NewTicketAttachmentData} from '../types';
import {uploadFileAndGetCloudPath} from '../utils';
import {useTicketEditContainer} from './useTicketEditContainer';
import {useTicketId} from './useTicketId';

// we need to create a simple CRUD for a comment subcollection with no real aploads - just writing stuff

export function useCommentAttachments() {
  const {authUser} = useAuthContainer();
  const {ticketId} = useTicketId();
  const {commentsCollection} = useTicketEditContainer();
  const {setIsLoading} = useLoadingContainer();
  const showNotification = useNotification();

  const attachmentUpload = useCallback(
    async (commentId?: string, file?: File) => {
      setIsLoading(true);

      try {
        if (!ticketId || !commentId || !file) {
          throw new Error('attachmentUpload: invalid arguments');
        }

        const fileCloudPath = await uploadFileAndGetCloudPath(`tickets/${ticketId}`, file);

        const newTicketCommentAttachment: NewTicketAttachmentData = {
          uid: authUser.data?.uid ?? '',
          createdAt: Firebase.FieldValue.now(),
          fileName: file.name,
          fileCloudPath,
        };

        const docRef = await Firebase.firestore
          .collection(Collections.tickets)
          .doc(ticketId)
          .collection(commentsCollection)
          .doc(commentId)
          .collection(Collections.attachments)
          .add(newTicketCommentAttachment);

        showNotification('success', 'File successfully uploaded');
      } catch (error) {
        console.error('Error adding document: ', error);
        showNotification('error', 'File upload failed');
      } finally {
        setIsLoading(false);
      }
    },
    [ticketId, commentsCollection]
  );

  const attachmentRemove = useCallback(
    async (commentId?: string, attachmentId?: string) => {
      if (!ticketId || !commentId || !attachmentId) {
        console.log('remove attachment', ticketId, commentId, attachmentId);
        showNotification('error', 'Error removing document (missing params)');
        return;
      }
      try {
        await Firebase.firestore
          .collection(Collections.tickets)
          .doc(ticketId)
          .collection(commentsCollection)
          .doc(commentId)
          .collection(Collections.attachments)
          .doc(attachmentId)
          .delete();

        showNotification('success', 'Document successfully deleted!');
      } catch (error) {
        showNotification('error', 'Error removing document');
        console.error('Error removing document: ', error);
      }
    },
    [ticketId, commentsCollection]
  );

  return {
    attachmentUpload,
    attachmentRemove,
  };
}
