import {Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ScorecardServiceName} from '@ozark/functions/src/shared';
import ExperianLogo from '../../static/images/experian_logo.png';
import FinicityLogo from '../../static/images/finicity_logo.png';
import LexisNexisLogo from '../../static/images/lexis_nexis_logo.png';
import MatchLogo from '../../static/images/match_logo.png';
import PlaidLogo from '../../static/images/plaid_logo.png';

export const ScorecardsLogoMap: {[_ in ScorecardServiceName]: any} = {
  [ScorecardServiceName.experian]: ExperianLogo,
  [ScorecardServiceName.lexisNexis]: LexisNexisLogo,
  [ScorecardServiceName.match]: MatchLogo,
  [ScorecardServiceName.plaid]: PlaidLogo,
  [ScorecardServiceName.finicity]: FinicityLogo,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: 160,
      height: '2.4rem',

      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      marginRight: theme.spacing(8),
    },
  })
);

interface Props {
  scorecardService: ScorecardServiceName;
}

export const ScorecardServiceLogo = ({scorecardService}: Props) => {
  const classes = useStyles();
  return (
    <div
      className={classes.card}
      style={{backgroundImage: `url(${ScorecardsLogoMap[scorecardService]})`}}
    />
  );
};
