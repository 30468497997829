import {TicketTypeAndCategory} from '../../../..';
import {InputCategoryError} from '../../types';
import {CategoryEditorCategory} from './CategoryEditorCategory';
import {CategoryEditorType} from './CategoryEditorType';

interface Props {
  draft?: TicketTypeAndCategory;
  setDraft: (draft: TicketTypeAndCategory) => void;
  errors: Record<InputCategoryError, string>;
  setErrors: (errors: Record<InputCategoryError, string>) => void;
}
export const CategoryEditorTypeAndCategory = ({draft, setDraft, errors, setErrors}: Props) => {
  return (
    <>
      <CategoryEditorType draft={draft} setDraft={setDraft} errors={errors} setErrors={setErrors} />
      <CategoryEditorCategory
        draft={draft}
        setDraft={setDraft}
        errors={errors}
        setErrors={setErrors}
      />
    </>
  );
};
