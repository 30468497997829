import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Button, ButtonTypeMap} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {useCallback, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {EquipmentDescriptionDialog} from './EquipmentDescriptionDialog';
import {FieldVarSheet} from './FieldVarSheet';

type Props = {
  applicationId: string;
  isAdditional: boolean;
  targetFieldName: string;
  readonly: boolean;
  handleEquipmentRemove?: () => void;
};

export const EquipmentListItemMenu = ({
  applicationId,
  isAdditional,
  targetFieldName,
  readonly,
  handleEquipmentRemove,
}: Props) => {
  const {watch, setValue} = useFormContext();
  const [descriptionDialogOpen, setDescriptionDialogOpen] = useState(false);

  const watchDescription = watch(`${targetFieldName}.description`, '');
  const watchVarSheetAttachment = watch(`${targetFieldName}.VARSheetAttachmentUrl`, null);

  const handleDescriptionEdit = useCallback(
    (description: string) => {
      setValue(`${targetFieldName}.description`, description, {shouldDirty: true});
      setDescriptionDialogOpen(false);
    },
    [targetFieldName, setValue, setDescriptionDialogOpen]
  );

  const handleDescriptionDialogClose = useCallback(() => {
    setDescriptionDialogOpen(false);
  }, [setDescriptionDialogOpen]);

  const buttonProps: ButtonTypeMap['props'] = {
    disabled: readonly,
    variant: 'text',
    color: 'secondary',
  };

  return (
    <Box display="flex" onClick={e => e.stopPropagation()}>
      <ButtonStyled {...buttonProps} startIcon={<CloudUploadIcon />}>
        <FieldVarSheet
          fieldNameFull={`${targetFieldName}.VARSheetAttachmentUrl`}
          defaultValue={watchVarSheetAttachment}
          applicationId={applicationId}
          attachmentLabel={`Equipment ${watchDescription} VAR Sheet`}
        >
          Upload VAR Sheet
        </FieldVarSheet>
      </ButtonStyled>
      {watchVarSheetAttachment && (
        <ButtonStyled
          {...buttonProps}
          disabled={false}
          startIcon={<CloudDownloadIcon color="primary" />}
        >
          <a href={watchVarSheetAttachment} target="_blank" rel="noreferrer">
            Download VAR Sheet
          </a>
        </ButtonStyled>
      )}
      {isAdditional ? (
        <ButtonStyled
          {...buttonProps}
          onClick={() => setDescriptionDialogOpen(true)}
          startIcon={<EditIcon />}
        >
          Edit Description
        </ButtonStyled>
      ) : null}
      {isAdditional ? (
        <ButtonStyled
          {...buttonProps}
          onClick={() => handleEquipmentRemove?.()}
          startIcon={<DeleteIcon />}
        >
          Delete
        </ButtonStyled>
      ) : null}
      <EquipmentDescriptionDialog
        open={descriptionDialogOpen}
        dialogTitle="Edit Description"
        okTitle="Save"
        defaultValue={watchDescription}
        onOk={handleDescriptionEdit}
        onClose={handleDescriptionDialogClose}
      />
    </Box>
  );
};

const ButtonStyled = withStyles({
  root: {
    textTransform: 'none',
    marginLeft: '10px',
    padding: '5px 10px',
  },
})(Button);
