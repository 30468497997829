import {Grid, Typography} from '@mui/material';
import {AllMIDs, SearchCriteria, useApiContainer, useMidsContainer} from '@ozark/common';
import {
  ActiveFilter,
  ChargebacksSummaryFilters,
  Filters,
  Table,
  TransactionType,
} from '@ozark/common/components';
import {Loading} from '@ozark/common/components/Loading';
import {
  Chargebacks,
  PaginatedChargebacksResponse,
} from '@ozark/functions/src/functions/express/private/types';
import {isEmpty} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Bar, Filter, MidOptionPreselected} from '..';
import {Column} from '../../../api/Column';
import {CardImage, CardImagesDictionaryType} from '../../CardImage';
import {currentFormatter, forceActiveFilter, useReportingPageStyles} from '../Reporting';
import {ChargebacksFilters} from './ChargebacksFilters';
import {ChargebacksTotals} from './ChargebacksTotals';
import {getCardNumber, getDate} from './index';
import {ChargebacksFilters as ChargebacksFiltersType, getStatusName} from './Types';

const DefaultCriteria: SearchCriteria = {
  limit: 20, // page size
  offset: 1, // page
  order: 'desc',
  orderBy: 'caseAmount',
};

type Props = MidOptionPreselected & {
  chargebackDetailsUrl: string;
};

export const ChargebacksSummaryByMid = ({midOptionPreselected, chargebackDetailsUrl}: Props) => {
  const classes = useReportingPageStyles();
  const history = useHistory<{
    chargeback: Chargebacks;
    caseHistory: Chargebacks[] | [];
    mid?: string;
  }>();
  const api = useApiContainer();
  const {mids, midsOptions, selectedMid, handleSelectMid, forceSelectMidIfNeeded} =
    useMidsContainer();
  const [filters, setFilters] = useState<Filter>({});
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(DefaultCriteria);
  const [chargebacks, setChargebacks] = useState<PaginatedChargebacksResponse | null>();

  const getChargebacks = useCallback(async () => {
    if (!selectedMid || !api) return;
    const chargebacks: PaginatedChargebacksResponse | null = await api?.chargebacks.getChargebacks(
      searchCriteria,
      selectedMid,
      Object.values(filters)
    );

    setChargebacks(chargebacks);
  }, [api, selectedMid, filters, searchCriteria]);

  const handleRowClick = (chargeback: Chargebacks) => {
    const caseHistory = chargebacks?.data.filter(cb => cb.transId === chargeback.transId) || [];
    history.push(chargebackDetailsUrl, {chargeback, caseHistory, mid: selectedMid});
  };

  const handleApplyFilter = (filter: ActiveFilter) => {
    setFilters({...filters, [filter.option.column]: filter});
  };

  const handleDeleteFilter = (id: string) => () => {
    const _filters = {...filters};
    delete _filters[id];
    setFilters(_filters);
  };

  const onMidSelect = (mid: string) => {
    if (mid !== selectedMid) {
      handleSelectMid(mid);
      setSearchCriteria({...searchCriteria, offset: 1});
      setMidFilter(mid);
    }
  };

  const setMidFilter = (mid: string) => {
    const midFilter = forceActiveFilter(ChargebacksFiltersType, 'mid', '__eq', mid);
    setFilters(previous => ({...previous, mid: midFilter}));
  };

  const setTotalCbFilter = () => {
    const filter = forceActiveFilter(
      ChargebacksSummaryFilters,
      'transactionType',
      '__eq',
      TransactionType.Chargeback
    );
    setFilters(previous => {
      const {cardBrand, ...restFilters} = previous;
      return {...restFilters, transactionType: filter};
    });
  };

  const setRdrFilter = () => {
    const filter = forceActiveFilter(
      ChargebacksSummaryFilters,
      'transactionType',
      '__eq',
      TransactionType.RDR
    );
    setFilters(previous => {
      const {cardBrand, ...restFilters} = previous;
      return {...restFilters, transactionType: filter};
    });
  };

  const setCbFilter = () => {
    setFilters(previous => {
      const {cardBrand, transactionType, ...restFilters} = previous;
      return restFilters;
    });
  };

  useEffect(() => {
    if (selectedMid === AllMIDs) return;

    setMidFilter(selectedMid);
  }, [selectedMid]);

  useEffect(() => {
    if (mids.promised || !mids.data) return;
    if (midOptionPreselected) {
      handleSelectMid(midOptionPreselected.mid);
    } else {
      forceSelectMidIfNeeded();
    }
  }, [mids, midOptionPreselected, forceSelectMidIfNeeded]);

  useEffect(() => {
    if (isEmpty(filters)) return;
    getChargebacks();
  }, [searchCriteria, filters]);

  useEffect(() => {
    if (!midsOptions.length) return;
    const currentMid = history.location?.state?.mid ?? midsOptions[0].mid;
    handleSelectMid(currentMid);
    setMidFilter(currentMid);
  }, [mids]);

  if (mids.promised) return <Loading />;

  return (
    <div className={classes.root}>
      <Bar
        title="Disputes"
        midsLoaded
        selectedMid={selectedMid}
        mids={midOptionPreselected ? [midOptionPreselected] : midsOptions}
        onMidSelect={onMidSelect}
        Filters={<Filters options={ChargebacksFiltersType} onApplyFilter={handleApplyFilter} />}
      />
      {isEmpty(mids.data) && (
        <Typography className={classes.noContent}>
          There are no MIDs associated with your account
        </Typography>
      )}

      {chargebacks && !isEmpty(chargebacks) && (
        <Grid container spacing={2} direction="row" alignItems="stretch">
          <ChargebacksFilters filters={filters} handleDeleteFilter={handleDeleteFilter} />

          <ChargebacksTotals
            setTotalCbFilter={setTotalCbFilter}
            setCbFilter={setCbFilter}
            setRdrFilter={setRdrFilter}
            cbAmount={chargebacks.cbAmount}
            cbCount={chargebacks.cbCount}
            rdrCbAmount={chargebacks.rdrCbAmount}
            rdrCbCount={chargebacks.rdrCbCount}
            totalCbAmount={chargebacks.totalCbAmount}
            totalCbCount={chargebacks.totalCbCount}
            totalCbAmountRatio={chargebacks.totalCbAmountRatio}
            totalCbCountRatio={chargebacks.totalCbCountRatio}
          />

          <Grid item xs={12}>
            <Table
              stickyHeader
              scrollableBody
              customHeight="75vh"
              columns={columnsConfig}
              data={chargebacks}
              onRetrieveData={setSearchCriteria}
              onRowClick={handleRowClick}
              paginate
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const columnsConfig = [
  {
    id: 'cardBrand',
    numeric: false,
    sortable: false,
    export: false,
    label: 'Card brand',
    selector: row => (
      <CardImage
        cardType={row.cardholderAccountNumber.substring(0, 1)}
        dictionaryType={CardImagesDictionaryType.Chargebacks}
      />
    ),
  },
  {
    id: 'bin',
    label: 'BIN',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'cardholderAccountNumber',
    numeric: false,
    sortable: false,
    export: false,
    label: 'Card',
    selector: row => getCardNumber(row.cardholderAccountNumber),
  },
  {
    id: 'reasonCode',
    label: 'Reason Code',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'reasonDesc',
    label: 'Reason Description',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'authCode',
    label: 'Auth Code',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'caseAmount',
    numeric: true,
    sortable: true,
    export: false,
    label: 'Amount',
    selector: row => {
      return (
        <span style={{color: row.debitCredit === 'D' ? 'green' : 'inherit'}}>
          {currentFormatter.format(row.caseAmount)}
        </span>
      );
    },
  },
  {
    id: 'acquirerReferenceNumber',
    label: 'Ref#',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'currentStatus',
    numeric: false,
    sortable: false,
    label: 'Current Status',
    selector: row => getStatusName(row.status),
  },
  {
    id: 'dateLoaded',
    numeric: false,
    sortable: true,
    export: false,
    label: 'Date Loaded',
    selector: row => getDate(row.dateLoaded),
  },
] as Column<Chargebacks>[];
