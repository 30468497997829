import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import {useState} from 'react';
import {StyledBadge} from '../common/StyledBadge';
import {useTicketEditContainer} from '../hooks/useTicketEditContainer';

type Props = {
  commentsCounter: number;
  commentsInternalCounter: number;
};

type CommentTabValue = 'Comments' | 'Internal Comments';

export const CommentsTabs2 = ({commentsCounter, commentsInternalCounter}: Props) => {
  const {commentsInternal, setCommentsInternal} = useTicketEditContainer();
  const [value, setValue] = useState<CommentTabValue | null>('Comments');

  const handleTabClick = (
    event: React.MouseEvent<HTMLElement>,
    tabValue: CommentTabValue | null
  ) => {
    tabValue && tabValue !== value && setValue(tabValue);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      value={value}
      exclusive
      onChange={handleTabClick}
      aria-label="text alignment"
    >
      <ToggleButton
        value="Comments"
        sx={{minWidth: 200}}
        onClick={() => setCommentsInternal(false)}
      >
        <StyledBadge offset={-46} active={!commentsInternal} badgeContent={commentsCounter}>
          <span>Comments</span>
        </StyledBadge>
      </ToggleButton>
      <ToggleButton
        value="Internal Comments"
        sx={{px: 2, minWidth: 200}}
        onClick={() => setCommentsInternal(true)}
      >
        <StyledBadge offset={-12} active={commentsInternal} badgeContent={commentsInternalCounter}>
          Internal Comments
        </StyledBadge>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
