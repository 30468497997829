import EditIcon from '@mui/icons-material/Edit';
import {IconButton, TableCell} from '@mui/material';
import {ResourceView} from '@ozark/common';
import {useDisplayContext} from './DisplayContext';

interface Props {
  resourceView: ResourceView;
}
export const DisplayEditButton = ({resourceView}: Props) => {
  const {allowEdit, handleEditClick} = useDisplayContext();

  const button = (
    <IconButton size="small" onClick={() => handleEditClick(resourceView)}>
      <EditIcon />
    </IconButton>
  );

  if (!allowEdit) {
    return null;
  }

  return <TableCell>{button}</TableCell>;
};
