import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {
  ApplicationDisputeReason,
  Dispositions,
  ReasonInputType,
  useDisputeHistory,
  useNotification,
} from '@ozark/common';
import camelcase from 'lodash/camelCase';
import {Fragment, useCallback, useEffect, useState} from 'react';
import {PendingReasons} from '../ApplicationDisputeHandlers/reasons/pending';

type Reason = {
  name: string;
  title: string;
  text: string | null;
};

interface DisputeReasonsDetailProps {
  setDialogVisible: (args0: boolean) => void;
  applicationId: string;
  disposition: Dispositions;
}

export const DisputeReasonsDetail = ({
  setDialogVisible,
  applicationId,
  disposition,
}: DisputeReasonsDetailProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const {getDisputeReasons} = useDisputeHistory();
  const showNotification = useNotification();
  const [disputeReasons, setDisputeReasons] = useState<Reason[]>([]);

  const fetchDisputeReasons = useCallback(async () => {
    const data = await getDisputeReasons(applicationId, disposition);
    const reasons = data.map((x: ApplicationDisputeReason) => {
      const reason = PendingReasons.filter(pr => camelcase(pr.name) === x.key)?.[0];
      return {
        name: x.key,
        title: reason?.name,
        text: reason.type === ReasonInputType.RichText ? (x.value as string) : null,
      };
    });
    setDisputeReasons(reasons);
  }, [applicationId, disposition]);

  useEffect(() => {
    if (!applicationId || !disposition || disputeReasons.length > 0) return;

    fetchDisputeReasons().catch(err => {
      console.error(err);
      showNotification('error', `Error during loading dispute reasons`);
    });
  }, [applicationId, disposition, getDisputeReasons]);

  const cancelDialog = () => {
    setDialogVisible(false);
  };

  return (
    <Dialog
      open={true}
      onClose={cancelDialog}
      aria-labelledby="application-dispute-reasons"
      fullScreen={fullScreen}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="application-dispute-reasons">
        Application Dispute Reasons: {disposition}
      </DialogTitle>
      <DialogContent
        sx={{
          margin: '0 auto',
          [theme.breakpoints.up('md')]: {
            width: 600,
          },
          [theme.breakpoints.up('lg')]: {
            width: '100%',
          },
        }}
      >
        <Fragment>
          <Typography variant="body1" gutterBottom>
            {`Selected reason${
              disputeReasons?.length > 0 ? 's' : ''
            } for ${disposition} disposition:`}
          </Typography>
          <List>
            {disputeReasons.map((x, idx) => {
              return (
                <ListItem key={x.name}>
                  <ListItemText
                    primary={
                      <>
                        <Typography sx={{fontWeight: theme.typography.fontWeightBold}}>
                          {idx + 1}.&nbsp;{x.title}
                        </Typography>
                      </>
                    }
                    secondary={
                      <Typography
                        dangerouslySetInnerHTML={{__html: x.text as any}}
                        sx={{
                          whiteSpace: 'pre-line',
                          lineHeight: 1.1,
                          margin: theme.spacing(2),
                        }}
                      />
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </Fragment>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelDialog}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
