import {DocumentBase, ViewBase} from '.';
import {Overwrite} from '../helpers';
import {UniversalTimestamp} from './compat';
import {defaultViewSelector, ViewSelector} from './ViewSelector';

export type DisputeAttachmentAuthor = {
  uid: string;
  name: string;
};

export type Dispute = DocumentBase & {
  mid: string;
  createdAt: UniversalTimestamp;
};

export enum DisputeAttachmentStatus {
  Created = 'created',
  Uploaded = 'uploaded',
}

export type DisputeAttachment = DocumentBase & {
  fileDate: UniversalTimestamp;
  name?: string;
  caseNumber: string;
  label?: string;
  fileNameKey?: string; // unique field for parsed files
  cloudPathView: string;
  cloudPathDownload: string;
  createdAt: UniversalTimestamp;
  status?: DisputeAttachmentStatus;
  author?: DisputeAttachmentAuthor;
  deleted?: boolean;
};

export type DisputeAttachmentView = ViewBase<DisputeAttachment>;

export type DisputeAttachmentInput = Overwrite<DisputeAttachment, {createdAt: Date}>;

export const selectDisputeAttachmentView: ViewSelector<
  DisputeAttachmentView,
  DisputeAttachment
> = snapshot => {
  const disputeAttachment: DisputeAttachmentView = {
    ...defaultViewSelector(snapshot),
  };
  return disputeAttachment;
};

export enum DisputeResponseStatus {
  InProgress = 'in_progress',
  DocumentsUploaded = 'documents_uploaded',
  ResponseFileCreated = 'response_file_created',
  RebuttalFileCreated = 'rebuttal_file_created',
  FilesConverted = 'files_converted',
  MultipageResponseCreated = 'multipage_response_created',
}

export type DisputeResponse = DocumentBase & {
  mid: string;
  caseNumber: string;
  author: DisputeAttachmentAuthor;
  answer: boolean;
  fileName: string;
  rebuttal?: string;
  cloudPath?: string;
  signature?: {
    downloadUrl: string;
    fullPath: string;
  };
  status: DisputeResponseStatus;
  createdAt: UniversalTimestamp;
  deleted: boolean;
  success?: boolean;
};

export type DisputeResponseAttachment = DocumentBase & {
  fileDate: UniversalTimestamp;
  name?: string;
  label?: string;
  cloudPathView: string;
  cloudPathDownload: string;
  createdAt: UniversalTimestamp;
};

export type DisputeResponseAttachmentView = ViewBase<DisputeResponseAttachment>;

export type DisputeResponseAttachmentInput = Overwrite<
  DisputeResponseAttachment,
  {createdAt: Date}
>;
