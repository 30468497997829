import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
  AgentSubCollection,
  ApplicationDocument,
  ApplicationSubCollection,
  LeadSubCollection,
} from '@ozark/functions/src/constants';
import {AUTOMATION_NOTE_UID, NoteView} from '@ozark/functions/src/documents';
import firebase from 'firebase/compat';
import {useCallback, useEffect, useMemo, useState} from 'react';
import RichTextEditor from 'react-rte';
import {useCallable, useNotes, useNotification, useUserInfo} from '../../hooks';
import {isRteValueEmpty} from './helpers';
import {useEditorWrapperStyles} from './hooks/useEditorWrapperStyles';
import {NoteList} from './NoteList';
import {NotesUsersProfiles} from './types';
import {NoteContext, NoteContextProps} from './utils/NoteContext';

const getNotesUids = (notes: NoteView[]): Set<string> => {
  const uids = new Set<string>();

  notes.forEach(note => {
    uids.add(note.uid);

    if (note.deletedBy) {
      uids.add(note.deletedBy);
    }

    if (note.updatedBy) {
      uids.add(note.updatedBy);
    }

    if (note.replies) {
      note.replies.forEach(reply => {
        uids.add(reply.uid);

        if (reply.deletedBy) {
          uids.add(reply.deletedBy);
        }

        if (reply.updatedBy) {
          uids.add(reply.updatedBy);
        }
      });
    }
  });

  return uids;
};

interface NotesProps {
  firestoreDocumentRef: firebase.firestore.DocumentReference | null;
  notesSubCollection:
    | ApplicationSubCollection.uwRiskNotes
    | ApplicationSubCollection.supportNotes
    | LeadSubCollection.notes
    | AgentSubCollection.notes;
  title?: string;
  documentTypeOptions?: ApplicationDocument[];
}

export const Notes = ({
  firestoreDocumentRef,
  notesSubCollection,
  title,
  allowAddComments,
  allowAttachments,
  allowDeleteMessages,
  allowEditMessages,
  documentTypeOptions,
}: NotesProps &
  Pick<
    NoteContextProps,
    'allowAddComments' | 'allowAttachments' | 'allowDeleteMessages' | 'allowEditMessages'
  >) => {
  const [isNewNoteEditorVisible, setIsNewNoteEditorVisible] = useState(true);
  const [profiles, setProfiles] = useState<NotesUsersProfiles>({});

  const [newNoteMessage, setNewNoteMessage] = useState(RichTextEditor.createEmptyValue());

  const [noteIdToReply, setNoteIdToReply] = useState<string | null>(null);

  const {notes, addNote} = useNotes({
    docFirebaseRef: firestoreDocumentRef,
    notesSubCollection,
  });

  const {isErpAdmin} = useUserInfo();

  const {getUsersProfiles} = useCallable();

  const showNotification = useNotification();

  useEffect(() => {
    if (!notes.data) {
      return;
    }

    const uids = getNotesUids(notes.data);

    getUsersProfiles({uids: Array.from(uids)})
      .then(result => {
        if (result.status === 'error') {
          console.error('Cannot get users data', result.message, result.errorCode);
          showNotification('error', result.message ?? 'An error occurred while fetching data');
          return;
        }

        const nextProfiles: NotesUsersProfiles = {
          [AUTOMATION_NOTE_UID]: {displayName: 'Auto update'},
        };

        Object.keys(result.profilesDictionary).forEach((uid: string) => {
          const {department, firstName, lastName, photoUrl, role} = result.profilesDictionary[uid];

          const position = department || role;

          if (firstName || lastName) {
            nextProfiles[uid] = {
              displayName: [firstName, lastName, position ? `(${position})` : '']
                .filter(Boolean)
                .join(' '),
              photoUrl,
            };

            return;
          }

          if (position) {
            nextProfiles[uid] = {
              displayName: position,
              photoUrl,
            };

            return;
          }

          // default name for user without name and department
          nextProfiles[uid] = {
            displayName: 'Unknown user',
          };
        });

        setProfiles(nextProfiles);
      })
      .catch(error => {
        console.error('Cannot get users data', error.message);
        showNotification('error', error.message ?? 'An error occurred while fetching data');
      });
  }, [notes.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onReplyClick = useCallback((noteId: string | null) => {
    setNoteIdToReply(noteId);

    setIsNewNoteEditorVisible(!noteId);
  }, []);

  const onEditClick = useCallback((id: string | null) => {
    setNoteIdToReply(null);

    setIsNewNoteEditorVisible(!id);
  }, []);

  const noteContextProps = useMemo<NoteContextProps>(
    () => ({
      allowAttachments,
      allowEditMessages: allowEditMessages && isErpAdmin,
      allowDeleteMessages: allowDeleteMessages && isErpAdmin,
      noteIdToReply,
      notesSubCollection,
      onEditClick,
      onReplyClick,
      documentTypeOptions: documentTypeOptions ?? [],
    }),
    [
      allowAttachments,
      allowEditMessages,
      allowDeleteMessages,
      isErpAdmin,
      noteIdToReply,
      notesSubCollection,
      onEditClick,
      onReplyClick,
      documentTypeOptions,
    ]
  );

  const onNoteAdd = useCallback(async () => {
    if (isRteValueEmpty(newNoteMessage)) {
      return;
    }

    const isOk = await addNote(newNoteMessage.toString('html'));

    if (isOk) {
      setNewNoteMessage(RichTextEditor.createEmptyValue());
    }
  }, [newNoteMessage, addNote]);

  const editorWrapperClasses = useEditorWrapperStyles({});

  return (
    <NoteContext.Provider value={noteContextProps}>
      <Paper sx={{mt: 0, p: [1, 2, 2], flexGrow: 1}}>
        <Box sx={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
          {title && (
            <Box>
              <Typography sx={{fontSize: '1.2rem'}} variant="caption" gutterBottom>
                {title}
              </Typography>
              <Divider />
            </Box>
          )}

          <NoteList
            profiles={profiles}
            firestoreDocumentRef={firestoreDocumentRef}
            notesSubCollection={notesSubCollection}
            isFetching={notes.promised}
            noteViews={notes.data}
          />

          {!notes.promised && isNewNoteEditorVisible && allowAddComments && (
            <div className={editorWrapperClasses.root}>
              <RichTextEditor
                onChange={setNewNoteMessage}
                placeholder="Leave a comment"
                value={newNoteMessage}
                editorStyle={{
                  minHeight: '200px',
                  fontFamily: 'Rubik, sans-serif',
                }}
              />

              <Box sx={{py: 2, textAlign: 'right'}}>
                <Button
                  color="primary"
                  disabled={isRteValueEmpty(newNoteMessage)}
                  size="medium"
                  variant="contained"
                  onClick={onNoteAdd}
                >
                  Comment
                </Button>
              </Box>
            </div>
          )}
        </Box>
      </Paper>
    </NoteContext.Provider>
  );
};
