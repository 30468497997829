import {
  AsyncState,
  Collections,
  DisputeAttachment,
  DisputeResponse,
  DisputeResponseStatus,
  Firebase,
  selectDisputeAttachmentView,
  StoragePath,
  UniversalSnapshot,
} from '@ozark/common';
import {flatten} from '@s-libs/micro-dash';
import {format} from 'date-fns';
import {useCallback, useEffect, useState} from 'react';
import {ResponseWizardFormValues} from '../components/reports/Chargebacks/ResponseWizard';

export const useDisputeAttachmentFiles = (caseNumbers?: string[]) => {
  const [disputeAttachments, setDisputeAttachments] = useState<AsyncState<DisputeAttachment[]>>({
    promised: true,
  });

  const fetchDisputeAttachments = useCallback(async (caseNumbers: string[]) => {
    if (caseNumbers.length === 0) {
      setDisputeAttachments({promised: false, data: []});
      return;
    }
    Firebase.firestore
      .collection(Collections.disputes)
      .where(Firebase.FieldPath.documentId(), 'in', caseNumbers)
      .onSnapshot(
        async snapshot => {
          if (snapshot.size === 0) {
            setDisputeAttachments({promised: false, data: []});
            return;
          }

          const data = await Promise.all(
            snapshot.docs.map(async doc => {
              const attachments: DisputeAttachment[] = [];
              // get attachments
              const disputeAttachments = await Firebase.firestore
                .collection(Collections.disputes)
                .doc(doc.id)
                .collection(Collections.attachments)
                .get();

              if (disputeAttachments.size) {
                const attachmentsData = disputeAttachments.docs.map(type =>
                  selectDisputeAttachmentView(type as UniversalSnapshot<DisputeAttachment>)
                );
                attachments.push(...attachmentsData);
              }

              return attachments;
            })
          );

          const sortedAttachments = flatten(data).sort(
            (a, b) => a.createdAt?.toDate().getTime() - b.createdAt?.toDate().getTime()
          );

          setDisputeAttachments({promised: false, data: sortedAttachments});
        },
        err => {
          console.error(err);
          setDisputeAttachments({promised: false, error: err});
          return;
        }
      );
  }, []);

  useEffect(() => {
    if (!caseNumbers || caseNumbers?.length === 0 || !disputeAttachments.promised) {
      return;
    }
    fetchDisputeAttachments(caseNumbers);
  }, [fetchDisputeAttachments, caseNumbers]);

  const createDisputeRecord = async (mid: string, caseNumber: string) => {
    const disputeDocument = {
      mid,
      createdAt: Firebase.Timestamp.now(),
    };

    await Firebase.firestore
      .collection(Collections.disputes)
      .doc(caseNumber)
      .set(disputeDocument, {merge: true});
  };

  const saveResponse = async (
    userId: string,
    userName: string,
    mid: string,
    caseNumber: string,
    data: ResponseWizardFormValues
  ): Promise<string> => {
    let signatureObject: {fullPath: string; downloadUrl: string} | undefined = undefined;

    if (data.signature) {
      const blob: Blob | null = dataUrlToBlob(data.signature);
      if (blob) {
        const uploadResult = Firebase.storage
          .ref()
          .child(`${StoragePath.signatures}/${userId}`)
          .put(blob);
        const snapshot = await uploadResult;
        const signatureRef = snapshot.ref;
        const downloadUrl = await snapshot.ref.getDownloadURL();
        signatureObject = {fullPath: signatureRef.fullPath, downloadUrl};
      }
    }

    const responseDocument: DisputeResponse = {
      mid,
      caseNumber,
      author: {
        uid: userId || 'N/A',
        name: userName,
      },
      answer: data.response === 'true',
      rebuttal: data.rebuttal,
      fileName: `IN_5846_${caseNumber}_${format(new Date(), 'MMddyyHHmmss')}.TIF`,
      signature: signatureObject,
      status: DisputeResponseStatus.InProgress,
      createdAt: Firebase.Timestamp.now(),
      deleted: false,
    };

    // note: create dispute record
    // remove this logic if we will impletent logic for disabling Respond until notice exists
    await createDisputeRecord(mid, caseNumber);

    const {id: responseId} = await Firebase.firestore
      .collection(Collections.disputes)
      .doc(caseNumber)
      .collection(Collections.responses)
      .add(responseDocument);

    return responseId;
  };

  const onComplete = async (caseNumber: string, responseId: string) => {
    await Firebase.firestore
      .collection(Collections.disputes)
      .doc(caseNumber)
      .collection(Collections.responses)
      .doc(responseId)
      .set({status: DisputeResponseStatus.DocumentsUploaded}, {merge: true});
  };

  return {
    disputeAttachments,
    saveResponse,
    onComplete,
  };
};

const dataUrlToBlob = (dataUrl: string) => {
  try {
    const arr = dataUrl.split(',');
    if (!arr[0]) return null;
    let mime = arr[0].match(/:(.*?);/)![1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
  } catch (err) {
    return null;
  }
};
