import {Box, Typography} from '@mui/material';
import {Title} from '..';

type Props = {
  children?: React.ReactNode;
};

export const MerchantsActivityTitle = ({children}: Props) => {
  return (
    <Title breadcrumbs={[<Typography variant="body1">Online Portal</Typography>]}>
      <Box flexGrow={1} />
      {children}
    </Title>
  );
};
