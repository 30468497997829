import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  Collections,
  ExportToCsv,
  Firebase,
  ResidualStatus,
  useCallable,
  useResiduals,
} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {
  BoxParentHeight,
  ConfirmationDialog,
  DATETIME_FORMAT,
  Loading,
  Table,
} from '@ozark/common/components';
import {ResidualView} from '@ozark/functions/src/documents/Residual';
import {uniq} from '@s-libs/micro-dash';
import {format, parse} from 'date-fns';
import {MouseEventHandler, useState} from 'react';
import {generatePath, useHistory} from 'react-router';
import {RESIDUAL_PAYOUT} from '../../constants/routes';
import {useNotification} from '../../hooks/useNotification';
import {useStore} from '../../store/helpers';

type Totals = {
  yearMonth: string;
  agentNet: string;
  count: number;
  expense: string;
  grossSales: string;
  income: string;
  net: string;
  transactionCount: number;
};

type RowType = ResidualView & Totals;

export const Files = ({onEmptyResiduals}: {onEmptyResiduals: () => void}) => {
  const {apiClient} = useStore();
  const history = useHistory();
  const [selected, setSelected] = useState<ResidualView | null>(null);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const {documents: residuals} = useResiduals();
  const showNotification = useNotification();
  const [confirmationAction, setConfirmationAction] = useState<(() => Promise<void>) | null>(null);
  const {runResidualCalculations} = useCallable();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuClick: MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRowClick = (row: ResidualView) => {
    setSelected(row);
  };

  const handleDownloadClick = () => {
    if (!selected) return;
    var a = document.createElement('a');
    a.href = selected.fileUrl;
    a.setAttribute('download', `${selected.yearMonth}.csv`);
    a.click();
  };

  const exportToCsv = async () => {
    if (!selected?.yearMonth) {
      return;
    }

    const options = {
      fieldSeparator: ',',
      filename: `residuals-${selected?.yearMonth}`,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Residuals',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const exportData = await apiClient.residuals.getResidualExport(selected.yearMonth);

    if (!exportData) return;

    const exporter = new ExportToCsv(options);

    const noNullData = JSON.parse(JSON.stringify(exportData).replace(/null/gi, '""'));

    exporter.generateCsv(noNullData);
  };

  const handleDeleteClick = async () => {
    if (!selected) return;
    await Firebase.firestore.collection(Collections.residuals).doc(selected!.ref.id).delete();
    showNotification('info', 'Residual file has successfully been deleted.');
  };

  const handlePublish = () => {
    if (!selected) return;
    (async () => {
      await Firebase.firestore
        .collection(Collections.residuals)
        .doc(selected!.ref.id)
        .set({status: ResidualStatus.published}, {merge: true});
      setSelected({...selected, status: ResidualStatus.published});
      showNotification(
        'info',
        'Residual file has successfully been published to all groups and agents.'
      );
    })();
  };

  const handleUndoPublish = () => {
    if (!selected) return;
    (async () => {
      await Firebase.firestore
        .collection(Collections.residuals)
        .doc(selected!.ref.id)
        .set({status: ResidualStatus.imported}, {merge: true});
      setSelected({...selected, status: ResidualStatus.imported});
      showNotification(
        'info',
        'Residual file has successfully been unpublished and is now hidden from all groups.'
      );
    })();
  };

  const handlePayoutReportClick = () => {
    if (!selected) return;
    history.push(generatePath(RESIDUAL_PAYOUT, {id: selected.ref.id}));
  };

  if (residuals.promised) return <Loading />;

  if (!residuals.promised && !residuals.data) {
    onEmptyResiduals();
    return <Loading />;
  }

  const rows = residuals.data?.map((e, i) => ({...e, ...e.totals, id: i})) as RowType[] | undefined;
  const handleRefreshResidualClick = async () => {
    if (!selected) {
      return;
    }

    await runResidualCalculations({residualId: selected.ref.id});

    showNotification('info', 'Residual file is processing to refresh data');
  };

  return (
    <>
      <BoxParentHeight overflow="unset" sx={{'& > div': {maxHeight: '100%'}}}>
        {rows && (
          <Table
            onRowClick={handleRowClick}
            rows={rows}
            selectedId={selected?.id}
            columns={getTableColumns(selected, handleMenuClick)}
            scrollableBody
            stickyHeader
          />
        )}
      </BoxParentHeight>

      <ConfirmationDialog
        title="Confirmation"
        message="Are you sure you want to delete this residual file?"
        onClose={() => setConfirmationAction(null)}
        onConfirm={confirmationAction}
      />
      {selected?.errors && (
        <Dialog open={showErrors} maxWidth={'lg'}>
          <DialogTitle id="add-equipment-dialog-title">Import Errors</DialogTitle>
          <DialogContent>
            <List>
              {uniq(selected.errors).map((e, i) => {
                return (
                  <ListItem key={i}>
                    <ListItemText
                      primary={<Typography gutterBottom>{`${e.toUpperCase()}.`}</Typography>}
                    />
                  </ListItem>
                );
              })}
            </List>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setShowErrors(false)} type="submit">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Menu
        id="option-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {selected && (
          <div>
            {selected.status === ResidualStatus.failed && (
              <MenuItem onClick={() => setShowErrors(true)}>View Errors</MenuItem>
            )}
            {selected.status !== ResidualStatus.failed && (
              <MenuItem onClick={handlePayoutReportClick}>Payout Report</MenuItem>
            )}
            {(selected.status === ResidualStatus.imported ||
              selected.status === ResidualStatus.readyForReview) && (
              <MenuItem onClick={handlePublish}>Publish</MenuItem>
            )}
            {selected.status === ResidualStatus.published && (
              <MenuItem onClick={handleUndoPublish}>Undo Publish</MenuItem>
            )}
            {selected.status !== ResidualStatus.failed && (
              <MenuItem onClick={handleRefreshResidualClick}>Refresh</MenuItem>
            )}
            <MenuItem onClick={handleDownloadClick}>Download Original File</MenuItem>
            <MenuItem onClick={exportToCsv}>Export</MenuItem>
            <MenuItem onClick={() => setConfirmationAction(() => handleDeleteClick)}>
              Delete
            </MenuItem>
          </div>
        )}
      </Menu>
    </>
  );
};

const formatCurrency = (value?: number) => {
  if (!value) return '';

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(value as number);
};

const getTableColumns = (
  selected: ResidualView | null,
  handleMenuClick: MouseEventHandler<HTMLButtonElement>
): Column<RowType>[] => [
  {
    id: 'yearMonth',
    label: 'File Date',
    selector: row => {
      const date = parse(row.yearMonth, 'yyyyMM', new Date());
      return format(date, 'MMMM, yyyy');
    },
  },
  {id: 'status', label: 'Import Status', width: 160},
  {
    id: 'count',
    label: 'Rows Imported',
    numeric: true,
  },
  {
    id: 'transactionCount',
    label: 'Transactions',
    numeric: true,
  },
  {
    id: 'grossSales',
    label: 'Gross Sales',
    numeric: true,
    selector: row => formatCurrency(Number(row.grossSales)),
  },
  {
    id: 'income',
    label: 'Income',
    numeric: true,
    selector: row => formatCurrency(Number(row.income)),
  },
  {
    id: 'expense',
    label: 'Expense',
    numeric: true,
    selector: row => formatCurrency(Number(row.expense)),
  },
  {
    id: 'net',
    label: 'Net',
    numeric: true,
    selector: row => formatCurrency(Number(row.net)),
  },
  {
    id: 'agentNet',
    label: 'Agent Net',
    numeric: true,
    selector: row => formatCurrency(Number(row.agentNet)),
  },
  {
    id: 'updatedAt',
    label: 'Last Updated',
    numeric: true,
    selector: row => {
      const date = row.updatedAt ?? row.createdAt;
      return date && format(date.toDate(), DATETIME_FORMAT);
    },
  },
  {
    id: 'actions',
    label: '',
    numeric: true,
    width: 150,
    selector: row => {
      if (row.yearMonth === selected?.yearMonth) {
        return (
          <Button
            color="inherit"
            onClick={handleMenuClick}
            variant="text"
            sx={{p: 0, lineHeight: 0}}
          >
            actions
          </Button>
        );
      }
      return '';
    },
  },
];
