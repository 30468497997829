import {ApplicationView, Dispositions} from '@ozark/common';
import {ApplicationResponse} from '@ozark/functions/src/functions/express/private/types';
import {format} from 'date-fns';
import {asUniversalTimestamp} from '../../helpers';

const DATETIME_FORMAT_FULL = 'MM/dd/yyyy h:mm a';
export const getApplicationDate = (application: ApplicationResponse | ApplicationView) => {
  let label = 'Date Started';
  let dateString = '-';

  if (application.completedAt || application.createdAt) {
    dateString = format(
      (asUniversalTimestamp(application.completedAt) ||
        asUniversalTimestamp(application.createdAt))!.toDate(),
      DATETIME_FORMAT_FULL
    );
  }

  if (application.disposition === Dispositions.boarded && application.dispositionUpdatedAt) {
    label = 'Date Boarded';
    dateString = format(
      asUniversalTimestamp(application.dispositionUpdatedAt)!.toDate(),
      DATETIME_FORMAT_FULL
    );
  }

  if (application.referenceApp) {
    label = 'Date Cloned';
    dateString = format(
      asUniversalTimestamp(application.createdAt)!.toDate(),
      DATETIME_FORMAT_FULL
    );
  }

  if (application.completedAt) {
    label = 'Date Signed';
  }

  return {dateString, label} as const;
};
