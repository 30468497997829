import {Dispositions} from '@ozark/common';
import {FilterOption} from '@ozark/common/components';

export const ApplicationFilters: FilterOption[] = [
  {
    id: 'uid',
    column: 'uid',
    label: 'uid',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'groupId',
    column: 'groupId',
    label: 'Group',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'agentId',
    column: 'agentId',
    label: 'Agent',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'deleted',
    column: 'deleted',
    label: 'deleted',
    type: 'boolean',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'dispositions',
    column: 'disposition',
    label: 'Dispositions',
    type: 'list',
    options: Object.values(Dispositions).map(x => ({key: x, value: x})),
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
];
