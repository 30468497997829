export enum TimeZone {
  Adak = 'America/Adak',
  Anchorage = 'America/Anchorage',
  Boise = 'America/Boise',
  Chicago = 'America/Chicago',
  Denver = 'America/Denver',
  Detroit = 'America/Detroit',
  Indianapolis = 'America/Indiana/Indianapolis',
  Knox = 'America/Indiana/Knox',
  Marengo = 'America/Indiana/Marengo',
  Petersburg = 'America/Indiana/Petersburg',
  TellCity = 'America/Indiana/Tell_City',
  Vevay = 'America/Indiana/Vevay',
  Vincennes = 'America/Indiana/Vincennes',
  Winamac = 'America/Indiana/Winamac',
  Juneau = 'America/Juneau',
  Louisville = 'America/Kentucky/Louisville',
  Monticello = 'America/Kentucky/Monticello',
  LosAngeles = 'America/Los_Angeles',
  Menominee = 'America/Menominee',
  Metlakatla = 'America/Metlakatla',
  NewYork = 'America/New_York',
  Nome = 'America/Nome',
  DakotaBeulah = 'America/North_Dakota/Beulah',
  DakotaCenter = 'America/North_Dakota/Center',
  DakotaNewSalem = 'America/North_Dakota/New_Salem',
  Phoenix = 'America/Phoenix',
  Sitka = 'America/Sitka',
  Yakutat = 'America/Yakutat',
  Honolulu = 'Pacific/Honolulu',
}

export const TimeZoneValues: {[_ in TimeZone]: string} = {
  [TimeZone.Adak]: 'America/Adak',
  [TimeZone.Anchorage]: 'America/Anchorage',
  [TimeZone.Boise]: 'America/Boise',
  [TimeZone.Chicago]: 'America/Chicago',
  [TimeZone.Denver]: 'America/Denver',
  [TimeZone.Detroit]: 'America/Detroit',
  [TimeZone.Indianapolis]: 'America/Indiana/Indianapolis',
  [TimeZone.Knox]: 'America/Indiana/Knox',
  [TimeZone.Marengo]: 'America/Indiana/Marengo',
  [TimeZone.Petersburg]: 'America/Indiana/Petersburg',
  [TimeZone.TellCity]: 'America/Indiana/Tell City',
  [TimeZone.Vevay]: 'America/Indiana/Vevay',
  [TimeZone.Vincennes]: 'America/Indiana/Vincennes',
  [TimeZone.Winamac]: 'America/Indiana/Winamac',
  [TimeZone.Juneau]: 'America/Juneau',
  [TimeZone.Louisville]: 'America/Kentucky/Louisville',
  [TimeZone.Monticello]: 'America/Kentucky/Monticello',
  [TimeZone.LosAngeles]: 'America/Los Angeles',
  [TimeZone.Menominee]: 'America/Menominee',
  [TimeZone.Metlakatla]: 'America/Metlakatla',
  [TimeZone.NewYork]: 'America/New York',
  [TimeZone.Nome]: 'America/Nome',
  [TimeZone.DakotaBeulah]: 'America/North Dakota/Beulah',
  [TimeZone.DakotaCenter]: 'America/North Dakota/Center',
  [TimeZone.DakotaNewSalem]: 'America/North Dakota/New Salem',
  [TimeZone.Phoenix]: 'America/Phoenix',
  [TimeZone.Sitka]: 'America/Sitka',
  [TimeZone.Yakutat]: 'America/Yakutat',
  [TimeZone.Honolulu]: 'Pacific/Honolulu',
};

export const UnitedStatesTimeZonesShortList = {
  PST: 'America/Los_Angeles',
  MST: 'America/Denver',
  CST: 'America/Chicago',
  EST: 'America/New_York',
};

export const DefaultTimeZone = TimeZone.LosAngeles;

export const TimeZoneIdsList: string[] = Object.values(TimeZone);
