import {
  AsyncState,
  Collections,
  Firebase,
  GetUsersProfilesResult,
  SecuredUserProfile,
  TicketComment,
  UniversalTimestamp,
  useCallable,
  useNotification,
} from '@ozark/common';
import {format, formatRelative} from 'date-fns';
import {useEffect, useState} from 'react';
import {useTicketEditContainer} from './useTicketEditContainer';
import {useTicketId} from './useTicketId';

export type CommentInfo = {
  comment: TicketComment;
  humanFriendlyDate: string;
  localTime: string;
  author: SecuredUserProfile | undefined;
};

const getMappedComments = async (
  comments: TicketComment[],
  authors: Record<string, SecuredUserProfile>
): Promise<CommentInfo[]> => {
  if (!comments.length) {
    return [];
  }

  const commentsInfo = comments.map(comment => {
    const dateCreateAt = (comment.createdAt as UniversalTimestamp)?.toDate();
    const humanFriendlyDate = formatRelative(dateCreateAt, new Date()).split(' at ')[0];
    const localTime = format(dateCreateAt, 'MM/dd/yy - hh:mm a');
    const author = authors[comment.uid];

    return {
      comment,
      humanFriendlyDate,
      localTime,
      author,
    };
  });

  return commentsInfo;
};

export const useComments = () => {
  const {commentsCollection} = useTicketEditContainer();
  const {ticketId, isTicketIdValid} = useTicketId();
  const [comments, setComments] = useState<AsyncState<CommentInfo[]>>({promised: true});
  const [commentsCounter, setCommentsCounter] = useState(0);
  const [commentsInternalCounter, setCommentsInternalCounter] = useState(0);
  const [userProfiles, setUserProfiles] = useState<AsyncState<GetUsersProfilesResult>>({
    promised: true,
  });
  const showNotification = useNotification();
  const {getUsersProfiles} = useCallable();
  useEffect(() => {
    getUsersProfiles().then(x => {
      setUserProfiles({promised: false, data: x});
    });
  }, []);

  useEffect(() => {
    if (userProfiles.promised) {
      //no profiles yet
      return;
    }
    if (!isTicketIdValid) {
      console.error('missing ticketId');
      setComments({promised: false, data: []});
      return;
    }
    try {
      setComments({promised: true, data: []});
      return Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .collection(commentsCollection)
        .orderBy('createdAt', 'desc')
        .onSnapshot(
          async snapshot => {
            if (snapshot.size === 0) {
              setComments({promised: false, data: []});
              return;
            }
            const comments = snapshot.docs.map(
              doc => ({...doc.data(), id: doc.id} as TicketComment)
            );

            const profilesResult = userProfiles.data!;
            if (profilesResult.status === 'error') {
              throw new Error(
                profilesResult.message ?? 'An error occurred while getting comments data'
              );
            }

            const mappedComments = await getMappedComments(
              comments ?? [],
              profilesResult.profilesDictionary
            );
            setComments({promised: false, data: mappedComments});
          },
          err => {
            console.error(err);
            showNotification('error', err.message);
            setComments({promised: false, error: err});
          }
        );
    } catch (error) {
      console.error('error reading comments', error);
    }
  }, [ticketId, isTicketIdValid, commentsCollection, userProfiles.promised]);

  useEffect(() => {
    if (!isTicketIdValid) {
      console.error('missing ticketId');
      setCommentsCounter(0);
      return;
    }
    try {
      return Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .collection(Collections.comments)
        .onSnapshot(
          async snapshot => {
            setCommentsCounter(snapshot.size);
          },
          err => {
            console.error(err);
            setCommentsCounter(0);
          }
        );
    } catch (error) {
      console.error('error reading comments count', error);
    }
  }, [ticketId, isTicketIdValid]);

  useEffect(() => {
    if (!isTicketIdValid) {
      console.error('missing ticketId');
      setCommentsInternalCounter(0);
      return;
    }
    try {
      return Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .collection(Collections.commentsInternal)
        .onSnapshot(
          async snapshot => {
            setCommentsInternalCounter(snapshot.size);
          },
          err => {
            console.error(err);
            setCommentsInternalCounter(0);
          }
        );
    } catch (error) {
      console.error('error reading comments count', error);
    }
  }, [ticketId, isTicketIdValid]);

  return {comments, commentsCounter, commentsInternalCounter};
};
