import {useEffect, useState} from 'react';
import {Collections, Firebase, Group, TicketManager, useAuthContainer} from '../../..';
import {getMerchantAsync} from '../../../hooks/useMerchant';
import {useUserInfo} from '../../../hooks/useUserInfo';

const HIGH_PRIORITY_GROUP_NAME = 'tangram';

export function useGroupsOptions() {
  const {uid, isErpUser, isMerchant} = useUserInfo();
  const {claims} = useAuthContainer();
  const [groups, setGroups] = useState<TicketManager[]>([]);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      setGroups([]);
      let query = Firebase.firestore.collection(Collections.groups);
      if (isErpUser) {
        const snap = await query.get();
        if (!snap.size) return;

        const options = snap.docs.map(doc => ({id: doc.id, name: (doc.data() as Group).name}));
        const sortedOptions = [...options].sort((a, b) => {
          const isAHighPriorityGroup = a.name.toLowerCase().includes(HIGH_PRIORITY_GROUP_NAME);
          const isBHighPriorityGroup = b.name.toLowerCase().includes(HIGH_PRIORITY_GROUP_NAME);
          if (isAHighPriorityGroup && isBHighPriorityGroup) {
            return a.name.localeCompare(b.name);
          }

          if (isAHighPriorityGroup) {
            return -1;
          }

          if (isBHighPriorityGroup) {
            return 1;
          }

          return a.name.localeCompare(b.name);
        });

        isMounted && setGroups(sortedOptions);
      } else {
        let groupId = claims?.groupId;
        if (!groupId && isMerchant) {
          // merchant claims do not have groupId yet
          groupId = (await getMerchantAsync(uid))?.groupId;
        }

        if (!groupId) return;
        const doc = await query.doc(groupId).get();
        if (!doc.exists) return;

        isMounted && setGroups([{id: doc.id, name: (doc.data() as Group).name}]);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [uid, claims]);

  return {
    groups,
  };
}
