import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import {
  Button,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Theme} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Collections, ExportToCsv, Firebase, useNotification} from '@ozark/common';
import {InlineTypographyGeneric, Loading} from '@ozark/common/components';
import {
  LineItem,
  Payouts,
  ResidualPayoutWithNumbers,
  ResidualView,
  Totals,
} from '@ozark/functions/src/documents/Residual';
import {get, groupBy, keyBy, map, reduce, set, sortBy, sumBy} from '@s-libs/micro-dash';
import {Fragment, useEffect, useRef, useState} from 'react';
import {useStore} from '../../store/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > *': {
        marginLeft: theme.spacing(2),
      },
      paddingRight: theme.spacing(2),
    },
    table: {
      marginBottom: theme.spacing(8),
    },
    rowHeader: {
      backgroundColor: '#f5fafc',
      color: '#4d6575',
    },
    rowSubHeader: {
      backgroundColor: '#f5fafc66',
      color: '#4d6575',
    },
    cellLeftPadding: {
      paddingLeft: theme.spacing(6),
    },
    cellLeftPaddingDouble: {
      paddingLeft: theme.spacing(12),
    },
    cellValueWidth: {
      width: '15%',
    },
    cellActionWidth: {
      width: 48,
    },
    inlineTypography: {
      color: theme.palette.primary.main,
    },
    inlineTypographyInput: {
      color: 'black',
      textAlign: 'right',
    },
  })
);

export const PayoutReport = ({residualView}: {residualView: ResidualView}) => {
  const classes = useStyles();
  const {apiClient} = useStore();
  const showNotification = useNotification();

  const editOnRender = useRef<{[_: string]: boolean}>({});

  const [payoutMap, setPayoutMap] = useState<Payouts>();

  const [payoutView, setPayoutView] = useState<
    {
      groupId: string;
      groupName: string;
      payoutMethod: string;
      payouts: ResidualPayoutWithNumbers[];
      lineItems: LineItem[];
      subtotals: Totals;
      totals: Totals;
    }[]
  >();

  useEffect(() => {
    if (residualView.payouts) {
      setPayoutMap(residualView.payouts);
      return;
    }
    apiClient.residuals.getResidualPayoutByAgent(residualView.yearMonth).then(result => {
      if (!result) return;
      const resultsWithLineItems = result.map(e => ({...e, lineItems: []}));
      const byGroup = groupBy(resultsWithLineItems, e => e.firestoreGroupId);
      const e = reduce(
        byGroup,
        (result, value, key) => {
          const byAgent = keyBy(value, (e: any) => e.firestoreAgentId);
          return {
            ...result,
            [String(key)]: {payoutMethod: 'group', payouts: byAgent, lineItems: []},
          };
        },
        {}
      );
      setPayoutMap(e);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!payoutMap) return;
    const unsorted = map(
      payoutMap,
      (
        value: {
          payoutMethod: string;
          payouts: {[agentId: string]: ResidualPayoutWithNumbers};
          lineItems: LineItem[];
        },
        key: string
      ) => {
        const payouts = Object.values(value.payouts);
        const numberedPayouts = sortBy(
          payouts.map(payout => {
            const sumOfLineItems = sumBy(payout.lineItems, item => item.amount) || 0;
            return {
              ...payout,
              profit: parseFloat(payout.profit?.toString() || '0'),
              groupNetSplit: parseFloat(payout.groupNetSplit?.toString() || '0'),
              groupProfit: parseFloat(payout.groupProfit?.toString() || '0'),
              grossProfit:
                parseFloat(payout.groupProfit?.toString() || '0') +
                parseFloat(payout.agentNetSplit?.toString() || '0'),
              agentNetSplit: parseFloat(payout.agentNetSplit?.toString() || '0'),
              agentProfit: parseFloat(payout.agentProfit?.toString() || '0'),
              subAgentNetSplit: parseFloat(payout.subAgentNetSplit?.toString() || '0'),
              subAgentProfit: parseFloat(payout.subAgentProfit?.toString() || '0'),
              sumOfLineItems: sumOfLineItems,
            } as ResidualPayoutWithNumbers;
          }),
          payout => payout.agentName
        );

        const subtotals = {
          net: sumBy(numberedPayouts, e => e.profit),
          group: sumBy(numberedPayouts, e =>
            isGroupPayoutMethod(value) ? e.groupNetSplit + e.sumOfLineItems : e.groupProfit
          ),
          gross: sumBy(numberedPayouts, e => e.grossProfit + e.sumOfLineItems),
          agent: sumBy(numberedPayouts, e =>
            isGroupPayoutMethod(value) ? 0 : e.agentNetSplit + e.sumOfLineItems
          ),
        };

        const totals = {
          net: subtotals.net,
          group: subtotals.group + sumBy(value.lineItems, item => item.amount),
          gross: subtotals.gross + sumBy(value.lineItems, item => item.amount),
          agent: subtotals.agent,
        };

        if (payouts.length > 1) {
          showNotification(
            'error',
            `There's an issue with agent ${payouts[0].agentName}. Perhaps there are inconsistent agent names.`
          );
        }

        return {
          groupId: key,
          groupName: payouts[0].groupName,
          payoutMethod: value.payoutMethod,
          payouts: numberedPayouts,
          lineItems: value.lineItems,
          subtotals: subtotals,
          totals: totals,
        };
      }
    );
    const sorted = sortBy(unsorted, e => e.groupName);
    setPayoutView(sorted);
  }, [payoutMap, showNotification]);

  const handleSaveClick = () => {
    Firebase.firestore
      .collection(Collections.residuals)
      .doc(residualView.id)
      .set({payouts: payoutMap}, {merge: true})
      .then(() => {
        showNotification('success', 'Successfully saved payout report.');
      })
      .catch(err => {
        console.error(err);
        showNotification('error', 'Failed to save payout report.');
      });
  };

  const onGroupPayoutMethodChange =
    (groupId: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
      setPayoutMap({...set(payoutMap, [groupId, 'payoutMethod'], event.target.value as string)});

  const isGroupPayoutMethod = (group: {payoutMethod: string}) => group.payoutMethod === 'group';

  const onAddLineItemClick = (path: (string | number)[]) => () => {
    if (!payoutMap) return;
    const copy = {...payoutMap};
    const lineItems = [...get(copy, path), {description: 'Description', amount: 0}];
    editOnRender.current[[...path, lineItems.length - 1].join('.')] = true;
    setPayoutMap({...set(copy, path, lineItems)});
  };

  const handleLineItemChange = <T extends unknown>(path: (string | number)[], value: T) => {
    if (!payoutMap) return;
    const copy = {...payoutMap};
    setPayoutMap({...set(copy, path, value)});
  };

  const handleLineItemDelete = (path: (string | number)[], index: number) => {
    if (!payoutMap) return;
    const copy = {...payoutMap};
    const lineItems = get(copy, path);
    const filteredItems = lineItems
      .slice(0, index)
      .concat(lineItems.slice(index + 1, lineItems.length));
    setPayoutMap({...set(copy, path, filteredItems)});
  };

  const handleExportClick = () => {
    if (!payoutMap) return;
    const data: any = [];
    payoutView?.forEach(group => {
      if (group.payoutMethod === 'agent') {
        group.payouts.forEach(payout => {
          data.push({
            group: group.groupName,
            agent: payout.agentName,
            amount: payout.agentNetSplit + payout.sumOfLineItems,
          });
        });
      }
      data.push({group: group.groupName, agent: '', amount: group.totals.group});
    });

    const options = {
      fieldSeparator: ',',
      filename: `payout-report-${residualView.yearMonth}.csv`,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: `Payout Report - ${residualView.yearMonth}`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const exporter = new ExportToCsv(options);

    exporter.generateCsv(data);
  };

  const formatCurrency = (value: number) => {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const formatted = formatter.format(parseFloat(value.toString()));
    if (value >= 0) {
      return formatted.replace('$', '$ ');
    }
    return <span style={{color: 'red'}}>({formatted.replace('$', '$ ')})</span>;
  };

  if (!payoutView) return <Loading />;

  return (
    <Paper className={classes.paper}>
      <div className={classes.actions}>
        <Button startIcon={<GetAppIcon />} onClick={handleExportClick}>
          Export
        </Button>
        <Button variant="outlined" color="primary" onClick={handleSaveClick}>
          Save Report
        </Button>
      </div>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {payoutView?.map(group => (
            <Table className={classes.table} key={`payout-group-${group.groupId}`}>
              <TableHead>
                <TableRow className={classes.rowHeader}>
                  <TableCell>
                    <Typography variant="h6">{group.groupName}</Typography>
                  </TableCell>
                  <TableCell align="right" colSpan={5}>
                    <TextField
                      select
                      size="small"
                      variant="outlined"
                      value={group.payoutMethod}
                      onChange={onGroupPayoutMethodChange(group.groupId)}
                    >
                      <MenuItem value={'group'}>Paying Group</MenuItem>
                      <MenuItem value={'agent'}>Paying Agents</MenuItem>
                    </TextField>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.rowSubHeader}>
                  <TableCell>
                    <b>Residuals</b>
                  </TableCell>
                  <TableCell />
                  <TableCell
                    align="right"
                    className={classes.cellValueWidth}
                    style={{whiteSpace: 'nowrap'}}
                  >
                    <b>
                      {isGroupPayoutMethod(group)
                        ? `${group.groupName} House Revenue Split`
                        : 'Gross Profit'}
                    </b>
                  </TableCell>
                  <TableCell align="right" className={classes.cellValueWidth}>
                    <b>{isGroupPayoutMethod(group) ? 'Group Payout' : 'Group Profit'}</b>
                  </TableCell>
                  <TableCell align="right" className={classes.cellValueWidth}>
                    <b>Agent Payout</b>
                  </TableCell>
                  <TableCell className={classes.cellActionWidth} />
                </TableRow>
                {group.payouts?.map((payout, i) => (
                  <Fragment key={payout.firestoreGroupId}>
                    <TableRow
                      style={
                        i % 2 === 0 ? {backgroundColor: '#ffffff'} : {backgroundColor: '#fdfdfd'}
                      }
                    >
                      <TableCell className={classes.cellLeftPadding}>{payout.agentName}</TableCell>
                      <TableCell />
                      <TableCell align="right" className={classes.cellValueWidth}>
                        {isGroupPayoutMethod(group)
                          ? formatCurrency(payout.profit)
                          : formatCurrency(payout.grossProfit)}
                      </TableCell>
                      <TableCell align="right" className={classes.cellValueWidth}>
                        {isGroupPayoutMethod(group)
                          ? formatCurrency(payout.groupNetSplit)
                          : formatCurrency(payout.groupProfit)}
                      </TableCell>
                      <TableCell align="right" className={classes.cellValueWidth}>
                        {isGroupPayoutMethod(group)
                          ? formatCurrency(0)
                          : formatCurrency(payout.agentNetSplit)}
                      </TableCell>
                      <TableCell align="right" className={classes.cellActionWidth}>
                        <IconButton
                          color="default"
                          aria-label="add line item"
                          onClick={onAddLineItemClick([
                            group.groupId,
                            'payouts',
                            payout.firestoreAgentId,
                            'lineItems',
                          ])}
                          size="large"
                        >
                          <AddIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>

                    {payout.lineItems?.map((lineItem, index) => {
                      const dotPath = [
                        group.groupId,
                        'payouts',
                        payout.firestoreAgentId,
                        `lineItems`,
                        index,
                      ].join('.');
                      const shouldEditOnRender = editOnRender.current[dotPath];
                      return (
                        <Fragment key={`line-item-${index}`}>
                          <TableRow
                            style={
                              i % 2 === 0
                                ? {backgroundColor: '#ffffff'}
                                : {backgroundColor: '#fdfdfd'}
                            }
                          >
                            <TableCell className={classes.cellLeftPaddingDouble}>
                              <InlineTypographyGeneric
                                name="description"
                                value={lineItem.description}
                                editOnRender={shouldEditOnRender}
                                onChange={(name, value) =>
                                  handleLineItemChange(
                                    [
                                      group.groupId,
                                      'payouts',
                                      payout.firestoreAgentId,
                                      `lineItems`,
                                      index,
                                      name,
                                    ],
                                    value
                                  )
                                }
                                renderLabel={value => value}
                                validationSchema={yup => yup.string().required()}
                                TypographyProps={{
                                  color: 'primary',
                                }}
                                TextFieldProps={{
                                  variant: 'outlined',
                                  onFocus: event => event.target.select(),
                                  fullWidth: true,
                                }}
                              />
                            </TableCell>
                            <TableCell />
                            <TableCell align="right" className={classes.cellValueWidth}>
                              -
                            </TableCell>
                            {group.payoutMethod === 'agent' && (
                              <TableCell align="right" className={classes.cellValueWidth}>
                                -
                              </TableCell>
                            )}
                            <TableCell align="right" className={classes.cellValueWidth}>
                              <InlineTypographyGeneric
                                name="amount"
                                value={lineItem.amount as number}
                                editOnRender={shouldEditOnRender}
                                onChange={(name, value) =>
                                  handleLineItemChange(
                                    [
                                      group.groupId,
                                      'payouts',
                                      payout.firestoreAgentId,
                                      `lineItems`,
                                      index,
                                      name,
                                    ],
                                    value
                                  )
                                }
                                renderLabel={value => formatCurrency(value)}
                                validationSchema={yup => yup.number().required()}
                                TypographyProps={{
                                  color: 'primary',
                                }}
                                TextFieldProps={{
                                  variant: 'outlined',
                                  onFocus: event => event.target.select(),
                                  inputProps: {
                                    style: {textAlign: 'right'},
                                  },
                                  fullWidth: true,
                                }}
                              />
                            </TableCell>
                            {isGroupPayoutMethod(group) && (
                              <TableCell align="right" className={classes.cellValueWidth}>
                                -
                              </TableCell>
                            )}
                            <TableCell align="right" className={classes.cellActionWidth}>
                              <IconButton
                                color="default"
                                aria-label="delete"
                                onClick={() =>
                                  handleLineItemDelete(
                                    [
                                      group.groupId,
                                      'payouts',
                                      payout.firestoreAgentId,
                                      `lineItems`,
                                    ],
                                    index
                                  )
                                }
                                size="large"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    })}

                    {payout.lineItems.length > 0 && (
                      <TableRow
                        style={
                          i % 2 === 0 ? {backgroundColor: '#ffffff'} : {backgroundColor: '#fdfdfd'}
                        }
                      >
                        <TableCell className={classes.cellLeftPaddingDouble}>
                          <b>Agent Subtotal</b>
                        </TableCell>
                        <TableCell />
                        <TableCell align="right" className={classes.cellValueWidth}>
                          -
                        </TableCell>
                        {group.payoutMethod === 'agent' && (
                          <TableCell align="right" className={classes.cellValueWidth}>
                            -
                          </TableCell>
                        )}
                        <TableCell align="right" className={classes.cellValueWidth}>
                          <b>
                            {isGroupPayoutMethod(group)
                              ? formatCurrency(payout.groupNetSplit + payout.sumOfLineItems)
                              : formatCurrency(payout.agentNetSplit + payout.sumOfLineItems)}
                          </b>
                        </TableCell>
                        {isGroupPayoutMethod(group) && (
                          <TableCell align="right" className={classes.cellValueWidth}>
                            -
                          </TableCell>
                        )}
                        <TableCell align="right" className={classes.cellActionWidth} />
                      </TableRow>
                    )}
                  </Fragment>
                ))}
                <TableRow>
                  <TableCell className={classes.cellLeftPadding}>
                    <b>Subtotals</b>
                  </TableCell>
                  <TableCell />
                  <TableCell align="right" className={classes.cellValueWidth}>
                    <b>
                      {isGroupPayoutMethod(group)
                        ? formatCurrency(group.subtotals.net)
                        : formatCurrency(group.subtotals.gross)}
                    </b>
                  </TableCell>
                  <TableCell align="right" className={classes.cellValueWidth}>
                    <b>{formatCurrency(group.subtotals.group)}</b>
                  </TableCell>
                  <TableCell align="right" className={classes.cellValueWidth}>
                    <b>{formatCurrency(group.subtotals.agent)}</b>
                  </TableCell>
                  <TableCell className={classes.cellActionWidth} />
                </TableRow>

                <TableRow className={classes.rowSubHeader}>
                  <TableCell>
                    <b>Additional Group Expenses</b>
                  </TableCell>
                  <TableCell />
                  <TableCell align="right" className={classes.cellValueWidth} />
                  <TableCell align="right" className={classes.cellValueWidth} />
                  <TableCell align="right" className={classes.cellValueWidth} />
                  <TableCell align="right" className={classes.cellActionWidth}>
                    <IconButton
                      color="default"
                      aria-label="add line item"
                      onClick={onAddLineItemClick([group.groupId, 'lineItems'])}
                      size="large"
                    >
                      <AddIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>

                {group.lineItems?.map((lineItem, index) => (
                  <TableRow key={`group-line-item-${index}`}>
                    <TableCell className={classes.cellLeftPadding}>
                      <InlineTypographyGeneric
                        name="description"
                        value={lineItem.description}
                        onChange={(name, value) =>
                          handleLineItemChange([group.groupId, `lineItems`, index, name], value)
                        }
                        renderLabel={value => value}
                        validationSchema={yup => yup.string().required()}
                        TypographyProps={{
                          color: 'primary',
                        }}
                        TextFieldProps={{
                          variant: 'outlined',
                          onFocus: event => event.target.select(),
                          fullWidth: true,
                        }}
                      />
                    </TableCell>
                    <TableCell />
                    <TableCell align="right" className={classes.cellValueWidth}>
                      -
                    </TableCell>
                    <TableCell align="right" className={classes.cellValueWidth}>
                      <InlineTypographyGeneric
                        name="amount"
                        value={lineItem.amount as number}
                        onChange={(name, value) =>
                          handleLineItemChange([group.groupId, `lineItems`, index, name], value)
                        }
                        renderLabel={value => formatCurrency(value)}
                        validationSchema={yup => yup.number().required()}
                        TypographyProps={{
                          color: 'primary',
                        }}
                        TextFieldProps={{
                          variant: 'outlined',
                          onFocus: event => event.target.select(),
                          inputProps: {
                            style: {textAlign: 'right'},
                          },
                          fullWidth: true,
                        }}
                      />
                    </TableCell>
                    <TableCell align="right" className={classes.cellValueWidth}>
                      -
                    </TableCell>
                    <TableCell align="right" className={classes.cellActionWidth}>
                      <IconButton
                        color="default"
                        aria-label="delete"
                        onClick={() => handleLineItemDelete([group.groupId, `lineItems`], index)}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>{' '}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <b>Totals</b>
                  </TableCell>
                  <TableCell />
                  <TableCell align="right" className={classes.cellValueWidth}>
                    <b>
                      {isGroupPayoutMethod(group)
                        ? formatCurrency(group.totals.net)
                        : formatCurrency(group.totals.gross)}
                    </b>
                  </TableCell>
                  <TableCell align="right" className={classes.cellValueWidth}>
                    <b>{formatCurrency(group.totals.group)}</b>
                  </TableCell>
                  <TableCell align="right" className={classes.cellValueWidth}>
                    <b>{formatCurrency(group.totals.agent)}</b>
                  </TableCell>
                  <TableCell className={classes.cellActionWidth} />
                </TableRow>
              </TableBody>
            </Table>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
};
