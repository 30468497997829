import {ApiBase} from '@ozark/common';
import {ActiveFilter} from '@ozark/common/components';
import {Reserve} from '@ozark/functions/src/functions/express/private/types';
import {ContextKey} from '@ozark/functions/src/functions/express/private/types/Context';
import {ReserveActivity} from '@ozark/functions/src/functions/express/private/types/Reserve';
import {toQuery} from '../util/FilterUtil';

export class ReservesApi extends ApiBase {
  getReserves = (mid?: string, filters?: ActiveFilter[]) => {
    const queryString = toQuery(null, filters);
    return this.get<Reserve>(`Reserves?${queryString}`, {
      [ContextKey.Mid]: mid,
    });
  };
  getReserveActivity = (mid?: string, filters?: ActiveFilter[]) => {
    const queryString = toQuery(null, filters);
    return this.get<ReserveActivity[]>(`reserves/activity?${queryString}`, {
      [ContextKey.Mid]: mid,
    });
  };
}
