import {yupResolver} from '@hookform/resolvers/yup';
import {Button, CircularProgress, Divider, Fade, Grid, Paper} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  AgentStatus,
  Firebase,
  UniversalTimestamp,
  useAgent,
  useGroups,
  useNotification,
} from '@ozark/common/';
import {Loading} from '@ozark/common/components';
import {Fragment, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import {AgentForm, AgentFormModel, Schema} from './AgentForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolBar: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      padding: theme.spacing(2, 2),
    },
    logo: {
      height: 48,
    },
    actions: {},
    content: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(1, 2, 2),
      position: 'relative',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    saveButton: {
      width: 150,
    },
  })
);

export const AgentEdit = ({
  agentId,
  isAuthUserAdmin,
}: {
  agentId: string;
  isAuthUserAdmin: boolean;
}) => {
  const classes = useStyles();
  const {
    document: {data: agent},
    set,
  } = useAgent(agentId);

  const {
    documents: {data: groups},
  } = useGroups();

  const [loading, setLoading] = useState(false);
  const showNotification = useNotification();

  const hookForm = useForm<AgentFormModel>({
    resolver: yupResolver(Schema) as any,
  });

  const {formState, reset, handleSubmit} = hookForm;

  const {isDirty} = formState;

  const onSuccess: SubmitHandler<AgentFormModel> = async data => {
    try {
      const {
        firstName,
        lastName,
        groupId,
        role,
        email,
        phone,
        dateOfBirth,
        office,
        adminContact,
        timeZoneId,
        agentStatus,
        agentCode,
        subAgentCode,
        masterUid,
        permissions,
      } = data;

      const groupDocument = groups && groups.find(g => g.id === groupId);
      const group = groupDocument && {
        id: groupDocument.id,
        name: groupDocument.name,
        logoUrl: groupDocument.logoUrl,
      };

      const isActive = agentStatus === AgentStatus.active ? true : false;
      const isDisabled = agentStatus === AgentStatus.disabled;
      const dob = dateOfBirth as UniversalTimestamp;
      setLoading(true);

      await set({
        firstName,
        lastName,
        dob,
        group,
        role,
        email,
        phone,
        office,
        adminContact,
        timeZoneId,
        isActive,
        isDisabled,
        masterUid: masterUid || (Firebase.FieldValue.delete() as any),
        agentCode,
        subAgentCode: masterUid ? subAgentCode : (Firebase.FieldValue.delete() as any),
        permissions: {
          ...(agent?.permissions || {}),
          ...permissions,
        },
      });

      reset(data);

      showNotification('success', 'Agent successfully updated.');
    } catch (err) {
      showNotification('error', 'Failed to Save Changes.');
    } finally {
      setLoading(false);
    }
  };

  if (!agent) return <Loading />;

  return (
    <div>
      <Paper>
        <div className={classes.content}>
          <Grid container spacing={2}>
            <AgentForm
              hookForm={hookForm}
              agent={agent}
              isAuthUserAdmin={isAuthUserAdmin}
              editMode={isAuthUserAdmin}
            />
            <Fade in={isDirty}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Fade>
            {isAuthUserAdmin && (
              <Fade in={isDirty} unmountOnExit={false}>
                <Grid item xs={12}>
                  <div className={classes.footer}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.saveButton}
                      disabled={loading}
                      onClick={handleSubmit(onSuccess)}
                    >
                      {loading ? <CircularProgress size={24} /> : <Fragment>Save Changes</Fragment>}
                    </Button>
                  </div>
                </Grid>
              </Fade>
            )}
          </Grid>
        </div>
      </Paper>
    </div>
  );
};
