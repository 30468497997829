import AttachFileIcon from '@mui/icons-material/AttachFile';
import {Button} from '@mui/material';
import {ButtonUpload} from '../common/ButtonUpload';
import {useComment} from '../hooks/useComment';
import {useCommentAttachments} from '../hooks/useCommentAttachments';
import {useIsEditingClosed} from '../hooks/useIsEditingClosed';

export const CommentsAddAttachment = () => {
  const {isEditingClosed} = useIsEditingClosed();
  const {attachmentUpload} = useCommentAttachments();
  const {commentAdd} = useComment();

  const handleCommentAttachmentUpload = async (file: File) => {
    const commentId = await commentAdd('File attachment.');
    await attachmentUpload(commentId, file);
  };

  return (
    <>
      <ButtonUpload
        onUpload={handleCommentAttachmentUpload}
        uploadKey={'CommentsAddAttachment'}
        disabled={isEditingClosed}
      >
        <Button
          disabled={isEditingClosed}
          startIcon={<AttachFileIcon />}
          component="span"
          aria-label="add attachment"
          sx={{p: '2px'}}
          color="primary"
        >
          Attach...
        </Button>
      </ButtonUpload>
    </>
  );
};
