import {Agent, ViewBase} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {format} from 'date-fns';

export const columns: Column<ViewBase<Agent>>[] = [
  {
    id: 'firstName',
    label: 'First Name',
    sortable: true,
  },
  {
    id: 'lastName',
    label: 'Last Name',
    sortable: true,
  },
  {
    id: 'email',
    label: 'Email',
    sortable: true,
  },
  {
    id: 'phone',
    label: 'Phone',
    sortable: true,
  },
  {
    id: 'group.name',
    label: 'Group',
    sortable: true,
    selector: row => row.group.name,
  },
  {
    id: 'role',
    label: 'Role',
    sortable: true,
  },
  {
    id: 'lastLoginAt',
    label: 'Last Login',
    sortable: true,
    selector: row => (row.lastLoginAt ? format(row.lastLoginAt.toDate(), 'MM/dd/yyyy h:mm a') : ''),
  },
];
