import {
  Department,
  LayoutRoutes,
  OzarkUserRoles,
  OzarkUserRolesWithoutSales,
  OzarkUserRolesWithoutUnderwriter,
  ProfileView,
  UserRoles,
} from '@ozark/common';
import {
  AgentResiduals,
  AgentStatistics,
  AGENT_STATISTICS_ROUTES,
  Authorizations,
  AuthorizationSummaryPage,
  Batches,
  CalendarView,
  CALENDAR_ROUTES,
  ChargebackDetails,
  ChargebacksSummary,
  ChargebacksSummaryByMid,
  DashboardPage,
  DepositsCpyDays,
  FraudAnalysis,
  MccsBySalesPage,
  MfaAuthWizard,
  MidsByChargebacksPage,
  MidsByProcessingPage,
  NotificationsPage,
  Reserves,
  StatementsPage,
  Tickets,
  Transactions,
  VolumeSummaryPage,
} from '@ozark/common/components';
import ApplicationAnalyticsDetails from '@ozark/common/components/Applications/common/ApplicationAnalyticsDetails';
import {FRAUD_ANALYSIS_ROUTES} from '@ozark/common/components/FraudAnalysis';
import {NOTIFICATIONS} from '@ozark/common/components/NotificationsPage/routes';
import {TICKETS_VIEW, TICKET_EDIT} from '@ozark/common/constants/routes';
import {FC} from 'react';
import ActivationsPage from '../components/Activations';
import Agents from '../components/Agents';
import AgentEdit from '../components/Agents/AgentDetails';
import AgentSupport from '../components/AgentSupport';
import {
  AgentMidAssociationPage,
  PortfolioStatisticsPage,
  TransactionsSummary,
} from '../components/Analytics';
import {AnnouncementCreate, AnnouncementDetails, Announcements} from '../components/Announcements';
import Application from '../components/Application';
import Applications from '../components/Applications';
import Audit from '../components/Audit';
import BlankPage from '../components/BlankPage';
import Boarded from '../components/Boarded';
import ChangePassword from '../components/ChangePassword';
import ConditionallyApproved from '../components/ConditionallyApproved';
import Equipment from '../components/Equipment';
import {ForgotPassword} from '../components/ForgotPassword/ForgotPassword';
import Groups from '../components/Groups';
import GroupEdit from '../components/Groups/Edit';
import Incomplete from '../components/IncompleteApplications';
import {MainLayout} from '../components/Layout';
import Login from '../components/Login';
import Merchants from '../components/Merchants';
import MerchantDetails from '../components/Merchants/MerchantDetails';
import MerchantsPortfolio from '../components/MerchantsPortfolio';
import MerchantPortfolioDetails from '../components/MerchantsPortfolio/Edit';
import Pricing from '../components/Pricing';
import Profile from '../components/Profile';
import {ProfileWorkingHours} from '../components/Profile/ProfileWorkingHours';
import {ResetPassword} from '../components/ResetPassword';
import Residuals from '../components/Residuals';
import ResidualPayouts from '../components/Residuals/Payouts';
import Resources from '../components/Resources';
import RiskOverview from '../components/RiskOverview/RiskOverview';
import {
  SalesLeadsCampaignRatioPage,
  SalesLeadsDashboard,
  SalesLeadsDetailsPage,
  SalesLeadsListPage,
  SalesLeadsMetrics,
  SalesLeadsSourceRatioPage,
  SalesRepRatioPage,
} from '../components/SalesLeads';
import Underwriting from '../components/Underwriting';
import {UserEditPage} from '../components/UserEditPage';
import Users from '../components/Users';
import Utilities from '../components/Utilities';
import * as ROUTES from '../constants/routes';

export interface IRoute {
  path: string | string[];
  component:
    | FC<React.PropsWithChildren<{}> | JSX.Element>
    | {renderWithProfile: (profile: ProfileView) => FC<React.PropsWithChildren<{}> | JSX.Element>};
  isProtected?: boolean;
  isExact?: boolean;
  condition?: (profile: ProfileView) => boolean;
}

export const layoutRoutes: LayoutRoutes<IRoute> = [
  {
    layout: null,
    routes: [
      {
        path: ROUTES.LOGIN,
        component: Login,
        isProtected: false,
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        component: ForgotPassword,
        isProtected: false,
      },
      {
        path: ROUTES.RESET_PASSWORD,
        component: ResetPassword,
        isProtected: false,
      },
      {
        path: ROUTES.MFA_AUTH,
        component: MfaAuthWizard,
        isProtected: false,
      },
    ],
  },
  {
    layout: MainLayout,
    isProtected: true,
    routes: [
      {
        path: ROUTES.DASHBOARD,
        component: {
          renderWithProfile: (profile: ProfileView) =>
            profile.role === UserRoles.auditor
              ? Audit
              : profile.role === UserRoles.sales
              ? Applications
              : DashboardPage,
        },
      },
      {
        path: ROUTES.APPLICATIONS,
        component: Applications,
        condition: (profile: ProfileView) => OzarkUserRoles.includes(profile.role),
      },
      {
        path: ROUTES.APPLICATIONS_ANALYTICS_DETAILS,
        component: ApplicationAnalyticsDetails, // same permissions as we have for Dashboard
      },
      {
        path: ROUTES.INCOMPLETE,
        component: Incomplete,
        condition: (profile: ProfileView) => OzarkUserRoles.includes(profile.role),
      },
      {
        path: ROUTES.AGENT_SUPPORT,
        component: AgentSupport,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.UNDERWRITING,
        component: Underwriting,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.CONDITIONALLY_APPROVED,
        component: ConditionallyApproved,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.BOARDED,
        component: Boarded,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.SALES_LEADS,
        component: SalesLeadsListPage,
        condition: (profile: ProfileView) =>
          [UserRoles.admin, UserRoles.agentSupport, UserRoles.sales].includes(profile.role),
      },
      {
        path: ROUTES.SALES_LEADS_METRICS,
        component: SalesLeadsMetrics,
        condition: (profile: ProfileView) => [UserRoles.admin].includes(profile.role),
      },
      {
        path: ROUTES.SALES_LEADS_DASHBOARD,
        component: SalesLeadsDashboard,
        condition: (profile: ProfileView) => [UserRoles.admin].includes(profile.role),
      },
      {
        path: ROUTES.SALES_LEADS_CAMPAIGN_RATIO,
        component: SalesLeadsCampaignRatioPage,
        condition: (profile: ProfileView) => [UserRoles.admin].includes(profile.role),
      },
      {
        path: ROUTES.SALES_LEADS_SOURCE_RATIO,
        component: SalesLeadsSourceRatioPage,
        condition: (profile: ProfileView) => [UserRoles.admin].includes(profile.role),
      },
      {
        path: ROUTES.SALES_LEADS_SALES_REP_RATIO,
        component: SalesRepRatioPage,
        condition: (profile: ProfileView) => [UserRoles.admin].includes(profile.role),
      },
      {
        path: ROUTES.SALES_LEADS_DETAILS,
        component: SalesLeadsDetailsPage,
        condition: (profile: ProfileView) =>
          [UserRoles.admin, UserRoles.agentSupport, UserRoles.sales].includes(profile.role),
      },
      {
        path: ROUTES.MERCHANTS,
        component: Merchants,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: [ROUTES.MERCHANT_DETAILS],
        component: MerchantDetails,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.RISK_OVERVIEW,
        component: RiskOverview,
        condition: (profile: ProfileView) => OzarkUserRoles.includes(profile.role),
      },
      {
        path: ROUTES.MERCHANTS_PORTFOLIO,
        component: MerchantsPortfolio,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.RESOURCES,
        component: Resources,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: [ROUTES.TICKETS, TICKET_EDIT, TICKETS_VIEW],
        component: Tickets,
        isExact: true,
        condition: (profile: ProfileView) => OzarkUserRoles.includes(profile.role),
      },
      {
        path: [ROUTES.TICKET_TYPES_MANAGER, ROUTES.TICKET_TYPES_MANAGER_EDIT_CATEGORIES],
        component: Tickets,
        isExact: false,
        condition: (profile: ProfileView) => profile.role === UserRoles.admin,
      },
      {
        path: ROUTES.USERS,
        component: Users,
        condition: (profile: ProfileView) =>
          [UserRoles.admin, UserRoles.sales, UserRoles.support].includes(profile.role) ||
          (profile.department !== undefined &&
            [Department.sales, Department.risk, Department.logistics].includes(
              profile.department as Department
            )),
      },
      {
        path: ROUTES.USER_EDIT,
        component: UserEditPage,
        condition: (profile: ProfileView) =>
          [UserRoles.admin, UserRoles.sales, UserRoles.support].includes(profile.role) ||
          (profile.department !== undefined &&
            [Department.sales, Department.risk, Department.logistics].includes(
              profile.department as Department
            )),
      },
      {
        path: ROUTES.PROFILE,
        component: Profile,
        condition: (profile: ProfileView) => Object.values(UserRoles).includes(profile.role),
      },
      {
        path: ROUTES.CHANGE_PASSWORD,
        component: ChangePassword,
        condition: (profile: ProfileView) =>
          [UserRoles.admin, UserRoles.sales].includes(profile.role),
      },
      {
        path: ROUTES.PROFILE_WORKING_HOURS,
        component: ProfileWorkingHours,
        condition: (profile: ProfileView) => [UserRoles.sales].includes(profile.role),
      },
      {
        path: ROUTES.APPLICATIONS_EDIT,
        component: Application,
        condition: (profile: ProfileView) => OzarkUserRoles.includes(profile.role),
      },
      {
        path: ROUTES.INCOMPLETE_EDIT,
        component: Application,
        condition: (profile: ProfileView) => OzarkUserRoles.includes(profile.role),
      },
      {
        path: ROUTES.AGENT_SUPPORT_EDIT,
        component: Application,
        condition: (profile: ProfileView) => OzarkUserRoles.includes(profile.role),
      },
      {
        path: ROUTES.UNDERWRITING_EDIT,
        component: Application,
        condition: (profile: ProfileView) => OzarkUserRoles.includes(profile.role),
      },
      {
        path: ROUTES.BOARDED_EDIT,
        component: Application,
        condition: (profile: ProfileView) => OzarkUserRoles.includes(profile.role),
      },
      {
        path: ROUTES.RISK_OVERVIEW_APPLICATION_DETAILS,
        component: Application,
        condition: (profile: ProfileView) => OzarkUserRoles.includes(profile.role),
      },
      {
        path: [ROUTES.MERCHANT_PORTFOLIO_DETAILS, ROUTES.MERCHANT_PORTFOLIO_DETAILS_TAB],
        component: () => <MerchantPortfolioDetails />,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.PRICING,
        component: Pricing,
        condition: (profile: ProfileView) =>
          [UserRoles.admin, UserRoles.agentSupport, UserRoles.support].includes(profile.role),
      },
      {
        path: ROUTES.EQUIPMENT,
        component: Equipment,
        condition: (profile: ProfileView) =>
          [UserRoles.admin, UserRoles.agentSupport, UserRoles.support].includes(profile.role),
      },
      {
        path: ROUTES.AGENTS,
        component: Agents,
        condition: (profile: ProfileView) => OzarkUserRoles.includes(profile.role),
      },
      {
        path: ROUTES.AGENTS_EDIT,
        component: AgentEdit,
        condition: (profile: ProfileView) => OzarkUserRoles.includes(profile.role),
      },
      {
        path: ROUTES.GROUPS,
        component: Groups,
        condition: (profile: ProfileView) =>
          [UserRoles.admin, UserRoles.agentSupport, UserRoles.sales, UserRoles.support].includes(
            profile.role
          ) ||
          (profile.department !== undefined &&
            [Department.sales, Department.risk, Department.logistics].includes(
              profile.department as Department
            )),
      },
      {
        path: ROUTES.GROUP_EDIT,
        component: GroupEdit,
        condition: (profile: ProfileView) =>
          [UserRoles.admin, UserRoles.agentSupport, UserRoles.sales, UserRoles.support].includes(
            profile.role
          ) ||
          (profile.department !== undefined &&
            [Department.sales, Department.risk, Department.logistics].includes(
              profile.department as Department
            )),
      },
      {
        path: ROUTES.RESIDUALS,
        component: Residuals,
        condition: (profile: ProfileView) =>
          [UserRoles.admin, UserRoles.operations].includes(profile.role) ||
          profile.department === Department.finance,
      },
      {
        path: ROUTES.RESIDUALS_AGENTS,
        component: () => <AgentResiduals showActiveAgents />,
        condition: (profile: ProfileView) =>
          [
            UserRoles.admin,
            UserRoles.operations,
            UserRoles.agentSupport,
            UserRoles.sales,
            UserRoles.support,
          ].includes(profile.role) ||
          (profile.department !== undefined &&
            [Department.sales, Department.risk, Department.logistics, Department.finance].includes(
              profile.department as Department
            )),
      },
      {
        path: ROUTES.RESIDUAL_PAYOUT,
        component: ResidualPayouts,
        condition: (profile: ProfileView) =>
          [UserRoles.admin, UserRoles.operations].includes(profile.role) ||
          profile.department === Department.finance,
      },
      {
        path: ROUTES.PORTFOLIO_STATISTICS,
        component: PortfolioStatisticsPage,
        condition: (profile: ProfileView) =>
          OzarkUserRolesWithoutUnderwriter.includes(profile.role),
      },
      {
        path: ROUTES.YEARLY_SUMMARY,
        component: TransactionsSummary,
        condition: (profile: ProfileView) =>
          OzarkUserRolesWithoutUnderwriter.includes(profile.role),
      },
      {
        path: ROUTES.REPORTING_AGENT_MID_ASSOCIATIONS,
        component: AgentMidAssociationPage,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.ANALYTICS_MCC_BY_SALES,
        component: MccsBySalesPage,
        condition: (profile: ProfileView) =>
          OzarkUserRolesWithoutUnderwriter.includes(profile.role),
      },
      {
        path: ROUTES.ANALYTICS_MIDS_BY_PROCESSING,
        component: MidsByProcessingPage,
        condition: (profile: ProfileView) =>
          OzarkUserRolesWithoutUnderwriter.includes(profile.role),
      },
      {
        path: [ROUTES.ANALYTICS_MIDS_BY_CHARGEBACKS, ROUTES.ANALYTICS_MIDS_BY_CHARGEBACKS_DETAILS],
        component: MidsByChargebacksPage,
        isExact: false,
        condition: (profile: ProfileView) =>
          OzarkUserRolesWithoutUnderwriter.includes(profile.role),
      },
      {
        path: ROUTES.MONTHLY_VOLUME_DETAILS,
        component: VolumeSummaryPage,
        condition: (profile: ProfileView) =>
          OzarkUserRolesWithoutUnderwriter.includes(profile.role),
      },
      {
        path: ROUTES.MONTHLY_AUTHORIZATION_DETAILS,
        component: AuthorizationSummaryPage,
        condition: (profile: ProfileView) =>
          OzarkUserRolesWithoutUnderwriter.includes(profile.role),
      },
      {
        path: ROUTES.ACTIVATIONS,
        component: ActivationsPage,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.TRANSACTIONS,
        component: () => <Transactions />,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.CHARGEBACKS,
        component: () => (
          <ChargebacksSummaryByMid chargebackDetailsUrl={ROUTES.CHARGEBACKS_DETAILS} />
        ),
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.CHARGEBACKS_SUMMARY,
        component: {
          renderWithProfile: (_: ProfileView) => () =>
            ChargebacksSummary({
              routeChargebacks: ROUTES.CHARGEBACKS,
              chargbackDetailsUrl: ROUTES.CHARGEBACKS_DETAILS,
            }),
        },
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.DEPOSITS,
        component: DepositsCpyDays,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.DISPUTES,
        component: BlankPage,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.PORTFOLIO_ACH_REJECTS,
        component: BlankPage,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.PORTFOLIO_PCI,
        component: BlankPage,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.PORTFOLIO_TIN,
        component: BlankPage,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.PORTFOLIO_RESERVES,
        component: () => <Reserves />,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: FRAUD_ANALYSIS_ROUTES,
        component: FraudAnalysis,
        isExact: false,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: AGENT_STATISTICS_ROUTES,
        component: AgentStatistics,
        isExact: false,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.AUTHORIZATIONS,
        component: () => <Authorizations />,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.STATEMENTS,
        component: StatementsPage,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.CHARGEBACKS_DETAILS,
        component: {
          renderWithProfile: (profile: ProfileView) => () =>
            ChargebackDetails({
              userId: profile.uid,
              userName: profile.displayName,
            }),
        },
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.BATCHES,
        component: () => <Batches transactionRouteUrl={ROUTES.TRANSACTIONS} />,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
      {
        path: ROUTES.AUDIT,
        component: Audit,
        condition: (profile: ProfileView) =>
          [UserRoles.admin, UserRoles.auditor].includes(profile.role),
      },
      {
        path: ROUTES.UTILITIES,
        component: Utilities,
        condition: (profile: ProfileView) => [UserRoles.admin].includes(profile.role),
      },
      {
        path: NOTIFICATIONS,
        component: NotificationsPage,
      },
      {
        path: ROUTES.ANNOUNCEMENTS,
        component: Announcements,
        condition: (profile: ProfileView) =>
          [UserRoles.admin, UserRoles.sales, UserRoles.support].includes(profile.role) ||
          (profile.department !== undefined &&
            [Department.sales, Department.risk, Department.logistics].includes(
              profile.department as Department
            )),
      },
      {
        path: ROUTES.ANNOUNCEMENT_CREATE,
        component: AnnouncementCreate,
        isExact: true,
        condition: (profile: ProfileView) => profile.role === UserRoles.admin,
      },
      {
        path: ROUTES.ANNOUNCEMENT_DETAILS,
        component: AnnouncementDetails,
        isExact: true,
        condition: (profile: ProfileView) =>
          [UserRoles.admin, UserRoles.sales, UserRoles.support].includes(profile.role) ||
          (profile.department !== undefined &&
            [Department.sales, Department.risk, Department.logistics].includes(
              profile.department as Department
            )),
      },
      {
        path: CALENDAR_ROUTES,
        isExact: false,
        component: CalendarView,
        condition: (profile: ProfileView) => OzarkUserRolesWithoutSales.includes(profile.role),
      },
    ],
  },
];

export default layoutRoutes;
