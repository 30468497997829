import {DocumentBase, DocumentCreatedBy} from '.';
import {UserRoles} from '../constants';
import {FieldValue, UniversalTimestamp} from './compat';

export enum TicketCommentType {
  Public = 'Public',
  Internal = 'Internal',
}

export type TicketComment = DocumentBase &
  DocumentCreatedBy & {
    id?: string;
    comment: string;
    author: string | null;
    authorRole: UserRoles | null;
    createdAt: UniversalTimestamp | FieldValue;
  };
