import {
  Box,
  Button,
  Divider,
  MenuItem,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  AllMIDs,
  getSearchTokens,
  GroupView,
  Merchant,
  MerchantStatus,
  MerchantStatusAll,
  MerchantStatusFilter,
  MerchantView,
  useGroups,
  useMerchants,
  useMidsContainer,
  ViewBase,
} from '@ozark/common';
import {Loading, MidSelect, SearchInput, Title} from '@ozark/common/components';
import {CreateMerchantDialog} from '@ozark/common/components/Merchants';
import {useEffect, useState} from 'react';
import debounceRender from 'react-debounce-render';
import {filterListByInput} from '../../helpers';
import {useUserInfo} from '../../hooks/useUserInfo';
import {MerchantGrid} from './MerchantGrid';
import {
  AllMerchantOwnerSelectItem,
  MerchantOwnerSelect,
  MerchantOwnerSelectItem,
  OwnersOnlyMerchantOwnerSelectItem,
  SubMerchantsOnlyMerchantOwnerSelectItem,
} from './MerchantOwnerSelect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupFilter: {
      minWidth: 200,
    },
    statusFilter: {
      minWidth: 150,
    },
    divider: {
      margin: theme.spacing(1, 2),
    },
    selectInput: {
      backgroundColor: 'transparent !important',
    },
    midLabel: {
      marginTop: 20,
    },
    midInput: {
      marginTop: 16,
    },
    midOwner: {
      marginTop: 16,
    },
  })
);

const DebouncedMerchantGrid = debounceRender(MerchantGrid, 300);

const getFilteredMerchants = (
  merchants: ViewBase<Merchant>[],
  groupId: string,
  merchantStatus: MerchantStatusFilter,
  selectedMid: string,
  selectedOwnerMerchant: MerchantOwnerSelectItem,
  masterMerchantId?: string
) => {
  let _filteredMerchants = [...merchants] as ViewBase<Merchant>[];

  if (masterMerchantId) {
    _filteredMerchants = _filteredMerchants.filter(
      (merchant: Merchant) => merchant.masterUid === masterMerchantId
    );
  }

  if (groupId !== '0') {
    _filteredMerchants = _filteredMerchants.filter(
      (merchant: Merchant) => merchant.groupId === groupId
    );
  }

  switch (merchantStatus) {
    case MerchantStatus.active:
      _filteredMerchants = _filteredMerchants.filter((merchant: Merchant) => merchant.isActive);
      break;
    case MerchantStatus.inactive:
      _filteredMerchants = _filteredMerchants.filter((merchant: Merchant) => !merchant.isActive);
      break;
    case MerchantStatusAll.all:
      break;
    default:
      throw RangeError(`invalid merchantStatus ${merchantStatus}`);
  }

  if (selectedMid && selectedMid !== AllMIDs) {
    _filteredMerchants = _filteredMerchants.filter((merchant: Merchant) =>
      merchant.applicationMids.includes(selectedMid)
    );
  }
  if (selectedOwnerMerchant.id !== AllMerchantOwnerSelectItem.id) {
    _filteredMerchants = _filteredMerchants.filter((merchant: Merchant) =>
      selectedOwnerMerchant.id === SubMerchantsOnlyMerchantOwnerSelectItem.id
        ? !!merchant.masterUid
        : selectedOwnerMerchant.id === OwnersOnlyMerchantOwnerSelectItem.id
        ? !merchant.masterUid
        : merchant.masterUid === selectedOwnerMerchant.id ||
          merchant.id === selectedOwnerMerchant.id
    );
  }

  return _filteredMerchants;
};

type Props = {
  userGroupId?: string;
  masterMerchant?: MerchantView;
};

export const MerchantsList = ({userGroupId, masterMerchant}: Props) => {
  const classes = useStyles();
  const {documents: merchants} = useMerchants();
  const {documents: groups} = useGroups();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [groupId, setGroupId] = useState<string>(userGroupId ?? '0');
  const [merchantStatus, setMerchantStatus] = useState<MerchantStatusFilter>(MerchantStatusAll.all);
  const {mids, selectedMid, handleSelectMid} = useMidsContainer();
  const [selectedOwnerMerchant, setSelectedOwnerMerchant] = useState(AllMerchantOwnerSelectItem);
  const [filterText, setFilterText] = useState('');
  const biggerThan1700 = useMediaQuery('(min-width:1700px)');
  const biggerThan1650 = useMediaQuery('(min-width:1650px)');
  const {isErpAdmin, isErpUser, isMerchant} = useUserInfo();

  const [filteredMerchants, setFilteredMerchants] = useState<ViewBase<Merchant>[]>([]);

  const canCreateMerchants = isErpAdmin || (isMerchant && !masterMerchant?.masterUid);

  const handleFilterChange = (id: string) => {
    setGroupId(id);
  };

  const handleMerchantStatusChange = (event: any) => {
    setMerchantStatus(event.target.value);
  };

  const getGroups = () =>
    (userGroupId && groups.data ? groups.data.filter(g => g.id === userGroupId) : groups.data) ||
    [];

  useEffect(() => {
    if (merchants.data) {
      const groups = getGroups();
      merchants.data.map(x => {
        const groupName = getGroupName(groups, x.groupId);
        x.searchTokens = getSearchTokens([...x.searchTokens, groupName]);
        x.groupName = groupName;
      });
      const filteredListBySearch = filterListByInput(merchants.data, filterText);
      const filtered = getFilteredMerchants(
        filteredListBySearch,
        groupId,
        merchantStatus,
        selectedMid,
        selectedOwnerMerchant,
        masterMerchant?.id
      );
      setFilteredMerchants(filtered);
    }
  }, [merchants, groupId, selectedMid, merchantStatus, filterText, selectedOwnerMerchant]);

  if (merchants.promised || groups.promised || mids.promised || !mids.data) {
    return <Loading />;
  }

  const onTextChange = (value: string) => {
    setFilterText(value);
  };

  const renderOwnerSearch = () => (
    <>
      <Typography className={classes.midLabel}>Owner:</Typography>&emsp;
      <div className={classes.midOwner}>
        <MerchantOwnerSelect
          selectedMerchant={selectedOwnerMerchant}
          handleSelectOwner={setSelectedOwnerMerchant}
          merchants={merchants}
        />
      </div>
      <Divider orientation="vertical" className={classes.divider} flexItem />
    </>
  );

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Title breadcrumbs={[<Typography>Merchants</Typography>]} />
      {createDialogOpen && (
        <CreateMerchantDialog
          onClose={() => setCreateDialogOpen(false)}
          userGroupId={userGroupId}
          masterMerchant={masterMerchant}
        />
      )}
      <Box display="flex" pb={1}>
        {canCreateMerchants && (
          <Box>
            <Button onClick={() => setCreateDialogOpen(true)} variant="outlined">
              Create New Merchant User
            </Button>
          </Box>
        )}
        <Box flex={1} />
        <Box display="flex">
          {isErpUser && biggerThan1700 && renderOwnerSearch()}
          {!isErpUser && biggerThan1650 && (
            <>
              <SearchInput
                disableExtendOnFocus={!biggerThan1700}
                widthOnFocus="15ch"
                filterText={filterText}
                onTextChange={onTextChange}
              />
              <Divider orientation="vertical" sx={{m: 2}} flexItem />
            </>
          )}
          <Typography className={classes.midLabel}>MID:</Typography>&emsp;
          <div className={classes.midInput}>
            <MidSelect
              selectedMid={selectedMid}
              handleSelectMid={handleSelectMid}
              mids={{
                promised: mids.promised,
                data: mids.data,
              }}
            />
          </div>
          <Divider orientation="vertical" className={classes.divider} flexItem />
          <TextField
            value={merchantStatus}
            onChange={handleMerchantStatusChange}
            variant="standard"
            label="Filter by Status"
            InputProps={{
              classes: {
                input: classes.selectInput,
              },
              disableUnderline: true,
            }}
            select
            className={classes.statusFilter}
          >
            <MenuItem key={MerchantStatusAll.all} value={MerchantStatusAll.all}>
              {MerchantStatusAll.all}
            </MenuItem>
            {Object.values(MerchantStatus).sortAndMap(e => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
          </TextField>
          {!userGroupId && (
            <>
              <Divider orientation="vertical" className={classes.divider} flexItem />
              <TextField
                defaultValue="0"
                className={classes.groupFilter}
                variant="standard"
                select
                label="Filter by Group"
                InputProps={{disableUnderline: true, classes: {input: classes.selectInput}}}
                onChange={event => handleFilterChange(event.target.value as string)}
              >
                <MenuItem value="0">All</MenuItem>
                {getGroups().sortAndMap(
                  group => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ),
                  group => group.name
                )}
              </TextField>
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          justifyContent: 'flex-start',
          display: isErpUser ? 'flex' : biggerThan1650 ? 'none' : 'block',
          marginBottom: theme => theme.spacing(2),
        }}
      >
        {isErpUser && !biggerThan1700 && renderOwnerSearch()}
        <Box
          sx={{
            width: '100%',
            justifyContent: 'flex-start',
            display: 'block',
          }}
        >
          <SearchInput inputSmallWidth="100%" filterText={filterText} onTextChange={onTextChange} />
        </Box>
      </Box>
      <Box position="relative" flex={1}>
        {filteredMerchants && (
          <Box position="absolute" width="100%" height="100%" overflow="auto">
            <DebouncedMerchantGrid merchants={filteredMerchants} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const getGroupName = (groups: GroupView[], merchantGroupId: string | undefined) => {
  const group: GroupView | undefined = groups.filter(g => g.id === merchantGroupId)?.[0];
  return !!group ? group.name : '';
};
