import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Switch,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  AdminContact,
  Agent,
  AgentOffice,
  AgentStatus,
  copyWithoutRef,
  GroupRole,
  StateArray,
  StateHash,
  UnitedStatesTimeZonesShortList,
  useAgents,
  useGroups,
} from '@ozark/common';
import {AutoCompleteAddressTextField, Loading, Select, TextField} from '@ozark/common/components';
import {emptyStringToNull, trimWhiteSpace} from '@ozark/common/helpers';
import {getNextSubAgentCode} from '@ozark/common/hooks/useAgents';
import {AgentPermissions, UniversalTimestamp} from '@ozark/functions/src/documents';
import {Fragment, useEffect, useState} from 'react';
import {Controller, UseFormReturn} from 'react-hook-form';
import * as yup from 'yup';
import {getAgentPhoneSchema} from './phoneValidator';

export interface AgentFormModel {
  groupId: string;
  role: GroupRole;
  masterUid?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  dateOfBirth: UniversalTimestamp;
  office: AgentOffice;
  adminContact: AdminContact;
  timeZoneId: string;
  agentStatus: AgentStatus;
  agentCode: string;
  subAgentCode?: string;
  permissions?: AgentPermissions;
}

export const Schema = yup.object().shape(
  {
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    groupId: yup.string().required('Group is required'),
    email: yup.string().email().required('Email is required'),
    role: yup.string().required('Role is required'),
    masterUid: yup.string(),
    phone: getAgentPhoneSchema(),
    dateOfBirth: yup.date().nullable().transform(emptyStringToNull).typeError('Invalid date'),
    agentStatus: yup.string().when(['editMode'], {
      is: (editMode: boolean) => editMode,
      then: yup.string().transform(emptyStringToNull).required('Agent Status is required'),
    }),
    adminContact: yup.object().shape({
      email: yup.string().email('Invalid email'),
    }),
    agentCode: yup.string().transform(trimWhiteSpace).required('Agent Code is required'),
    subAgentCode: yup.string().when(['masterUid'], {
      is: (masterUid: string) => !!masterUid,
      then: yup.string().required('Sub-Agent Code is required'),
      otherwise: yup.string().transform(emptyStringToNull).nullable(), // remove agent code validation of no masterUid
    }),
    permissions: yup.object().shape({
      residuals: yup.boolean(),
    }),
  },
  [['phone', 'phone']]
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleWrapper: {
      marginTop: theme.spacing(2),
    },
    title: {
      fontSize: '1.2em',
      [theme.breakpoints.down('md')]: {
        fontSize: '1em',
      },
    },
  })
);

export const AgentForm = ({
  hookForm,
  isAuthUserAdmin,
  editMode = false,
  agent,
}: {
  hookForm: UseFormReturn<AgentFormModel, any>;
  isAuthUserAdmin: boolean;
  editMode?: boolean;
  agent?: Agent;
}) => {
  const classes = useStyles();
  const {
    formState: {errors},
    control,
    reset,
    setValue,
    watch,
  } = hookForm;
  const {documents: groups} = useGroups();

  const {
    documents: {data: agents},
  } = useAgents();

  const [agentStatus, setAgentStatus] = useState<AgentStatus>(
    agent?.isActive
      ? AgentStatus.active
      : agent?.isDisabled
      ? AgentStatus.disabled
      : AgentStatus.inactive
  );
  const [dataLoaded, setDataLoaded] = useState<Boolean>(false);

  const watchGroupId = watch('groupId');

  const filteredAgents = watchGroupId
    ? agents?.filter(e => e.group?.id === watchGroupId && e.id !== agent?.id)
    : [];

  const watchMasterUid = watch('masterUid');

  const handleGroupChangeSuccess = (value: string) => {
    if (value) {
      setValue('masterUid', '');
    }
  };

  const handleMasterUidChangeSuccess = (value: string) => {
    const masterAgent = value ? filteredAgents?.find(e => e.id === value) : null;
    if (value) {
      setValue('agentCode', masterAgent?.agentCode || '');
      let subAgentCode = agent?.subAgentCode;
      if (agents && (agent?.masterUid !== value || !subAgentCode)) {
        subAgentCode = getNextSubAgentCode(value, agents);
      }
      setValue('subAgentCode', subAgentCode);
    } else {
      setValue('agentCode', agent?.agentCode || '');
      setValue('subAgentCode', '');
    }
  };

  useEffect(() => {
    if (agent && !dataLoaded) {
      const {_group, dateOfBirth, ...agentWithoutGroup} = agent;
      const dateOfBirthAsDate = dateOfBirth?.seconds
        ? new Date(dateOfBirth.seconds * 1000).toLocaleDateString('en-US')
        : null;
      reset({
        ...copyWithoutRef(agentWithoutGroup),
        groupId: agent.group.id,
        dateOfBirth: dateOfBirthAsDate as unknown as UniversalTimestamp,
      });
      setDataLoaded(true);
    }
  }, [agent, reset, dataLoaded]);

  const handleAddressAutoFill = (city: any, state: any, zip: any) => {
    const empty = '';
    setValue(`office.address2`, empty, {shouldDirty: true});
    setValue(`office.city`, city ? city : empty, {shouldDirty: true});
    setValue(`office.state`, state ? state : empty, {shouldDirty: true});
    setValue(`office.zipCode`, zip ? zip : empty, {shouldDirty: true});
  };

  const handleMenuItemClick = (agentStatus: AgentStatus) => () => {
    setAgentStatus(agentStatus);
  };

  const isReadonly = Boolean(!!agent?.id && !isAuthUserAdmin);

  if (groups?.data && groups.promised) {
    return <Loading />;
  }

  return (
    <Fragment>
      <Grid item xs={editMode ? 4 : 12}>
        <TextField
          name="firstName"
          label="First Name"
          required
          errors={errors}
          control={control}
          disabled={isReadonly}
          autoFocus
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <TextField
          name="lastName"
          label="Last Name"
          required
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <TextField
          name="dateOfBirth"
          label="Date of Birth"
          placeholder="__/__/____"
          errors={errors}
          control={control}
          disabled={isReadonly}
          transform={{
            pattern: '99/99/9999',
          }}
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <TextField
          name="phone"
          label="Phone Number"
          errors={errors}
          control={control}
          disabled={isReadonly}
          transform={{
            pattern: '(999) 999-9999',
          }}
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <TextField
          name="email"
          label="Email"
          required
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <Select
          name="timeZoneId"
          label="Time Zone"
          errors={errors}
          control={control}
          disabled={isReadonly}
        >
          {Object.entries(UnitedStatesTimeZonesShortList).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              {key}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            Office
          </Typography>
          <Divider />
        </div>
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="office.name"
          label="Name"
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={12}>
        <AutoCompleteAddressTextField
          setAutofillHandler={handleAddressAutoFill}
          name="office.address"
          label="Address Line 1"
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="office.address2"
          label="Address Line 2"
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <AutoCompleteAddressTextField
          setAutofillHandler={handleAddressAutoFill}
          name="office.city"
          label="City"
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <Select
          name="office.state"
          label="State"
          errors={errors}
          disabled={isReadonly}
          control={control}
        >
          {StateArray.sortAndMap(
            state => (
              <MenuItem key={`${state}`} value={state}>
                {StateHash[state]}
              </MenuItem>
            ),
            state => StateHash[state]
          )}
        </Select>
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <TextField
          name="office.zipCode"
          label="Zip Code"
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            Admin Contact
          </Typography>
          <Divider />
        </div>
      </Grid>
      <Grid item xs={editMode ? 6 : 12}>
        <TextField
          name="adminContact.name"
          label="Name"
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={editMode ? 6 : 12}>
        <TextField
          name="adminContact.email"
          label="Email"
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={editMode ? 6 : 12}>
        {groups?.data?.length && (
          <Select
            name="groupId"
            label="Group"
            required
            disabled={!isAuthUserAdmin}
            onChangeSuccess={handleGroupChangeSuccess}
            errors={errors}
            control={control}
          >
            {groups.data &&
              groups.data.sortAndMap(
                group => (
                  <MenuItem key={`${group.id}`} value={group.id}>
                    {group.name}
                  </MenuItem>
                ),
                group => group.name
              )}
          </Select>
        )}
      </Grid>
      <Grid item xs={editMode ? 6 : 12}>
        <Select
          name="masterUid"
          label="Master Agent"
          errors={errors}
          control={control}
          onChangeSuccess={handleMasterUidChangeSuccess}
          disabled={isReadonly || !filteredAgents || filteredAgents.length === 0}
        >
          <MenuItem value={''}>{'None'}</MenuItem>
          {filteredAgents?.sortAndMap(
            a => (
              <MenuItem key={a.id} value={a.id}>
                {a.firstName} {a.lastName}
              </MenuItem>
            ),
            a => `${a.firstName} ${a.lastName}`
          )}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Select
          name="role"
          label="Role"
          required
          disabled={!isAuthUserAdmin}
          errors={errors}
          control={control}
        >
          {Object.values(GroupRole).sortAndMap(role => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={editMode ? (watchMasterUid ? 3 : 6) : watchMasterUid ? 6 : 12}>
        <TextField
          name="agentCode"
          label="Agent Code"
          required
          errors={errors}
          control={control}
          disabled={Boolean(watchMasterUid) || isReadonly}
        />
      </Grid>
      {watchMasterUid && (
        <Grid item xs={editMode ? 3 : 6}>
          <TextField
            name="subAgentCode"
            label="Sub Agent Code"
            required
            errors={errors}
            control={control}
            disabled={!watchMasterUid || isReadonly}
          />
        </Grid>
      )}
      {editMode && (
        <Grid item xs={6}>
          <TextField
            name="agentStatus"
            value={agentStatus}
            label="Status"
            disabled={!isAuthUserAdmin}
            errors={errors}
            control={control}
            select
          >
            {Object.values(AgentStatus).sortAndMap(status => (
              <MenuItem key={status} value={status} onClick={handleMenuItemClick(status)}>
                {status}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            Permissions
          </Typography>
          <Divider />
        </div>
      </Grid>
      <Grid item xs={12}>
        <List>
          <ListItem>
            <ListItemText
              primary={'Residuals'}
              secondary={'Enabling will allow agent to view their residual reports.'}
            />

            <ListItemSecondaryAction>
              <Controller
                control={control}
                name="permissions.residuals"
                render={({field: {onChange, onBlur, value, ref}}) => (
                  <Switch
                    onBlur={onBlur}
                    onChange={onChange}
                    checked={value || false}
                    ref={ref}
                    disabled={!isAuthUserAdmin}
                  />
                )}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Grid>
    </Fragment>
  );
};
