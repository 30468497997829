import {
  MERCHANTS_PORTFOLIO as MERCHANTS_PORTFOLIO_ROUTE,
  MERCHANT_PORTFOLIO_DETAILS as MERCHANT_PORTFOLIO_DETAILS_ROUTE,
  MERCHANT_PORTFOLIO_DETAILS_TAB as MERCHANT_PORTFOLIO_DETAILS_TAB_ROUTE,
} from '@ozark/common/components';
import {
  APPLICATIONS_ANALYTICS_DETAILS_ROUTE,
  MERCHANTS_ROUTE,
  MERCHANT_DETAILS_ROUTE,
  MFA_AUTH_ROUTE,
  SALES_LEADS_DETAILS_ROUTE,
  SALES_LEADS_ROUTE,
  TICKET_TYPES_MANAGER_EDIT_CATEGORIES_ROUTE,
  TICKET_TYPES_MANAGER_ROUTE,
} from '@ozark/common/constants/routes';

export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const CHANGE_PASSWORD = '/change-password';
export const PROFILE = '/profile';
export const PROFILE_WORKING_HOURS = '/working-hours';
export const UTILITIES = '/utilities';
export const DASHBOARD = '/';
export const ACTIVATIONS = '/activations';
export const APPLICATIONS = '/applications';
export const APPLICATIONS_ANALYTICS_DETAILS = APPLICATIONS_ANALYTICS_DETAILS_ROUTE;
export const APPLICATIONS_EDIT = '/applications/:id/:tab?';
export const INCOMPLETE = '/incomplete';
export const INCOMPLETE_EDIT = '/incomplete/:id';
export const AGENT_SUPPORT = '/agent-support';
export const AGENT_SUPPORT_EDIT = '/agent-support/:id';
export const UNDERWRITING = '/underwriting';
export const UNDERWRITING_EDIT = '/underwriting/:id';
export const CONDITIONALLY_APPROVED = '/conditionally-approved';
export const BOARDED = '/boarded';
export const BOARDED_EDIT = '/boarded/:id';
export const MERCHANTS = MERCHANTS_ROUTE;
export const MERCHANT_DETAILS = MERCHANT_DETAILS_ROUTE;
export const MERCHANT_DETAILS_TAB = `${MERCHANT_DETAILS}/:tabValue`;
export const MERCHANTS_PORTFOLIO = MERCHANTS_PORTFOLIO_ROUTE;
export const MERCHANT_PORTFOLIO_DETAILS = MERCHANT_PORTFOLIO_DETAILS_ROUTE;
export const MERCHANT_PORTFOLIO_DETAILS_TAB = MERCHANT_PORTFOLIO_DETAILS_TAB_ROUTE;
export const PRICING = '/pricing';
export const EQUIPMENT = '/equipment';
export const RESOURCES = `/resources`;
export const TICKETS = `/tickets`;
export const TICKET_TYPES_MANAGER = TICKET_TYPES_MANAGER_ROUTE;
export const TICKET_TYPES_MANAGER_EDIT_CATEGORIES = TICKET_TYPES_MANAGER_EDIT_CATEGORIES_ROUTE;
export const USERS = '/users';
export const USER_EDIT = '/users/:uid/edit';
export const GROUPS = '/groups';
export const GROUP_EDIT = '/groups/:id/edit';
export const AGENTS = '/agents';
export const AGENTS_EDIT = '/agents/:id';
export const AUDIT = '/audit';
export const ANALYTICS = '/analytics';
export const ANALYTICS_MCC_BY_SALES = `${ANALYTICS}/mcc-by-sales`;
export const ANALYTICS_MIDS_BY_PROCESSING = `${ANALYTICS}/mids-by-processing`;
export const ANALYTICS_MIDS_BY_CHARGEBACKS = `${ANALYTICS}/mids-by-chargebacks`;
export const ANALYTICS_MIDS_BY_CHARGEBACKS_DETAILS = `${ANALYTICS_MIDS_BY_CHARGEBACKS}/:mid`;
export const YEARLY_SUMMARY = '/analytics/yearly-summary';
export const PORTFOLIO_STATISTICS = '/analytics/portfolio-statistics';
export const REPORTING_AGENT_MID_ASSOCIATIONS = '/reporting/agent-mid-associations';
export const MONTHLY_VOLUME_DETAILS = '/analytics/monthly-volume-details';
export const MONTHLY_AUTHORIZATION_DETAILS = '/analytics/monthly-authorization-details';
export const RESIDUALS = '/residuals';
export const RESIDUALS_AGENTS = '/residuals/agents-report';
export const RESIDUAL_PAYOUTS = '/residuals/payouts';
export const RESIDUAL_PAYOUT = '/residuals/payouts/:id';
export const RISK_OVERVIEW = '/risk-overview';
export const RISK_OVERVIEW_APPLICATION_DETAILS = '/risk-overview/:id/:tab?';
export const BATCHES = '/batches';
export const TRANSACTIONS = '/transactions';
export const CHARGEBACKS = '/chargebacks';
export const CHARGEBACKS_SUMMARY = '/chargebacks-summary';
export const AUTHORIZATIONS = '/authorizations';
export const STATEMENTS = '/statements';
export const CHARGEBACKS_DETAILS = '/chargeback-details';
export const DEPOSITS = '/deposits';
export const DISPUTES = '/disputes';
export const PCI = '/pci';
export const PORTFOLIO = '/portfolio';
export const PORTFOLIO_ACH_REJECTS = `${PORTFOLIO}/ach-rejects`;
export const PORTFOLIO_PCI = `${PORTFOLIO}/pci`;
export const PORTFOLIO_RESERVES = `${PORTFOLIO}/reserves`;
export const PORTFOLIO_TIN = `${PORTFOLIO}/tin`;
export const FRAUD_ANALYSIS = `/fraud-analysis`;
export const AGENT_STATISTICS = `/agent-statistics`;
export const MERCHANT_REGISTRATION = '/merchant-registration';
export const ANNOUNCEMENTS = '/announcements';
export const ANNOUNCEMENT_DETAILS = `${ANNOUNCEMENTS}/:announcementId`;
export const ANNOUNCEMENT_CREATE = `${ANNOUNCEMENTS}/create-new`;
export const SALES_LEADS = SALES_LEADS_ROUTE;
export const SALES_LEADS_METRICS = `${SALES_LEADS}/metrics`;
export const SALES_LEADS_DASHBOARD = `${SALES_LEADS}/dashboard`;
export const SALES_LEADS_CAMPAIGN_RATIO = `${SALES_LEADS}/campaign-ratio`;
export const SALES_LEADS_SOURCE_RATIO = `${SALES_LEADS}/source-ratio`;
export const SALES_LEADS_SALES_REP_RATIO = `${SALES_LEADS}/sales-rep-ratio`;
export const SALES_LEADS_DETAILS = SALES_LEADS_DETAILS_ROUTE;

export const MFA_AUTH = MFA_AUTH_ROUTE;
