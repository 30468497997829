import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {Box, Button, CircularProgress, Divider, Grid, Typography} from '@mui/material';
import {
  AllMIDs,
  AsyncState,
  SearchCriteria,
  useApiContainer,
  useMidsContainer,
} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {
  ActiveFilter,
  ButtonExportCsv,
  Filters,
  FilterUpdate,
  forceActiveFilter,
  getDisputeTypeName,
  IHistorySubtable,
  Loading,
  MERCHANT_PORTFOLIO_DETAILS,
  MidSelect,
  Table,
  Title,
  TransactionType,
} from '@ozark/common/components';
import {
  Chargebacks,
  PaginatedChargebacksResponse,
} from '@ozark/functions/src/functions/express/private/types';
import {ChargebacksHistory} from '@ozark/functions/src/functions/express/private/types/Chargebacks';
import {CancelTokenSource} from 'axios';
import {Fragment, useCallback, useEffect, useState} from 'react';
import {generatePath, Link, useHistory, useLocation} from 'react-router-dom';
import {CancelOperationMessage} from '../../../api/Constants';
import {useUrlQuery} from '../../../hooks/useUrlQuery';
import {CardImage, CardImagesDictionaryType} from '../../CardImage';
import {getMidFilter, useMidFilter} from '../../MidSelect/useMidFilter';
import {Filter, MidAndDba} from '../common';
import {forceMtdActiveFilter} from '../common/Utils/forceMtdActiveFilter';
import {getFiltersMidFirst} from '../common/Utils/getFiltersMidFirst';
import {currentFormatter, useReportingPageStyles} from '../Reporting';
import {ChargebacksFilters} from './ChargebacksFilters';
import {ChargebacksTotals} from './ChargebacksTotals';
import {getCardNumber, getDate} from './index';
import {ChargebacksSummaryFilters, getStatusName} from './Types';

type Props = {
  routeChargebacks: string;
  chargbackDetailsUrl: string;
};

const DefaultCriteria: SearchCriteria = {
  limit: 20, // page size
  offset: 1, // page
  order: 'desc',
  orderBy: 'dateLoaded',
};

const isRDR = (row: Chargebacks) => row.visaRdrIndicator === 'Y' && row.cardBrand === 1;

export const ChargebacksSummary = ({routeChargebacks, chargbackDetailsUrl}: Props) => {
  const classes = useReportingPageStyles();
  const {selectedMid, handleSelectMid: setSelectedMid, mids} = useMidsContainer();
  const query = useUrlQuery();
  const midsLoaded = !mids.promised;
  const midOptions: MidAndDba[] = Array.from((mids.data ?? new Map()).keys())
    .filter(mid => mid !== AllMIDs)
    .map(mid => ({mid, doingBusinessAs: mids.data!.get(mid) ?? ''}));

  const api = useApiContainer();
  const history = useHistory<{
    chargeback?: Chargebacks;
    caseHistory?: ChargebacksHistory[] | [];
    mid?: string;
  }>();
  const [, setCancelTokenSource] = useState<CancelTokenSource | undefined>();

  const [chargebacks, setChargebacks] = useState<AsyncState<PaginatedChargebacksResponse>>({
    promised: true,
  });
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(DefaultCriteria);
  const [filters, setFilters] = useState<Filter>(
    selectedMid !== AllMIDs ? getMidFilter(selectedMid) : {}
  );
  const selectMid = useMidFilter(setFilters, setSelectedMid);
  const setFiltersAndResetOffset = (_filters: Filter | null, filterUpdate?: FilterUpdate) => {
    if (filterUpdate) {
      setFilters(filters => getFiltersMidFirst(filters, filterUpdate));
    } else {
      setFilters(_filters ?? {});
    }
    setSearchCriteria({...searchCriteria, offset: DefaultCriteria.offset});
  };

  const forceFilterByMtd = () => {
    const forcedFilter = forceMtdActiveFilter(ChargebacksSummaryFilters, 'dateLoaded');
    setFiltersAndResetOffset(null, {updateColumnName: 'dateLoaded', filter: forcedFilter});
  };

  useEffect(() => {
    if (mids.promised) {
      return;
    }
    const mid = query.get('mid');
    if (mid) {
      handleSelectMid(mid);
    }
  }, [useLocation().search, mids.promised]);

  const getAllDataForExport = useCallback(async () => {
    if (!api) return [];

    const pageConfigFull: SearchCriteria = {...searchCriteria, offset: 0, limit: 0};
    const result = await api?.chargebacks.getChargebacks(
      pageConfigFull,
      selectedMid === 'all' ? undefined : selectedMid,
      Object.values(filters)
    );
    return result?.data ?? [];
  }, [searchCriteria, selectedMid, filters]);

  useEffect(() => {
    setChargebacks({promised: true});

    const cancelSource = api?.chargebacks.getCancelTokenSource();
    setCancelTokenSource(prev => {
      //Check if there are any previous pending requests
      if (prev !== undefined) {
        prev.cancel(CancelOperationMessage);
      }
      return cancelSource;
    });
    api?.chargebacks
      .getChargebacks(searchCriteria, undefined, Object.values(filters), cancelSource?.token)
      .then((result: any) => {
        setChargebacks({promised: false, data: result || {}});
      })
      .catch((err: any) => {
        if (err?.message === CancelOperationMessage) {
          return;
        }
        console.error(err);
        setChargebacks({promised: false, error: err || {}});
      });
    // eslint-disable-next-line
  }, [searchCriteria, filters]);

  useEffect(() => {
    forceFilterByMtd();
  }, []);

  if (mids.promised || !mids.data) return <Loading />;

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  };

  const handleDeleteFilter = (id: string) => () => {
    if (id === 'mid') {
      selectMid(AllMIDs);
      return;
    }

    const _filters = {...filters};
    delete _filters[id];
    setFiltersAndResetOffset(_filters);
  };

  const handleApplyFilter = (filter: ActiveFilter) => {
    const _filters = {...filters};
    if (filter.option.column === 'transactionType') {
      delete _filters['cardBrand'];
    }

    if (filter.option.column === 'cardBrand') {
      delete _filters['transactionType'];
      if (!filter.value?.length) {
        delete _filters['cardBrand'];
        setFiltersAndResetOffset({
          ..._filters,
        });
        return;
      }
    }

    setFiltersAndResetOffset({
      ..._filters,
      [filter.option.column]: filter,
    });
  };

  const handleSelectMid = (mid: string) => {
    selectMid(mid);
    setSearchCriteria({
      ...searchCriteria,
      offset: DefaultCriteria.offset,
    });
  };

  const setTotalCbFilter = () => {
    const filter = forceActiveFilter(
      ChargebacksSummaryFilters,
      'transactionType',
      '__eq',
      TransactionType.Chargeback
    );
    setFilters(previous => {
      const {cardBrand, ...restFilters} = previous;
      return {...restFilters, transactionType: filter};
    });
  };

  const setRdrFilter = () => {
    const filter = forceActiveFilter(
      ChargebacksSummaryFilters,
      'transactionType',
      '__eq',
      TransactionType.RDR
    );
    setFilters(previous => {
      const {cardBrand, ...restFilters} = previous;
      return {...restFilters, transactionType: filter};
    });
  };

  const setCbFilter = () => {
    setFilters(previous => {
      const {cardBrand, transactionType, ...restFilters} = previous;
      return restFilters;
    });
  };

  return (
    <div className={classes.root}>
      <Fragment>
        <Title breadcrumbs={[<Typography variant="body1">Chargebacks Summary</Typography>]}>
          <div className={classes.grow} />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Filters options={ChargebacksSummaryFilters} onApplyFilter={handleApplyFilter} />
          </LocalizationProvider>
          <Divider orientation="vertical" className={classes.divider} flexItem />
          <MidSelect selectedMid={selectedMid} handleSelectMid={handleSelectMid} mids={mids} />
          <Divider orientation="vertical" className={classes.divider} flexItem />
          {!chargebacks.promised && (
            <ButtonExportCsv
              filename="disputes-summary-report"
              rows={chargebacks.data?.data}
              columnsConfig={columnsConfig}
              getRows={getAllDataForExport}
            />
          )}
        </Title>
      </Fragment>
      {midsLoaded && !midOptions.length && (
        <Typography className={classes.noContent}>
          There are no MIDs associated with your account
        </Typography>
      )}
      {!chargebacks.promised && !chargebacks.data && (
        <Typography className={classes.noContent}>No Chargebacks</Typography>
      )}
      {chargebacks.promised && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      {!chargebacks.promised && chargebacks.data && midOptions.length && (
        <Grid container spacing={2} direction="row" alignItems="stretch">
          <ChargebacksFilters filters={filters} handleDeleteFilter={handleDeleteFilter} />
          <ChargebacksTotals
            setTotalCbFilter={setTotalCbFilter}
            setCbFilter={setCbFilter}
            setRdrFilter={setRdrFilter}
            cbAmount={chargebacks.data.cbAmount}
            cbCount={chargebacks.data.cbCount}
            rdrCbAmount={chargebacks.data.rdrCbAmount}
            rdrCbCount={chargebacks.data.rdrCbCount}
            totalCbAmount={chargebacks.data.totalCbAmount}
            totalCbCount={chargebacks.data.totalCbCount}
            totalCbAmountRatio={chargebacks.data.totalCbAmountRatio}
            totalCbCountRatio={chargebacks.data.totalCbCountRatio}
          />
          <Grid item xs={12}>
            <Table
              stickyHeader
              scrollableBody
              customHeight="75vh"
              columns={columnsConfig}
              historyColumns={historyColumnsConfig}
              actions={(row: Chargebacks) => (
                <Box sx={{mr: -1, mb: -1}}>
                  <Button
                    variant="contained"
                    disabled={isRDR(row)}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();

                      const caseHistory =
                        [
                          {
                            id: row.id,
                            caseNumber: row.caseNumber,
                            merchAmount: row.merchAmount,
                            itemType: row.itemType,
                            caseType: row.caseType,
                            dateLoaded: row.dateLoaded,
                            reasonDesc: row.reasonDesc,
                            status: row.status,
                          },
                          ...row.history,
                        ] || [];

                      history.push(chargbackDetailsUrl, {
                        chargeback: row,
                        caseHistory,
                        mid: row.mid,
                      });
                    }}
                    size="small"
                    sx={{mr: 1, mb: 1, whiteSpace: 'nowrap'}}
                  >
                    {isRDR(row) ? 'RDR Completed' : 'Respond'}
                  </Button>
                </Box>
              )}
              data={chargebacks.data}
              onRetrieveData={handleRetrieveData}
              paginate
              getCellProps={column => ({
                sx: [
                  column.id === 'historyDateLoaded' && {
                    width: '180px',
                  },
                  column.id === 'caseNumber' && {
                    width: '200px',
                  },
                  column.id === 'historyStatus' && {
                    width: '500px',
                  },
                ],
              })}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const historyColumnsConfig: IHistorySubtable<ChargebacksHistory, Chargebacks> = {
  title: 'History',
  calculatedTitle: (row: Chargebacks) => `History (${row.reasonDesc})`,
  hideIfEmpty: true,
  buttonText: 'History',
  historyProperty: 'history',
  columns: [
    {
      id: 'historyDateLoaded',
      numeric: false,
      label: 'Date of Dispute',
      selector: row => getDate(row.dateLoaded),
    },
    {
      id: 'caseNumber',
      numeric: false,
      label: 'Case Number',
    },
    {
      id: 'historyStatus',
      numeric: false,
      label: 'Status',
      selector: row => getStatusName(row.status),
    },
  ],
};

const columnsConfig: Column<Chargebacks>[] = [
  {
    id: 'doingBusinessAs',
    numeric: false,
    sortable: true,
    export: true,
    label: 'DBA Name',
    selector: row => {
      const value = row.doingBusinessAs;
      if (row.applicationId) {
        const route = generatePath(MERCHANT_PORTFOLIO_DETAILS, {id: row.applicationId});
        return (
          <Link
            to={route}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {value}
          </Link>
        );
      }
      return value;
    },
  },
  {
    id: 'mid',
    numeric: false,
    sortable: true,
    export: true,
    label: 'MID',
    selector: row => row.mid,
  },
  {
    id: 'cardBrand',
    numeric: false,
    sortable: false,
    export: true,
    label: 'Card Brand',
    selector: row => (
      <CardImage
        cardType={row.cardholderAccountNumber.substring(0, 1)}
        dictionaryType={CardImagesDictionaryType.Chargebacks}
      />
    ),
  },
  {
    id: 'bin',
    label: 'BIN',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'cardholderAccountNumber',
    numeric: false,
    sortable: false,
    label: 'Card',
    selector: row => getCardNumber(row.cardholderAccountNumber),
    export: row => getCardNumber(row.cardholderAccountNumber),
  },
  {
    id: 'dateTransaction',
    label: 'Original Transaction Date',
    numeric: false,
    sortable: true,
    selector: row => getDate(row.dateTransaction),
    export: row => getDate(row.dateTransaction),
  },
  {
    id: 'caseNumber',
    label: 'Case Number',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'reasonCode',
    label: 'Reason Code',
    numeric: false,
    sortable: false,
    selector: row => `${row.reasonCode}${isRDR(row) ? ' (RDR)' : ''}`,
    export: row => `${row.reasonCode}${isRDR(row) ? ' (RDR)' : ''}`,
  },
  {
    id: 'reasonDesc',
    label: 'Reason Description',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'authCode',
    label: 'Auth Code',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'disputeType',
    label: 'Dispute Type',
    numeric: false,
    sortable: false,
    selector: row => getDisputeTypeName(row.status),
    export: row => getDisputeTypeName(row.status),
  },
  {
    id: 'caseAmount',
    numeric: true,
    sortable: true,
    export: true,
    label: 'Dispute Amount',
    selector: row => {
      return (
        <span style={{color: row.debitCredit === 'D' ? 'green' : 'inherit'}}>
          {currentFormatter.format(row.caseAmount)}
        </span>
      );
    },
  },
  {
    id: 'acquirerReferenceNumber',
    label: 'Ref#',
    numeric: false,
    sortable: false,
    export: true,
  },
  {
    id: 'currentStatus',
    numeric: false,
    sortable: false,
    label: 'Current Status',
    selector: row => `${getStatusName(row.status)}${isRDR(row) ? ' (RDR)' : ''}`,
    export: row => `${getStatusName(row.status)}${isRDR(row) ? ' (RDR)' : ''}`,
  },
  {
    id: 'dateLoaded',
    numeric: false,
    sortable: true,
    label: 'Date of Dispute',
    selector: row => getDate(row.dateLoaded),
    export: row => getDate(row.dateLoaded),
  },
  {
    id: 'lastDateLoaded',
    numeric: false,
    sortable: true,
    label: 'Last Change Date',
    selector: row => getDate(row.lastDateLoaded),
    export: row => getDate(row.lastDateLoaded),
  },
];
