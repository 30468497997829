import {Box, Typography} from '@mui/material';
import {MerchantAnalytics} from '@ozark/functions/src/functions/express/private/types';
import {VolumeFilterTypes} from '@ozark/functions/src/functions/express/private/types/Reports';
import {AllMIDs} from '@ozark/functions/src/shared';
import {useEffect, useState} from 'react';
import {formatNumber, formatUSD} from '../..';
import {useApiContainer, useMidsContainer} from '../../store';
import {BoxFlexed} from '../common';
import {DashboardCell, DashboardGrid} from './common/DashboardCell';
import {getFilterPeriod, VolumeFilter} from './FilterPeriodSelect';
import {WidgetDisputes} from './widgets/WidgetDisputes';
import {WidgetDisputesGraph} from './widgets/WidgetDisputesGraph';
import {WidgetPinDebit} from './widgets/WidgetPinDebit';
import {WidgetPinDebitGraph} from './widgets/WidgetPinDebitGraph';
import {WidgetSales} from './widgets/WidgetSales';
import {WidgetSalesGraph} from './widgets/WidgetSalesGraph';
import {WidgetTransactions} from './widgets/WidgetTransactions';
import {WidgetTransactionsGraph} from './widgets/WidgetTransactionsGraph';

type Props = {
  filters: VolumeFilter;
};

export const MerchantAnalyticsSection = ({filters}: Props) => {
  const [loading, setLoading] = useState(true);
  const [merchantAnalytics, setMerchantAnalytics] = useState<MerchantAnalytics>();
  const {selectedMid} = useMidsContainer();

  const api = useApiContainer();

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const merchantAnalyticsResult = await api?.dashboard.getMerchantAnalytics(
          filters ?? getFilterPeriod(VolumeFilterTypes.MTD),
          selectedMid === AllMIDs ? undefined : selectedMid
        );
        setMerchantAnalytics(merchantAnalyticsResult);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [filters, selectedMid, api]);

  const salesTitle = (
    <BoxFlexed justifyContent="space-between">
      <Box>Sales</Box>
      <Box>
        {merchantAnalytics?.totalSales
          ? `Gross: ${formatUSD(merchantAnalytics.totalSales, 2)} / ${formatNumber(
              merchantAnalytics.totalTransactions
            )}`
          : null}
      </Box>
    </BoxFlexed>
  );

  return (
    <Box>
      <Typography variant="h5" gutterBottom color="secondary.main" fontSize="1.7rem">
        Portfolio Analytics
      </Typography>
      <DashboardGrid>
        <DashboardCell title="Sales">
          <WidgetSales loading={loading} data={merchantAnalytics} />
        </DashboardCell>
        <DashboardCell title={salesTitle}>
          <WidgetSalesGraph loading={loading} data={merchantAnalytics} />
        </DashboardCell>
        <DashboardCell title="Transactions" height={450}>
          <WidgetTransactions loading={loading} data={merchantAnalytics} />
        </DashboardCell>
        <DashboardCell title="Transactions" height={450}>
          <WidgetTransactionsGraph loading={loading} data={merchantAnalytics} />
        </DashboardCell>
        <DashboardCell title="Disputes">
          <WidgetDisputes loading={loading} data={merchantAnalytics} />
        </DashboardCell>
        <DashboardCell title="Disputes">
          <WidgetDisputesGraph loading={loading} data={merchantAnalytics} />
        </DashboardCell>
        <DashboardCell title="PIN Debit & EBT">
          <WidgetPinDebit loading={loading} data={merchantAnalytics} />
        </DashboardCell>
        <DashboardCell title="PIN Debit & EBT">
          <WidgetPinDebitGraph loading={loading} data={merchantAnalytics} />
        </DashboardCell>
      </DashboardGrid>
    </Box>
  );
};
