import {
  Collections,
  Equipment,
  EquipmentAvailability,
  EquipmentView,
  EquipmentViewableBy,
  Firebase,
  getEnumKeyByValue,
  ProcessingTypes,
  selectEquipmentView,
  UniversalSnapshot,
} from '@ozark/common';
import {useEffect, useState} from 'react';
import {AsyncState} from './AsyncState';

export const useEquipment = (
  processingType: ProcessingTypes,
  viewableBy: EquipmentViewableBy = EquipmentViewableBy.merchant
) => {
  const [equipment, setEquipment] = useState<AsyncState<EquipmentView[]>>({promised: true});

  useEffect(() => {
    if (!processingType) return;
    const processingTypesKey = getEnumKeyByValue(ProcessingTypes, processingType);
    let query = Firebase.firestore
      .collection(Collections.equipment)
      .where('processingTypes', 'array-contains', processingTypesKey);

    if (
      !viewableBy ||
      viewableBy === EquipmentViewableBy.all ||
      viewableBy === EquipmentViewableBy.erpUser
    ) {
      // no filter necessary
      query = query.where('viewableBy', '!=', EquipmentViewableBy.none);
    } else {
      query = query.where('viewableBy', 'in', [EquipmentViewableBy.all, viewableBy]);
    }

    const unsubscribe = query.onSnapshot(
      async snapshot => {
        if (snapshot.size === 0) {
          setEquipment({promised: false});
          return;
        }

        const equipment = snapshot.docs
          .map(doc => selectEquipmentView(doc as UniversalSnapshot<Equipment>))
          .filter(eq =>
            eq.isGateway
              ? true
              : !eq.availability || eq.availability === EquipmentAvailability.inStock
          )
          .sort((a, b) => {
            return a.isOther ? 1 : b.isOther ? -1 : a.name > b.name ? 1 : -1;
          });

        setEquipment({promised: false, data: equipment});
      },
      err => {
        console.error(err);
        setEquipment({promised: false, error: err});
      }
    );
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, [setEquipment, processingType]);

  return {equipment};
};
