import {
  Avatar,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {ApplicationView, AuditView, TSYS_ACQ_AUDIT_ID} from '@ozark/common';
import {format} from 'date-fns';
import React, {Fragment, useState} from 'react';
import {DEFAULT_GROUP} from '../../../constants/group';
import {useAudits} from '../../../hooks/useAudits';
import {useStore} from '../../../store/helpers';
import {Diff} from './Diff';

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      width: '100%',
    },
    empty: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

type ChangeLogListProps = {
  application: ApplicationView;
};

export const ChangeLogList = ({application}: ChangeLogListProps) => {
  const classes = useStyles();
  const {profiles} = useStore();
  const {audits} = useAudits(application.id);
  const [currentAudit, setCurrentAudit] = useState<AuditView | null>(null);
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);

  const handleClick =
    (audit: AuditView) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setAnchorElement(event.currentTarget);
      setCurrentAudit(audit);
    };

  const handleClose = () => {
    setAnchorElement(null);
  };

  if (audits.promised)
    return (
      <div className={classes.empty}>
        <CircularProgress />
      </div>
    );

  return (
    <Fragment>
      {audits.data && audits.data?.length > 0 ? (
        <List className={classes.list} dense>
          {audits.data?.map((audit: AuditView) => {
            const profile = audit.uid ? profiles.dictionary[audit.uid] : null;
            return (
              <ListItem
                button
                disabled={Boolean(audit.action !== 'Update')}
                onClick={audit.action === 'Update' ? handleClick(audit) : undefined}
              >
                <ListItemIcon>
                  {profile ? (
                    <Avatar alt={profile.displayName} src={profile.photoUrl}></Avatar>
                  ) : (
                    <Avatar>?</Avatar>
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={`${audit.action}${
                    audit.resource ? ` ${audit.resource}` : ''
                  } at ${format(audit.createdAt?.toDate?.(), 'MM/dd/yyyy h:mm a')}`}
                  secondary={
                    profile
                      ? `by ${profile.displayName} - ${profile.role}`
                      : audit.uid === TSYS_ACQ_AUDIT_ID
                      ? 'TSYS ACQ File'
                      : `by ${DEFAULT_GROUP.name} System`
                  }
                />
                <ListItemSecondaryAction></ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <div className={classes.empty}>
          <Typography>No Change History</Typography>
        </div>
      )}
      <Diff anchorElement={anchorElement} auditView={currentAudit} onClose={handleClose} />
    </Fragment>
  );
};
