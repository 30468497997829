import {TestConfig} from 'yup/es/util/createValidation';
import {MessageParams} from 'yup/lib/types';

export const getCustomErrorMessage = (
  useCustomErrorMessage: boolean,
  msg: string,
  originalMsg?: string
) => {
  return useCustomErrorMessage ? msg : originalMsg;
};

export const oneOfAllowEmpty = (validValues: string[]) => {
  return {
    name: 'numberString',
    message: ({path}) => `${path} must be one of the following values: ${validValues.join(',')}`,
    test: value => {
      if (!value || !validValues) {
        return true;
      }

      return !!validValues.find(x => x === value);
    },
  } as TestConfig;
};

export const getTypeError =
  (useCustomErrorMessage: boolean, msg: string, originalMsgSuffix?: string) =>
  ({path}: MessageParams) =>
    getCustomErrorMessage(useCustomErrorMessage, msg, `${path} ${originalMsgSuffix}`);

export const getNumberTypeError = (useCustomErrorMessage: boolean, msg: string) =>
  getTypeError(useCustomErrorMessage, msg, 'must be number');

export const getBooleanTypeError = (useCustomErrorMessage: boolean, msg: string) =>
  getTypeError(useCustomErrorMessage, msg, 'must be boolean');

export const getDateTypeError = (useCustomErrorMessage: boolean, msg: string) =>
  getTypeError(useCustomErrorMessage, msg, 'must be date');
