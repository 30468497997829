import {
  ApplicationDocument,
  ApplicationView,
  Collections,
  getBoardedApplicationDocumentValues,
  getNotBoardedApplicationDocumentValues,
} from '@ozark/common';
import {Attachments, AttachmentsRef} from '@ozark/common/components';
import {HARD_DELETE_ATTACHMENTS} from '@ozark/common/constants/application';
import {Fragment, useEffect, useRef, useState} from 'react';
import {useStore} from '../../store/helpers';

export const ApplicationAttachments = ({application}: {application: ApplicationView}) => {
  const {authUser} = useStore();
  const attachmentsRef = useRef<AttachmentsRef | null>(null);
  const [pendingDocument, setPendingDocument] = useState<ApplicationDocument | null>(null);
  const defaultFolderNames = ['Boarded', 'OFAC', 'Bank Pends', 'Risk'];
  const [documentTypeOptions, setDocumentTypeOptions] = useState<ApplicationDocument[]>([]);

  const handleAttachClick = () => {
    attachmentsRef?.current?.onUploadButtonClick();
  };

  useEffect(() => {
    setDocumentTypeOptions(
      !!application.boardedAt
        ? getBoardedApplicationDocumentValues()
        : getNotBoardedApplicationDocumentValues()
    );
  }, [application.boardedAt]);

  return (
    <Fragment>
      <Attachments
        allowDelete
        attachmentsRef={attachmentsRef}
        collection={Collections.applications}
        entityId={application.id}
        handleAttachClick={handleAttachClick}
        hardDelete={HARD_DELETE_ATTACHMENTS}
        pendingDocument={pendingDocument}
        setPendingDocument={setPendingDocument}
        userId={authUser.data?.uid}
        documentTypeOptions={documentTypeOptions}
        defaultFolderNames={defaultFolderNames}
      />
    </Fragment>
  );
};
